import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import CheckPagePermission from "../../Common/CheckPagePermissionJs";
import ListActions from '../../Common/Actions';
import EditButton from '../../Common/EditButton';
import { PostPagination } from '../../Common/Pagination';
import { MuiThemeProvider } from "@material-ui/core/styles";
import {isEqual} from 'lodash';
import { tableMuiTheme} from "../../Common/Styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

 const CategoryList = ({loading, classes, permissions,dispatch,isLoggedIn,isLoading,selectedIds, ...props}) => 
   
 <CheckPagePermission
 authParams={{ Admin: false }}
 location={window.location}
 match="Categories"
 render={({ pagePermissions }) =>
 <div>

   {
   pagePermissions ?
   (pagePermissions.View ==='Y') ?   
        <MuiThemeProvider theme={tableMuiTheme}>
        <List actions={<ListActions  {...props} permissions />}
            pagination={<PostPagination />}
            sort={{ field: 'ca_id', order: 'DESC' }}
            title="Categories"  
            bulkActionButtons={false}
            {...props}
             >
            <Datagrid rowClick="show">
                <TextField source="id" label="Id" />
                <TextField source="ca_Name" label="Name" />                
                <EditButton label="Edit" resource="/Categories/:id" basePath={props.basePath} record ={props.record}/>
            </Datagrid>
        </List> 
        </MuiThemeProvider>:  null:null
 }</div>
}/>

CategoryList.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,  
};


const mapState = state => {
    return {
        isLoggedIn: state.admin.auth.isLoggedIn,
        isLoading: state.admin.loading
        
    }
};

const areEqual = (prevProps, nextProps) => {  
   return isEqual(prevProps, nextProps) 
  }

export  default  React.memo(connect(mapState, null) (CategoryList),areEqual);