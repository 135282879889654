import { UnitList } from './UnitList';
import { UnitShow } from './UnitShow';
import UnitCreate from './UnitCreate';
import UnitEdit from './UnitEdit';

export default {
    list: UnitList,
    create: UnitCreate,
    edit: UnitEdit,
    show: UnitShow
};