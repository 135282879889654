import {
    createStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    withStyles,
    WithStyles,
  } from '@material-ui/core';
  import * as React from 'react';
  
  // util to make fake data object
  /*let id = 0;
  const createData = (name, calories, fat, carbs, protein) => {
    id += 1;
    return { id, name, calories, fat, carbs, protein };
  };
  
  // make an array of fake data
  const record = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];*/
  
  // create a styles object using a theme. The createStyles function is
  // needed to placate the TS compiler.
  const styles = (theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
        maxHeight: 500,
      },
      table: {
        minWidth: 700,
      },
    });
  


  const ListMaterial = ({showNotification,dataProvider,record,classes,...props}) => {
      console.log("Show Record 03082022",record)
    return (
      
      <Paper className={classes.root} >
        <p>File could be uploaded and process stopped with the following messages:</p>
        <Table className={classes.table} padding="dense">
          <TableHead>
            <TableRow>
              <TableCell numeric>Id</TableCell>
              <TableCell >Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.map(row => {
              return (
                <TableRow key={row.id}>
                  <TableCell numeric component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{row.Error}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
     
    );
  };
  
  // instrument the component with the CSS styles defined above, and export it.
  export default withStyles(styles)(ListMaterial);