import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { CREATE } from 'ra-core';
import { withDataProvider, Create, TextInput, crudGetAll, FormDataConsumer, SelectInput, SimpleForm, Toolbar } from 'react-admin';
import PropTypes from 'prop-types';
import { reset } from "redux-form";
import { withStyles } from '@material-ui/core';
import { margin } from '../Common/Styles';
import { required } from '../Common/Validator';
import compose from 'recompose/compose';
import ShowApprovalButton from './ShowApprovalButton';
import ApprovalList from './ApprovalList';
import { connect } from 'react-redux';

const toolbarStyles = {
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	}
}

const SanitizedGrid = ({ basePath, ...props }) => {
	return (
		<Grid {...props} />
	);
};

const CreateToolbar = withStyles(toolbarStyles)(({ handler, ...props }) => {


	return (
		<Toolbar {...props}>
			<ShowApprovalButton label="Approval" handler={handler} variant="flat" color="primary" />
		</Toolbar>
	)
});


class Approval extends Component {

	constructor(props) {
		super(props);
		this.state = {
			Contract: undefined,
			isShow: false,

		}
	}

	componentDidMount() {

		this.fetchData();
	}

	fetchData = () => {
		const { dispatch } = this.props;

		dispatch(
			crudGetAll(
				'Calculation/Details', 'DESC', { isContract: true, isModel: false, isPoint: false, pa_Name: "Approvals" }, 25,
				({ payload, requestPayload, error }) => {
					//console.log(payload.data);
					//debugger;
					this.contractList = payload.data[0].contract;

					this.setState({
						contractChanged: true,
					});

				})
		);

	}

	updateData = (values) => {
		this.setState({
			isShow: true,
			formData: values
		});
	};

	render() {
		if (!this.contractList) return null;

		const {
			classes = {},
			className,
			dataProvider,
			dispatch,
			permissions,
			prevAudit,
			...rest
		} = this.props;


		// toolbar={<CreateToolbar btnState={this.state.disableButton} />}
		return (
			<React.Fragment>
				<Create title="Approval" {...rest} >
					<SimpleForm form="approvalform" toolbar={<CreateToolbar handler={this.updateData} />} >
						<FormDataConsumer>
							{({ formData,prevAudit, ...rest }) => {
								return (
									<SanitizedGrid container >
										<Grid item >
											<SelectInput optionText="c_Name" choices={this.contractList} source="Contract" optionValue="id"
												defaultValue=""
												validate={required()}
												className={classes.margin}
											/>
										</Grid>

										<Grid item >

											<TextInput
												label="Month"
												type="month"
												source="month"
												defaultValue=""
												className={classes.margin}
												InputLabelProps={{
													shrink: true,
												}}
												///style={styleMargin.margin}
												validate={required()}
												{...reset}
											/>
										</Grid>
									</SanitizedGrid>
								);
							}}
						</FormDataConsumer>

					</SimpleForm>
				</Create >

				{(this.state.isShow || prevAudit.exist) && <ApprovalList {...this.props} permissions={permissions} approvalSelection={prevAudit.exist ? prevAudit.auditFilter : this.state.formData} />}
			</React.Fragment>
		);
	}
}

Approval.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};



const mapStateToProps = state => {

	if (state.selectionState.navItem.audit != null)
		return (
			{
				prevAudit: {
					audit: state.selectionState.navItem.audit,
					auditFilter: {
						Contract: state.selectionState.navItem.audit.value.c_id,
						month: state.selectionState.navItem.audit.value.a_StartDateTime
					},
					exist: true

				}
			})

	else return ({
		prevAudit: {
			audit: null,
			auditFilter: null, //{Contract: state.selectionState.navItem.audit.c_id, month: state.selectionState.navItem.audit.a_StartDateTime },
			exist: false
		}
	});

}

const Approval2Redux = connect(
	mapStateToProps,
	null
)(Approval);


export default compose(
	withDataProvider,
	withStyles(margin)
)(Approval2Redux);

