import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { connect } from 'react-redux';
import { ModelShowActions } from './ModelActions';
import ConsoleLog from '../Common/ConsoleLog';


const ModelShow = ({ naviPath, permissions, isLoggedIn, dispatch, modelPermissions, ...props }) => {

    //debugger;

    var permission = props.location ? props.location.state ?
        props.location.state.record : permissions ? {
            UpdateRight: permissions.Update,
            DeleteRight: permissions.Delete
        } : {
            UpdateRight: "N",
            DeleteRight: "N"
        } : {
        UpdateRight: "N",
        DeleteRight: "N"
    };

    return (
        <Show actions={<ModelShowActions permissions={permission} naviPath={naviPath} />}
            {...props} title="Model Details" >
            <SimpleShowLayout>
                {/* <ConsoleLog> "Model show props {props}" </ConsoleLog> */}
                <TextField source="id" />
                <TextField source="m_Name" label="Name" />
                <TextField source="m_Description" label="Description" />
                <TextField source="tz_Name" label="Time Zone" />
                <TextField source="m_PurchaseOrder" label="Purchase Order" />
            </SimpleShowLayout>
        </Show>
    )
};

const mapStateToProps = state => {
    return {
        naviPath: {
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
            model: state.selectionState.navItem.model,
            modelId: state.selectionState.navItem.model.value.id,
            modelName: state.selectionState.navItem.model.value.Name,
        },
        modelPermissions: {
            UpdateRight: state.selectionState.navItem.model.UpdateRight,
            DeleteRight: state.selectionState.navItem.model.DeleteRight
        }
    }
}
const ModelShow2Redux = connect(
    mapStateToProps,
    null
)
    (ModelShow);


export default ModelShow2Redux;