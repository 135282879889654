import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 6px;
  margin-bottom: 8px;
  background-color: ${props => (props.isDragging ? "#dceefd" : "white")};
  display: flex;

  width: ${props =>
    props.type === "O" || props.type === "Fake" ? "150px" : "inherit"};
  height: ${props =>
    props.type === "O" || props.type === "Fake" ? "40px" : "inherit"}; 
`;

const H3 = styled.h4`
  font-weight: normal;
  margin: 3px;   
  color: rgba(0, 0, 0, 0.87);
`;

// text-align: ${props =>
//   props.type === "O" || props.type === "Fake" ? "center" : "inherit"};

function RenderNormalColumn(props) {
  //debugger;
  return <h3>{props.props.field.content}</h3>;
}

function RenderConstantColumn(props) {
  return <h3>Hi</h3>;
}

function Render(props) {
  //debugger;
  if (props.props.columnType === "Constant") {
    return <RenderConstantColumn props={props.props} />;
  } else {
    return <RenderNormalColumn props={props.props} />;
  }
}

export default class Field extends React.Component {
  render() {
    return (
      <Draggable
        draggableId={this.props.field.id}
        index={this.props.index}
        draggabletype={this.props.field.type}
      >
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            type={this.props.field.type}
            isDragging={snapshot.isDragging}
          >
            <H3>{this.props.field.content}</H3>
          </Container>
        )}
      </Draggable>
    );
  }
}

// // background-color: ${props => (props.isDragging  ? 'Lightgreen' : 'white')};
// {
//   /* <Render field={this.props.field} index={this.props.index} /> */
// }
