import React from 'react';
import { CardActions, CreateButton, RefreshButton, ListButton, Link } from 'react-admin';
import { stringify } from 'query-string';
import ConsoleLog from '../Common/ConsoleLog';
import { cardActionStyle } from '../Common/Styles';
import { disableActionCreateButton } from '../Common/Actions';
import  EditButton  from '../Common/EditButton';
import  NavigationPath from '../Common/NavigationPath'

export const PointListActions = ({
    create,
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions,
    record, 
    ...rest }) => (
    <CardActions style={cardActionStyle}  >
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <NavigationPath {...record}/>
        <div style={cardActionStyle.actionBlock}>
            <CreateButton 
                label="Create" 
                basePath={basePath} 
                to={{
                    pathname: '/Points/create',
                    state: { record: { modelId: record.modelId } },
                }} 
                resource={create} 
                variant="flat" 
                color="primary"
                record={record}
                disabled={permissions.UpdateRight == "N"}
            />
            {/* <ConsoleLog > "Model Action:" + {record} </ConsoleLog> */}
            {/* <ConsoleLog>{this.props}</ConsoleLog> */}
            {/*<ExportButton resource= "Contracts"/>*/}
            <RefreshButton variant="flat" color="primary" />
        </div>
    </CardActions>
);

export default PointListActions;

export const PointShowActions = ({ basePath,data, naviPath, resource, permissions }) => (
    <CardActions style={{ display: "flex" }}>
        <NavigationPath {...naviPath} />
        <div  style={{ marginLeft: "auto" }}>
        <EditButton 
           // style={{ marginLeft: "auto" }}
            label="EditFromAction" 
            basePath={basePath} 
            record={{ ...data, ...permissions }}
            to={{
                pathname: `${basePath}/${data.id}`,
                state: {
                    record: {
                        PointTypeId: data.PointTypeId,
                        FormulaEditorData: data.FormulaEditorData,
                        data: data
                    }
                },
            }}
            variant="flat" color="primary"
        />
        <ListButton 
            label="Table" 
            basePath={basePath} to={{
            pathname: `${basePath}`,
            state: {
                record: {
                    modelId: data.modelId,
                    UpdateRight: permissions.UpdateRight
                }
            },
        }} variant="flat" color="primary" />
        </div>
        {/* <ConsoleLog>"Data show: {data}"</ConsoleLog>*/}
    </CardActions>
);

export const PointCreateActions = ({ basePath, naviPath,...rest }) => (
    <CardActions style={{ display: "flex" }}>
        <NavigationPath {...naviPath} />
            <div  style={{ marginLeft: "auto" }}>
        <ListButton label="Table" basePath={{ basePath }}
            to={{
                pathname: `${basePath}`,
                state: { record: { modelId: naviPath.modelId } },
            }}
            variant="flat" color="primary" />
     </div>
    </CardActions>
);

export const PointEditActions = ({ basePath, naviPath, ...rest }) => (
    <CardActions style={{ display: "flex" }}>
        {/* <ShowButton label="Details" basePath={basePath} record={record} resource="/Contracts/:id" /> */}
        <NavigationPath {...naviPath} />
        <ListButton     
            style={{ marginLeft: "auto" }}
            label="Table" 
            basePath={basePath}
            to={{
                pathname: `${basePath}`,
                state: { record: { modelId: naviPath.modelId } },
            }}
            //basePath={basePath}
            variant="flat" color="primary" />
    </CardActions >
);



export const choicesPointPublic = [
    { _id: 'Y', name: 'Yes' },
    { _id: 'N', name: 'No' },
];