import React, { Fragment } from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { connect } from 'react-redux';
import Aside from '../Common/Aside';
import { PointListActions } from './PointActions';
// import EditButton from '../Common/EditButton';
//import PointEditButton from './PointEditButton';
//import PointShowButton from './PointShowButton';
import ConsoleLog from '../Common/ConsoleLog';

import EditButton from '../Common/EditButton';
import ShowButton from '../Common/ShowButton';
import DeleteCrud from '../Common/DeleteCrud';

import { PostPagination } from '../Common/Pagination';
import { tableMuiTheme } from "../Common/Styles";
import { MuiThemeProvider } from "@material-ui/core/styles";

import EnhancedPointBulkDelete from './PointBulkDelete';
import { AccessDenied } from "../Common/NoAccess";

import PointDeleteWithConfirmButton from './PointDeleteWithConfirmButton';
import CheckPagePermission from "../Common/CheckPagePermissionJs";

// 2021-11-24 Daniel Rzehak add ButtonWithHandleSubmit for PointsShiftUp and Down
import ButtonWithHandleSubmit from '../Common/ButtonWithHandleSubmit';

const PostBulkActionButtons = props => (
    <Fragment>
        <EnhancedPointBulkDelete {...props} label="Delete" />
    </Fragment>
);

const PointList = ({ pagePermissions, dispatch, isLoggedIn, model, contract, ...props }) => {

    //debugger;
    const permissions = {
        UpdateRight: model.value.UpdateRight,
        DeleteRight: model.value.DeleteRight
    }

    const naviPath = {
        modelId: model.value.id,
        contractName: contract.value.c_Name,
        modelName: model.value.Name,
    }

    return (<CheckPagePermission
        authParams={{ Admin: false }}
        location={window.location}
        match="Points"
        render={({ pagePermissions }) =>
            <div>
                {pagePermissions && pagePermissions.View === "Y" ? (
                    <MuiThemeProvider theme={tableMuiTheme}>
                        <List actions={<PointListActions record={naviPath}
                            /** 
                            * Permissions:
                            * Decided Model create permission based on Model/{id} UpdateRight
                            * Because Global permission compress user multiple role rights 
                            * (
                            * e.g. User A has role Contract Manager for site 1 and Invoice Controller for Site 2
                            *      Global Permission compress rights and return Insert: Y, Update: Y, View: Y
                            *      Permission for Site 1 are Insert: Y, Update: Y, View: Y
                            *      Permission for Site 2 are Insert: N, Update: N, View: Y
                            *      But if user tries to access Model of Site 2 then user has no rights to create model
                            * )
                            * So if user have edit rights to model then also can create model 
                            */
                            permissions




                        />}

                            /*filter={{
                                modelId: props.location.state ?
                                    props.location.state.record.modelId : undefined
                            }}*/
                            filter={{ modelId: model.value.id }}
                            pagination={<PostPagination />}
                            title="Points" {...props}
                            sort={{ field: 'p_CalcOrder', order: 'ASC' }}
                            bulkActionButtons={false}>

                            <Datagrid >
                                <ConsoleLog>"Points list props: {props.location.pathname} "</ConsoleLog>
                                {/* {<ConsoleLog>{props.record}</ConsoleLog>} */}
                                <TextField source="id" />
                                <TextField source="p_Name" label="Name" />
                                <TextField source="p_Description" label="Description" />
                                <TextField source="Type" label="Type" />
                                <TextField source="Category" label="Category" />
                                <TextField source="Frequency" label="Frequency" />
                                <TextField source="Unit" label="Unit" />
                                <TextField source="Public" label="Public" />
                                <TextField source="p_Account" label="Account" />
                                <TextField source="p_CostCenter" label="Cost Center" />
                                // 2021-11-24 Daniel Rzehak add ButtonWithHandleSubmit for PointsShiftUp and Down
                                <ButtonWithHandleSubmit resource="Points" path="Points/ShiftUp" basePath={props.basePath}
                                        label="ShiftUp" record={props.record} />
                                <ButtonWithHandleSubmit resource="Points" path="Points/ShiftDown" basePath={props.basePath}
                                        label="ShiftDown" record={props.record} />
                                <EditButton label="Edit" resource="/Points/:id" basePath={props.basePath} record={props.record} {...naviPath} />
                                <ShowButton label="Show" resource="/Points/:id" basePath={props.basePath} record={props.record} {...naviPath} />
                                <PointDeleteWithConfirmButton
                                    label="Delete"
                                    redirect="list"
                                    basePath="/Points"
                                    submitOnEnter={false}
                                    variant="flat"
                                    color="primary"
                                    resource="Points"
                                    disabled={permissions.DeleteRight === 'N'}
                                // undoable={false}  //02.04 removed by Mustafa
                                />
                            </Datagrid>
                        </List>
                    </MuiThemeProvider>)
                    :
                    <AccessDenied />}
            </div>


        }
    />
    );
}


const mapState = state => {
    return {
        contract: state.selectionState.navItem.contract,
        model: state.selectionState.navItem.model,
        isLoggedIn: state.admin.auth.isLoggedIn,

    }
}

/*const mapDispatch = (dispatch) => {
    return {
        storeSelection: (typeName,record) => record ? dispatch({type: "ADD_SELECTION",payload:{type: typeName,value: record}}) : null,
        showNotification,
      
    }
  }*/


export default connect(mapState, null)(PointList);
// bulkActionButtons={<PostBulkActionButtons label = "Delete" />}

// sort={{ field: 'p_id', order: 'DESC' }}
// aside={<Aside title="Ponits" body="Point is editable and details are only shown if authorized" />}