import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListActions from '../../Common/Actions';
import EditButton from '../../Common/EditButton';
import CheckPagePermission from "../../Common/CheckPagePermissionJs";
import { PostPagination } from '../../Common/Pagination';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { isEqual } from 'lodash';
import { tableMuiTheme } from "../../Common/Styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const RegionList = ({permissions,...props}) => 
<CheckPagePermission
    authParams={{ Admin: false }}
    location={window.location}
    match="Regions"
    render={({ pagePermissions }) =>
    <div>
      {
      pagePermissions ?
      (pagePermissions.View ==='Y') ?  
            <MuiThemeProvider theme={tableMuiTheme}>
                <List actions={<ListActions permissions {...props} />}
                    pagination={<PostPagination />}
                    sort={{ field: 'r_id', order: 'DESC' }}
                    title="Regions"
                    bulkActionButtons={false}
                    {...props} >
                    <Datagrid rowClick="show"  >
                        <TextField source="id" label="Id" />
                        <TextField source="r_Name" label="Name" />
                        <EditButton label="Edit" resource="/Regions/:id" basePath={props.basePath} />
                    </Datagrid>
                </List>
            </MuiThemeProvider> : null : null
    }</div>
}/>