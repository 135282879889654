import { QualityList } from './QualityList';
import { QualityShow } from './QualityShow';
import QualityCreate from './QualityCreate';
import QualityEdit from './QualityEdit';

export default {
    list: QualityList,
    create: QualityCreate,
    edit: QualityEdit,
    show: QualityShow
};