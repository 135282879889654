import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification, refreshView, UPDATE } from 'ra-core';
import dataProvider from '../dataProvider/rest';
import { ArrowUpward, ArrowDownward, Check, Close } from '@material-ui/icons';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import compose from 'recompose/compose';

const styles = ({ spacing }) =>
    createStyles({
        button: {
            position: 'relative',
        },
        leftIcon: {
            marginRight: spacing.unit,
        },
        icon: {
            fontSize: 18,
        },
    });

const sanitizeRestProps = ({
    basePath,
    invalid,
    pristine,
    reset,
    saving,
    submitOnEnter,
    handleSubmit,
    handleSubmitWithRedirect,
    undoable,
    dispatch,
    ...rest
}) => rest

// 2021-11-24 Daniel Rzehak add PointsShiftUp and PointsShiftDown
// Define path for button
const Epath = {
    InvoiceCheckItemsShiftUp: "/InvoiceCheckItems/ShiftUp",
    InvoiceCheckItemsShiftDown: "/InvoiceCheckItems/ShiftDown",
    PointsShiftUp: "/Points/ShiftUp",
    PointsShiftDown: "/Points/ShiftDown",
    ApprovalsSetApproval: "Approvals/SetApproval",
    ApprovalsRevokeApproval: "Approvals/RevokeApproval"
}

// 2021-11-24 Daniel Rzehak add PointsShiftUp and PointsShiftDown
// Define icons for button
const icons = {
    InvoiceCheckItemsShiftUp: ArrowUpward,
    InvoiceCheckItemsShiftDown: ArrowDownward,
    PointsShiftUp: ArrowUpward,
    PointsShiftDown: ArrowDownward,
    ApprovalsSetApproval: Check,
    ApprovalsRevokeApproval: Close
};

// Return Icon based on pathname
const FieldIcon = ({ pathName }) => {
    //console.log(name);
    pathName = pathName.replace('/', '').replace('/', '');
    const Icon = icons[pathName];
    return Icon ? (<Icon />) : null;
};

class ButtonWithHandleSubmit extends Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = (values) => {
        values.stopPropagation();

        //debugger;
        const { showNotification, refreshView, resource, record, path } = this.props;

        var type = UPDATE;
        var resourcepath = `${path}`;
        var params = {
            id: record.id,
            data: record,
        };

        dataProvider(type, resourcepath, params)
            .then((response) => {
                //debugger;
                //showNotification(response.data.message, "info");
                showNotification(response.data.message, 'info', {
                    messageArgs: {},
                    undoable: false,
                });
                //console.log(response);
                refreshView();
            })
            .catch((e) => {
                showNotification("Error", "warning");
            })
            .finally(() => {

            });

    };

    render() {

        const {
            className,
            classes = {},
            dispatch,
            showNotification,
            refreshView,
            record,
            label,
            path,
            ...rest
        } = this.props;

        var disabled = true;
        if (path == Epath.ApprovalsSetApproval.toString()) {
            disabled = (record.ApprovalRight === 'Y') ? false : true;
        }
        else if (path == Epath.ApprovalsRevokeApproval.toString()) {
            disabled = (record.RevokeRight === 'Y') ? false : true;
        }
        else {
            disabled = (record.ExecuteRight === 'Y') ? false : true;
        }

        return (
            <Button type='submit'
                label={label}
                variant="flat" color="primary"
                onClick={this.handleSubmit}
                disabled={disabled}
                {...sanitizeRestProps(rest)}
            >
                <FieldIcon pathName={path} />
            </Button>
        );
    }
}

ButtonWithHandleSubmit.propTypes = {
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    record: PropTypes.object,
    showNotification: PropTypes.func,
    refreshView: PropTypes.func.isRequired,
};

ButtonWithHandleSubmit.defaultProps = {
    redirect: 'list',
};

const enhance = compose(
    connect(null, {
        showNotification: showNotification,
        refreshView,
    }),
    withStyles(styles)
);

export default enhance(ButtonWithHandleSubmit);