import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Title, Loading } from 'react-admin';
import ImgNoAccess from '../Images/noAccess250px.jpg';

const imgStyle = {
    width: "250px",
    height: "250px",

}

export const AccessDenied = (props) => (
    <div style={{ display: "flex" }}>
        {/*<Card style={{ width: "350px" }}>
            <Title title="" />
            <CardContent  >
                <CardMedia
                    style={imgStyle}
                    image={ImgNoAccess}
                />
            </CardContent>
</Card>*/}

       
            <Loading
                loadingPrimary="Please wait a request for permissions is running"
                loadingSecondary="Content won't load until page is fully loaded"
            />
    
    </div>
);