import React from 'react';
import { Filter, TextInput } from 'react-admin';

export const ContractFilter = (props) => (
    <Filter {...props} >
        <TextInput label="Search for Id" source="id" alwaysOff />
        <TextInput label="Search for Name" source="c_Name" alwaysOff />      
        <TextInput label="Search for Supplier" source="sp_Name" alwaysOff />
        <TextInput label="Search for Medium" source="me_Name" alwaysOff />
        <TextInput label="Search for Country" source="co_Name" alwaysOff />
    </Filter>
);
