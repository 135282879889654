import React from 'react';
import { ImageField } from 'react-admin';
import logo from './Linde_plc.jpg';

const Logo = props => (
   // <ImageField source= {logo} width={234.532} height={20.475}  {...props}/> 
    <img src={logo} width={150} height={75}  {...props}/> 

);

export default Logo;