import { Children, Component, ReactNode, ComponentType } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import getContext from 'recompose/getContext';

import { AuthProvider } from 'ra-core';
import { UserCheck } from 'ra-core';//'./types';
import { createBrowserHistory as Location } from 'history';
import { matchPath as Match } from 'react-router';

import { userCheck as userCheckAction } from 'ra-core'; //'../actions/authActions';
import { AUTH_GET_PERMISSIONS } from 'ra-core';//'./types';
import { isLoggedIn as getIsLoggedIn } from 'ra-core/esm/reducer'; //'../reducer';
import warning from 'ra-core/esm/util/warning';


const isEmptyChildren = children => Children.count(children) === 0;
const checkNested= (obj,...args/*, level1, level2, ... levelN*/)=> {
   // var keys = Array.prototype.slice.call(arguments, 1);
  
    //for (var i = 0; i < keys.length; i++) {
        for(let key of args){
      if (!obj || !obj.hasOwnProperty(key)) {
        return false;
      }
      obj = obj[key];
    }
    return true;
  }

export class CheckPagePermissions extends Component {
    cancelled = false;

    state = { pagePermissions: null };

    componentWillMount() {
        warning(
            this.props.render &&
                this.props.children &&
                !isEmptyChildren(this.props.children),
            'You should not use both <CheckPagePermissions render> and <CheckPagePermissions children>; <CheckPagePermissions children> will be ignored'
        );

        this.checkAuthentication(this.props);
    }


    async componentDidMount() {

        await this.checkPermissions(this.props);
    }

    componentWillUnmount() {
        this.cancelled = true;
       
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.location !== this.props.location ||
            nextProps.authParams !== this.props.authParams ||
            nextProps.isLoggedIn !== this.props.isLoggedIn
        ) {
           
            this.checkAuthentication(nextProps);
            
        }
        this.checkPermissions(this.props);
    }

    checkAuthentication(params) {

        const {userCheck,  authParams, location } = params;
       ////debugger;
        userCheck(authParams, location && location.pathname);
       
    }


     endPointName= (location,match,extractName) => {
        let pageName=match;
        if(extractName)
        {
            const regex = /\?|\//;
            const resource = location.hash.substr(2).split(regex);
            if (location.href.indexOf("Admin") != -1)
                pageName=  resource[1]
            else pageName = resource[0]
        }
        
    
        /*const op = ["create", "edit", "show"];
        var ep = resource.pop()
        const ret= op.includes(ep) ?  resource.pop() :  ep;*/
        pageName = (pageName === "") ? "Default" : pageName;
        pageName = (pageName === "login") ? "Default" : pageName;
    
        return pageName
    
    }
    async checkPermissions(params) {

        //console.log(params);
        const { authProvider, addPermissionSaga, authParams, location, match } = params;
        let pageRegistry = null;
       //debugger;
        let pageName=this.endPointName(location,match,false)
        if(checkNested(params,'permissionState','pageRegistry',pageName)){
        pageRegistry=params.permissionState.pageRegistry;
        //console.log("FOUND PAGE_REGISTRY)");
        //this.setState(x=>x.avail=true);
        //console.log("CheckPagePermissionsJs");
        //console.log(params);
        //console.log(authParams);
        try {
            const pagePermissions = await authProvider("GET_PAGE_PERMISSIONS", {
                ...authParams,
                pageRegistry,
                pageName,
                location: location ? location.pathname : undefined,
            });
            
            if (!this.cancelled) {
                console.log("pagePermissions found");
                this.setState({ pagePermissions });
                
            }
        } catch (error) {
            if (!this.cancelled) {
                console.log("pagePermissions was not found!",error);
                this.setState({ pagePermissions: null });
            }
        }
        }
        else{
            console.log("Redux PAGE_REGISTRY was not found)");
        
       //debugger;
        addPermissionSaga(pageName);
        }
           
       
    }

    render() {
        const {
            authProvider,
            userCheck,
            isLoggedIn,
            render,
            children,
            staticContext,
            ...props
        } = this.props;
        const { pagePermissions } = this.state;
        
    
        if (render) {
            return render({ pagePermissions, ...props });
        }

        if (children) {
            return children({ pagePermissions, ...props });
        }
    }
}

const mapStateToProps = (state,props) => ({
    isLoggedIn: getIsLoggedIn(state),
    permissionState: state.permissionState
});




const mapDispatchToProps = (dispatch) => {
 
    return {
        userCheck: userCheckAction,
        addPermissionSaga: (pageName) => { 
            dispatch({
                type: 'ADD_PERMISSION_SAGA',
                pageName})
        },
        dispatch: dispatch

    };
}

const EnhancedCheckPagePermissions = compose(
    getContext({
        authProvider: PropTypes.func
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
    )(CheckPagePermissions);


 /*   const EnhancedCheckPagePermissions =
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
        (CheckPagePermissions);
*/
export default EnhancedCheckPagePermissions