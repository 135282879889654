import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { translate } from 'ra-core';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import compose from 'recompose/compose';
import PageviewIcon from '@material-ui/icons/Pageview';

const styles = ({ spacing }) =>
    createStyles({
        button: {
            position: 'relative',
        },
        leftIcon: {
            marginRight: spacing.unit,
        },
        icon: {
            fontSize: 18,
        },
    });

    const sanitizeRestProps = ({
        basePath,
        invalid,
        pristine,
        reset,
        saving,
        submitOnEnter,        
        handleSubmitWithRedirect,
        undoable,
        dispatch,
        ...rest
      }) => rest

class ResultButton extends Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = (values) => {
        //debugger;
        // set form data to parent component using call back function
        this.callBackHandleSubmit(values);
    }

    callBackHandleSubmit = (values) => {
        //debugger;
        // set form data to parent component
        this.props.handler(values); 
    };

    // handleClick = (e) => {
    //     //debugger;
    //     this.props.handler();
    //     //console.log(e);
    //     e.stopPropagation();

    //     // const { push, record, handleSubmit, showNotification } = this.props;
    //     // const createRecord = { ...record };
    //     // const { submit } = this.props;
    //     // submit();
    // }

    render() {

        const {
            className,
            classes = {}, 
            handleSubmit,                       
        } = this.props;    

        return (            
            <Button className={classnames(classes.button, className)} 
                label="Show"
                variant="flat" color="primary"
                onClick={handleSubmit((data) => { this.handleSubmit(data) })}             
                >
                <PageviewIcon className={classnames(classes.leftIcon, classes.icon)} />
                Show
            </Button>
        );
    }
}

ResultButton.propTypes = {
    record: PropTypes.object,   
};

const enhance = compose(
    translate,
    withStyles(styles)
);

export default enhance(ResultButton);