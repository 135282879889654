import React from 'react';
import {
    Edit, SimpleForm, TextInput, AutocompleteInput, Toolbar
} from 'react-admin';

import UpdateCrudButton from '../../Common/UpdateCrud';
import DeleteWithConfirmButton from '../../Common/DeleteCrud';
import { withStyles } from '@material-ui/core';
import { styles, toolbarStyles } from '../../Common/Styles';
import { EditActions } from '../../Common/Actions';

const FrequencyEdit = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    return (
        <Edit actions={<EditActions />} {...props} >
            <SimpleForm toolbar={<UpdateToolbar basePath="/Frequencies" disabled={false} />}>
                <TextInput label="Frequency Name" source="fq_Name" validate={required()} className={classes.textFld} />
                <AutocompleteInput label="Minute" source="fq_Minute" choices={choices_fq_Minute} optionText="name" optionValue="_id" />
                <AutocompleteInput label="Day" source="fq_Day" choices={choices_fq_Day} optionText="name" optionValue="_id" />
                <AutocompleteInput label="Month" source="fq_Month" choices={choices_fq_Month} optionText="name" optionValue="_id" />
            </SimpleForm>
        </Edit>
    );
};

const Getchoices = (number) => {

    return [...Array(number).keys()].map((i) => ({ _id: i + 1, name: String(i + 1) }));

};

export const choices_fq_Minute = Getchoices(60);
export const choices_fq_Day = Getchoices(30);
export const choices_fq_Month = Getchoices(12);

export default withStyles(styles)(FrequencyEdit);

const UpdateToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath="/Frequencies"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            disabled={false}
        />
        <DeleteWithConfirmButton
            label="Delete"
            redirect="list"
            basePath="/Frequencies/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="Frequencies"
            undoable={false}
            disabled={false}
        />
    </Toolbar>
));
