import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListActions from '../../Common/Actions';
import EditButton from '../../Common/EditButton';
import CheckPagePermission from "../../Common/CheckPagePermissionJs";
import { PostPagination } from '../../Common/Pagination';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { isEqual } from 'lodash';
import { tableMuiTheme } from "../../Common/Styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const QualityList = ({permissions,...props}) => 
<CheckPagePermission
    authParams={{ Admin: false }}
    location={window.location}
    match="Qualities"
    render={({ pagePermissions }) =>
    <div>
      {
      pagePermissions ?
      (pagePermissions.View ==='Y') ? 
        <MuiThemeProvider theme={tableMuiTheme}>
        <List actions={<ListActions permissions {...props}/>}
            pagination={<PostPagination />}
            sort={{ field: 'q_id', order: 'DESC' }}
            title="Qualities"  
            {...props} 
            bulkActionButtons={false}
            >
            <Datagrid rowClick="show"  >
                <TextField source="id" label="Id" />
                <TextField source="q_Name" label="Name" />
                <TextField source="q_Description" label="Description" />
                <TextField source="q_ColorRedByte" label="Quality ColorRedByte" />
                <TextField source="q_ColorBlueByte" label="Quality ColorBlueByte" />
                <TextField source="q_ColorGreenByte" label="Quality ColorGreenByte" />
                <EditButton label="Edit" resource="/Qualities/:id" basePath={props.basePath} />
            </Datagrid>
        </List>
        </MuiThemeProvider> : null : null
  }
  </div>
  }/>