import { dispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import { InsertDriveFile } from '@material-ui/icons';


function getExtension(file)
{   const  fileName=file.title;
    var parts = fileName.split('.');
    return parts[parts.length - 1];

}


function to_csv(workbook, FS) {
  var result = [];
  workbook.SheetNames.forEach(function(sheetName) {
    var csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName], {FS:FS||","});
    if(csv.length > 0){
      result.push("SHEET: " + sheetName);
      result.push("");
      result.push(csv);
    }
  });
  return result.join("\n");
}




function parseEx(event) {
  var arrayBuffer =event; // reader.result;
  ////debugger

  var options = { 
    type: 'array',
   // encoding: 'utf-8',
    cellDates: false,
 };

  var workbook = XLSX.read(arrayBuffer, options);
 

  var sheetName = workbook.SheetNames
  var sheet = workbook.Sheets[sheetName]
  
  //console.log(sheet.A1);

  var out = [], txt = "";
  var opts = "" || {};
  if(!sheet || !sheet["!ref"]) return "";
  var r = XLSX.utils.decode_range(sheet["!ref"]);
  var fs = opts.FS||",", rs = opts.RS||"\n";

  var data = [];
  
 

  for(var R = r.s.r; R <= r.e.r; ++R) {
   
    var row = [];
    for(var C = r.s.c; C < 4; ++C) {
      
      var val = sheet[XLSX.utils.encode_cell({c:C,r:R})];

      if(val===undefined && (C == 0 || C==1)) break; 

      if(C<2 && val.t=="n"){            // Work around because of https://github.com/SheetJS/sheetjs/issues/1522
        val.v = parseFloat(val.v.toFixed(6));
        val.z="dd/mm/yyyy hh:mm";
        console.log("val:" + JSON.stringify(val));
        delete val.w;
      }

      txt = String(XLSX.utils.format_cell(val));
      txt = txt.replace(/ 24:/g,' 00:'); // Workaround 
      //console.log(txt);
       // txt = val;
      /*  if(txt.indexOf(fs)!==-1 || txt.indexOf(rs)!==-1 || txt.indexOf('"')!==-1)
            txt = "\"" + txt.replace(/"/g, '""') + "\"";*/
        row.push(txt);
    
  }
   if(row.length==0 ) continue;
    data.push(row);

  }

  return data;

};

const readFile = file => new Promise((resolve, reject) => {
    var ext = getExtension(file);
    const reader = new FileReader();
    console.log("extentsion, " + JSON.stringify(ext) );
    if(ext=='csv')
    {
     
    reader.readAsText(file.rawFile);
    reader.onload = () => resolve(parseCSV(reader.result));
    }
    else if( ext=='xlsx' || ext=='xls')
    {

      console.time();
      reader.readAsArrayBuffer(file.rawFile);
      reader.onload = () => resolve(parseEx(reader.result));

      console.timeEnd();
    }
    reader.onerror = reject;
  
    }
);


 //Create list of array out of CSV
function parseCSV(csv) {
    var allTextLines = csv.split(/\r\n|\n/);
    var lines = [];
    for (var i=0; i<allTextLines.length; i++) {
        var data = allTextLines[i].split(';');
            var tarr = [];
            for (var j=0; j<data.length; j++) {
                tarr.push(data[j]);
            }
            lines.push(tarr);
    }
  //console.log(lines);
  return lines;
}


const addUploadFeature = requestHandler => (type, resource, params) => {

    if (type === 'CREATE' && resource === 'MeterData') {
        console.log('addUploadFeature:' + type);
        console.log('addUploadFeature:' + JSON.stringify(params));

        if (params.data.files) {

            const myFile = params.data.files;
            if (!(myFile.rawFile instanceof File) ){
                return Promise.reject('Error: Not a file...'); 
            }
           
            
            return Promise.resolve( readFile(myFile) )
             /*   .then( (file64) => ({
                    src: file64,
                    title: `${myFile.title}`
                }))*/
                .then( transformedMyFile => requestHandler('UPLOAD', resource, {
                    ...params,
                    query: {_contract: params.data.Contract, _model: params.data.Model, _point: params.data.Point},
                    data: transformedMyFile      
                }));
        }
    }
    
    return requestHandler(type, resource, params);
};

const mapDispatchToProps=(dispatch) => {

	return {
		uploadDone2:(id) =>{dispatch({type: 'UPLOAD_DONE', id: id})},
		dispatch: dispatch

	};
}


export default addUploadFeature;

