import React from 'react';
import {
    Create, SimpleForm, TextInput, ReferenceInput, SelectInput
} from 'react-admin';
import { CreateActions } from '../../Common/Actions';
import CreateToolbar from '../../Common/CreateToolbar';
import { withStyles } from '@material-ui/core';
import { styles } from '../../Common/Styles';

const CountryCreate = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    const maxLength = (max, message = `Please enter exactly ${max} characters`) =>
        value => value && (value.length < max || value.length > max) ? message : undefined;
        
    return (
        <Create actions={<CreateActions />} {...props} >
            <SimpleForm toolbar={<CreateToolbar {...props} />} redirect="show">
                <TextInput label="Country Name" source="co_Name" validate={required()} className={classes.textFld} />
                <TextInput label="Country Alpha 2" source="co_Alpha_2" validate={required(), maxLength(2)} className={classes.textFld} />
                <TextInput label="Country Alpha 3" source="co_Alpha_3" validate={required(), maxLength(3)} className={classes.textFld} />
                <ReferenceInput label="Region" source="co_r_id" reference="Regions" resource="Regions"
                    filter={{ is_all: true }}
                    resettable validate={required()}  >
                    <SelectInput optionText="r_Name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export default withStyles(styles)(CountryCreate);