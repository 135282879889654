import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FlashOn } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Button } from 'react-admin';

export class ModelButton extends Component {
    constructor(props) {
        super(props);
    }

    handleClick = (e) => {
        const { record } = this.props;
        e.stopPropagation();
    }

    render() {
        return  <Button
            label=""
            onClick={this.handleClick}

            component={Link}
            to={{
                //pathname: `${this.props.record.id}`    
                // pathname: `/Model/Models`
                pathname: `Models`,
                state: { record: { contractId: this.props.record.id } }     
            }}
            disabled={ this.props.record.ViewRight =='N'}
            color="primary"
        >
            <FlashOn />
            </Button>
           
    }
}

ModelButton.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
};

ModelButton.defaultProps = {
    undoable: false,
};

export default ModelButton;