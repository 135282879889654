import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import { 
    withDataProvider,
    List,
    Datagrid,
    TextField
 } from 'react-admin';

import { CardActions, RefreshButton, Filter, BooleanInput} from 'react-admin';
import StyledBooleanField from '../Common/StyledBooleanField';
import classnames from 'classnames';




    const ResponseFilter = (props) => (
        <Filter {...props}>
            <BooleanInput label= "Show All" source="Valid" alwaysOn />
        </Filter>
    );


const StageUpload = (props) => 
{
    return(

<List  title= " " bulkActionButtons={false}  actions={<CardActions><RefreshButton /></CardActions> }  style={{maxHeight: '100%', overflow: 'auto'}} defaultValues={''}  perPage={100} filters={<ResponseFilter />} filterDefaultValues={{ Valid: true }}  {...props}>   
        <Datagrid >
            <TextField label="Line #" source="id" />
			<StyledBooleanField label="Valid" source="Check OK" /> 
            <TextField label="Start File" source="Start File" />
            <TextField label="Start Identified" source="Start Identified" />
            <TextField label="End File" source="End File" />
            <TextField label="End Identified" source="End Identified" />
            <TextField label="Value File" source="Value File" />
            <TextField label="Value Identified" source="Value Identified" />
            <TextField label="Quality File" source="Quality File" />
            <TextField label="Quality Identified" source="Quality Identified" />
			<TextField label="Check Message" source="Check Message" />
        </Datagrid>
     
    </List>
   
);
};


export default StageUpload;