import React from 'react';
import { Datagrid, List, TextField, showNotification } from 'react-admin';
import  NavigationButton  from '../Common/NavigationButton';
import { InvoiceCheckListActions } from './InvoiceCheckActions';
import EditButton  from '../Common/EditButton';
import  ShowButton  from '../Common/ShowButton';
import DeleteCrud from '../Common/DeleteCrud';
import ConsoleLog from '../Common/ConsoleLog';
import { PostPagination } from '../Common/Pagination';
import { MuiThemeProvider } from '@material-ui/core';
import { tableMuiTheme } from "../Common/Styles";
import { AccessDenied } from "../Common/NoAccess";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CheckPagePermission from "../Common/CheckPagePermissionJs";

const InvoiceCheckList = ({ pagePermissions, ...props }) => {


    const { showNotification,contract, ...rest } = props;

 
   /* <List actions={<InvoiceCheckListActions record={props.location.state ?
        props.location.state.record : { contractId: props.record.contractId }}
        permissions={pagePermissions}
    />}*/

    const naviPath= {
        contractId: contract.value.id,
        contractName: contract.value.c_Name,
    }

    
    return (
        <CheckPagePermission
        authParams={{ Admin: false }}
        location={window.location}
        match="InvoiceChecks"
        render={({ pagePermissions }) =>
            <div>
                {pagePermissions && pagePermissions.View === "Y" ?
            <MuiThemeProvider theme={tableMuiTheme} >
                <List actions={<InvoiceCheckListActions record={naviPath }
                 permissions={pagePermissions}
                />}
                    filter={{
                        contractId:  contract.value.id
                    }}
                    pagination={<PostPagination />}
                    title="Invoice Checks" {...rest}
                    sort={{ field: 'ic_id', order: 'DESC' }}
                    bulkActionButtons={false}
                >
                    <Datagrid >
                        {/* <ConsoleLog>"Model list props: {props.location.state} "</ConsoleLog> */}
                        <TextField source="id" />
                        <TextField source="ic_Name" label="Name" />
                        <TextField source="c_Name" label="Contract" />
                        <TextField source="Model1" label="Model1" />
                        <TextField source="Model2" label="Model2" />
                        <TextField source="fq_Name" label="Frequency" />
                        <TextField source="ro_Name" label="Role" />
                        <TextField source="ic_ValidFrom" label="Valid From" />
                        <TextField source="ic_ValidTo" label="Valid To" />
                        {/* <EditButton resource="/InvoiceChecks/:id" basePath={props.basePath} /> */}
                        <NavigationButton label="Check Items" resource="InvoiceCheckItems" path="InvoiceCheckItems" basePath={props.basePath} />
                        <EditButton label="Edit" resource="/InvoiceChecks/:id" basePath={props.basePath} record={props.record} showNotification={showNotification} />
                        <ShowButton label="Show" resource="/InvoiceChecks/:id" basePath={props.basePath} record={props.record} />
                        <DeleteCrud label="Delete" resource="/InvoiceChecks/:id" basePath={props.basePath} record={props.record} />
                    </Datagrid>
                </List>
            </MuiThemeProvider>: <AccessDenied />
                    }
                </div>}
        />
    )
};

InvoiceCheckList.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
};


const mapState = state => {
    return {
        contract: state.selectionState.navItem.contract,
        isLoggedIn: state.admin.auth.isLoggedIn,
        showNotification: showNotification,

    }
}
export default connect(mapState, null)(InvoiceCheckList);
// aside={<Aside title="Models" body="Model is editable and details are only shown if authorized" />}