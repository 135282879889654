import React from 'react';
import {
    Create, SimpleForm, TextInput, ReferenceInput, SelectInput
} from 'react-admin';
import { CreateActions } from '../../Common/Actions';
import CreateToolbar from '../../Common/CreateToolbar';
import { withStyles } from '@material-ui/core';
import { styles } from '../../Common/Styles';

const SupplierCreate = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    const posDigitNumbers = (min,max, message = `Please enter  ${min}-${max} digits`) =>
        value => value &&  (value.length < min || value.length > max || isNaN(value) || value < 0 ) ? message : undefined;
        
    return (
        <Create actions={<CreateActions />} {...props} >
            <SimpleForm toolbar={<CreateToolbar {...props} />} redirect="show">
                <TextInput label="Supplier Name" source="sp_Name" validate={required()} className={classes.textFld} />
                <TextInput label="Supplier ERP Number" source="sp_ERP_Number" validate={required(), posDigitNumbers(5,8)} className={classes.textFld} />                
                <ReferenceInput label="Country" source="sp_co_id" reference="Countries" resource="Countries"
                    filter={{ is_all: true }}
                    resettable validate={required()}  >
                    <SelectInput optionText="co_Name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export default withStyles(styles)(SupplierCreate);