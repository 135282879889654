import React from 'react';
import { CardActions, RefreshButton } from 'react-admin';

export const ApprovalListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <CardActions>        
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }        
        <RefreshButton />        
    </CardActions>
);

export default ApprovalListActions;