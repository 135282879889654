import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React, { Component, BrowserRouter } from 'react';
import { CREATE } from 'ra-core';
import { withDataProvider, Create, crudGetAll, FileField, FileInput, FormDataConsumer, SaveButton, SelectInput, SimpleForm, Toolbar } from 'react-admin';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDefaultValues, translate } from 'ra-core';

import { required, REDUX_FORM_NAME, Loading } from 'react-admin';
import { change, submit, isSubmitting } from "redux-form";
import compose from 'recompose/compose';
import StageUpload from './stageUpload';
import UploadToStageButton from './UploadToStageButton';
import UploadButtonRdx from './UploadButtonRdx';
import StageCommitButton from './StageCommitButton';
import { AccessDenied } from "../Common/NoAccess";
import CheckPagePermission from "../Common/CheckPagePermissionJs";

export const UPLOAD_COMMIT_DONE = 'UPLOAD_COMMIT_DONE';

const toolbarStyles = {
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	}
}


const sanitizeRestProps = ({
	anyTouched,
	array,
	asyncValidate,
	asyncValidating,
	autofill,
	blur,
	change,
	clearAsyncError,
	clearFields,
	clearSubmit,
	clearSubmitErrors,
	destroy,
	dirty,
	dispatch,
	form,
	handleSubmit,
	initialize,
	initialized,
	initialValues,
	pristine,
	pure,
	redirect,
	reset,
	resetSection,
	save,
	submit,
	submitFailed,
	submitSucceeded,
	submitting,
	touch,
	translate,
	triggerSubmit,
	untouch,
	valid,
	validate,
	...props
}) => props;

const CreateToolbar = withStyles(toolbarStyles)(props => (
	<Toolbar {...props}>
		<UploadButtonRdx label="Upload Meter Data"   {...props} />
		<StageCommitButton label="CommitDelete" userId={0} {...props} />
	</Toolbar>
))


const UploadType = () => {

	return <span>Upload </span>;
}

const SanitizedGrid = ({ basePath, ...props }) => {
	return (
		<Grid {...props} />
	);
};



class UploadMeterData2 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			Contract: undefined,
			Model: undefined,
			Point: undefined,
			isStaged: true,
			disableDropZone: true,
			disableButton: true,
			files: null
		}
	}

	/* handleSubmitWithRedirect = (redirect = this.props.redirect)=>{
			 
		  
	   };*/

	onChange = (formData) => {

		this.setState({ files: formData.files, inputKey: Date.now() })
	}

	setFile = (formData) => {
		let disabled = !(this.state.Contract && this.state.Model && this.state.Point && formData.files);
		if (JSON.stringify(this.state.files) !== JSON.stringify(formData.files))
			this.setState({
				files: formData.files,
				inputKey: Date.now(),
				disableButton: disabled
			})
	}

	handleSubmit = (redirect = this.props.redirect) =>
		this.props.handleSubmit(data => {
			//console.log('data:', data);
			this.setState({ isStaged: true });
		});


	/*  static getDerivedStateFromProps(props, state) {

		if(typeof state.Contract === 'number' && typeof state.Model === 'number' && typeof state.Point === 'number')
		{
			
			return {
				disableButton : false,
				disableDropZone: false
			};

		}
		return null;
	  }*/

	allowUpload(e) {
		var obj = new Object();
		obj[e.target.name] = e.target.value;
		this.setState(obj);
	}

	componentDidMount() {
		const { dispatch } = this.props;
		this.setState({ files: null });

		dispatch(
			{ type: UPLOAD_COMMIT_DONE }
		);
		this.fetchData();

	}

	fetchData() {
		const { dispatch } = this.props;

		dispatch(
			crudGetAll(
				'MeterData/ContractNames', 'DESC', undefined, 25,
				({ payload, requestPayload, error }) => {
					this.contractList = payload.data;
					this.setState({ contractChanged: true });
				})
		);


		dispatch(
			crudGetAll(
				'MeterData/ModelNames', 'DESC', undefined, 25,
				({ payload, requestPayload, error }) => {
					this.modelList = payload.data;
					this.setState({ modelChanged: true });
				})
		);


		dispatch(
			crudGetAll(
				'MeterData/PointNames', 'DESC', undefined, 25,
				({ payload, requestPayload, error }) => {
					this.pointList = payload.data;
					this.setState({ pointChanged: true });
				})
		);
	}


	getModelObjectsById(value) {
		var reducedArray = [];
		for (let i = 0; i < this.modelList.length; i++) {
			if (this.modelList[i].c_id == value.Contract) {
				reducedArray.push({ id: this.modelList[i].id, m_Name: this.modelList[i].m_Name });
			}

		}
		return reducedArray;
	}

	getPointObjectsById(value) {
		var reducedArray = [];
		for (let i = 0; i < this.pointList.length; i++) {
			if (this.pointList[i].c_id == value.Contract && this.pointList[i].m_id == value.Model) {
				reducedArray.push({ id: this.pointList[i].id, p_Name: this.pointList[i].p_Name });
			}

		}
		return reducedArray;
	}


	render() {
		if (!this.contractList || !this.modelList || !this.pointList) return null;

		const {
			basePath,
			children,
			classes = {},
			className,
			invalid,
			pristine,
			record,
			resource,
			submitOnEnter,
			toolbar,
			version,
			permissions,
			...rest
		} = this.props;


		return (<CheckPagePermission
			authParams={{ Admin: false }}
			location={window.location}
			match="MeterData"
			render={({ pagePermissions }) => <div>
				{pagePermissions && pagePermissions.View === "Y"  ?
					<React.Fragment>
						<Create {...this.props} title="Upload Meter Data "  >

							<SimpleForm toolbar={<CreateToolbar formState={this.state} />} form="uploadform"  >
								<FormDataConsumer>
									{({ formData, ...rest }) => {
										return (
											<SanitizedGrid container >
												<Grid item xs >
													<SelectInput optionText="c_Name" optionValue="id" choices={this.contractList} source="Contract" onClick={(e) => this.allowUpload(e)} defaultValue="" validate={required()} />
												</Grid>

												<Grid item xs>
													<SelectInput optionText="m_Name" optionValue="id" choices={this.getModelObjectsById(formData)} source="Model" onClick={(e) => this.allowUpload(e)} defaultValue="" validate={required()} />
												</Grid>

												<Grid item xs={6}>
													<SelectInput optionText="p_Name" optionValue="id" source="Point" choices={this.getPointObjectsById(formData)} onClick={(e) => this.allowUpload(e)} defaultValue="" validate={required()} />
												</Grid>

												<Grid item xs={8}>

													<FileInput
														source="files"
														label="Upload file (.csv)"
														accepts="text/*"
														defaultValue=""
														options={{
															onChange: () => this.onChange(formData)
														}}
													//	key={this.state.inputKey}
													>
														<FileField
															source="src"
															title="title"

														//key={this.state.inputKey}
														/>

													</FileInput>


													{

														this.setFile(formData)
													}

													{console.log("formdata: " + JSON.stringify(formData.files))}
													{console.log("state: " + JSON.stringify(this.state.files))}

												</Grid>

											</SanitizedGrid>

										);
									}}
								</FormDataConsumer>

							</SimpleForm>

						</Create >

						{this.props.staged && <StageUpload {...this.props} />}
					</React.Fragment> :
					<AccessDenied />} </div>
			}
		/>);
	}
}

function mapStateToProps(state) {
	return {
		loading: state.admin.loading,
		staged: state.uploadState.staged
	}
}


const mapDispatchToProps = (dispatch) => {

	return {
		stageValid: (valid) => { dispatch({ type: 'STAGE_VALID', staged: valid }); },
		handleSubmit: submit


	};
};
/*
UploadMeterData2.propTypes = {
	basePath: PropTypes.string,
	children: PropTypes.node,
	classes: PropTypes.object,
	className: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	handleSubmit: PropTypes.func, // passed by redux-form
	invalid: PropTypes.bool,
	pristine: PropTypes.bool,
	push: PropTypes.func,
	record: PropTypes.object,
	resource: PropTypes.string,
	redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
	showNotification: PropTypes.func,
	submitOnEnter: PropTypes.bool,
	toolbar: PropTypes.element,
	validate: PropTypes.func,
	version: PropTypes.number,
};

*/
//export default connect(null, mapDispatchToProps)(UploadMeterData2);
export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withDataProvider
)(UploadMeterData2);