import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { ShowActions } from '../../Common/Actions';

export const UnitShow = props => (
    <Show actions={<ShowActions />}  {...props} title="Unit Details" >
        <SimpleShowLayout>
            <TextField source="id" label="Id" />
            <TextField source="un_Name" label="Name" />
            <TextField source="un_IsCurrency" label="IsCurrency" />
        </SimpleShowLayout>
    </Show>
);