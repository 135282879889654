import React, { Component } from 'react';
import { Authenticated } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { userCheck, Loading } from 'react-admin';
import { Title,WithPermissions} from 'react-admin';
import NotFound from "../Common/NotFound";
import ConsoleLog from "../Common/ConsoleLog";
import CheckPagePermission from  "../Common/CheckPagePermissionJs";
import { AccessDenied } from "../Common/NoAccess";

export class Dashboard extends Component {

  constructor(props) {
    super(props);

  }



  render() {

    let { isLoggedIn, userCheck, pagePermissions, ...rest } = this.props;
    console.log("Logging state", { isLoggedIn });
    console.log("Dashbard permission", { pagePermissions });
    console.log(this.props)
    if (isLoggedIn == null || isLoggedIn == false) {

      return (
        <Redirect to={{ pathname: "/login", }} />
        
      );
    }
    else {

      return (
        <CheckPagePermission 
        authParams={{Admin:false}}
        location= {window.location}
        match=""
        render={({ pagePermissions }) => <Card>
        <Title title="Dashboard" />
        {pagePermissions && pagePermissions.View === "Y" ? (
          <CardContent>
          Welcome to PRESTO {pagePermissions.UserName}!
          <iframe width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=8cd2ce21-f1d9-49eb-b25b-a9879af75342&autoAuth=true&ctid=1562f007-09a4-4fcb-936b-e79246571fc7&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen={true}/>
          </CardContent>) :
          <CardContent>
             <AccessDenied/>
          </CardContent>
        }
          </Card>
         } />

       /* <WithPermissions authParams={{userCheck: userCheck}}  render={({ permissions }) =>   

          <Card>
          <Title title="Dashboard" />
          {permissions != null ?
            permissions.View == "Y"
              ? <CardContent>Welcome to PRESTO {permissions.UserName}!</CardContent>
              : <CardContent>You are not logged in. Please log in and try again</CardContent>
            : <Loading loadingPrimary="Please wait a request for permission is running" loadingSecondary="Content won't load until page is fully loaded" />

          }
        </Card>
       /* }/>*/

      )
    
    /*  return ( 
      <Authenticated authParams={{isLoggedIn: this.props.isLoggedIn }} location={this.props.location}>
          <span>Loading Site Information...</span> 
      </Authenticated> 
      );
    */
  
}
  }
}

function mapStateToProps(state) {
  return { isLoggedIn: state.admin.auth.isLoggedIn };
}

export default connect(mapStateToProps, { })(Dashboard);