import React from "react";
import Column from "./column";
import "@atlaskit/css-reset";
import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import TextField from '@material-ui/core/TextField';
//import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

import initialData from "./initial-data";

import ConsoleLog from '../Common/ConsoleLog';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },  
});

const divWidth = "80%";
const Maindiv = styled.div`
  height: 950px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  width: ${divWidth};
  height: 80%;
  margin-bottom: 8px;  
`;

const ConSelect = styled.select`
  width: 220px;
  height: 25px;
`;

const ConLable = styled.label`
  margin: 8px;
`;

const TopContainer = styled.div`
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: ${divWidth};  
`;

export class FormulaEditor extends React.Component {
  constructor(props) {
    //debugger;
    super(props);
    //this.state = initialData;
    this.state = props.location.state.record.FormulaEditorData;

    if (this.state == undefined)
    {
      this.state = props.record ? props.record.FormulaEditorData ?  props.record.FormulaEditorData : undefined : undefined;
    }

    this.AddConstant = this.AddConstant.bind(this);

    const { resource, source } = props;

    //console.log("Resource : " + resource);
    //console.log("Source : " + source);

    //this.state = this.props.record.FormulaEditor;

    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  //state = initialData;

  // Event Add Constant
  AddConstant(event) {
    //debugger;
    event.preventDefault();

    // do nothing when constant value is undefined
    if (this.state.ConstantValue == undefined) {
      return;
    }

    // Fetch fake id from state
    let fakeid = this.state.FakeId;

    // Get Constant column
    const columnConstant = this.state.columns["Constant"];

    // Create new constant field based on constant value
    var newObj;
    newObj = {
      [fakeid]: {
        id: fakeid,
        type: "Constant",
        content: this.state.ConstantValue
      }
    };

    // Add new constant field to field list in state
    Object.assign(this.state.fields, newObj);

    // Get all fieldIds of constant column
    const columnConstantFieldIds = Array.from(columnConstant.fieldIds);
    // Add new constant id to constant column fieldIds
    columnConstantFieldIds.splice(-1, 0, fakeid);

    // Prepared modified constant column
    const prepareConstantColumn = {
      ...columnConstant,
      fieldIds: columnConstantFieldIds
    };

    // Modified fake id to use next time
    fakeid -= 1;

    // Update modified data to state
    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [prepareConstantColumn.id]: prepareConstantColumn
      },
      FakeId: String(fakeid)
    };

    //log new state
    //console.log(newState);

    // Set new state
    this.setState(newState);

    // alert(ConstantValue);
    //console.log(ConstantValue);
  }

  /// Function used to store Constant value
  TextFieldhandleOnChange = event => {

    // Update constant value in state
    const newState = {
      ...this.state,
      ConstantValue: event.target.value
    };

    this.setState(newState);
  };

  handleChange(event) {
    //debugger;
    const fieldIdsToBind = this.state.DropDownData[event.target.selectedIndex]
      .fieldIds;

    const columnField = this.state.columns["Field"];

    //debugger;

    const columnTarget = this.state.columns["Target"];
    const columnOperators = this.state.columns["Operators"];
    const fieldsOperators = this.state.OperatorsOrder;

    const newColumnField = {
      ...columnField,
      fieldIds: fieldIdsToBind
    };

    const newcolumnTarget = {
      ...columnTarget,
      fieldIds: []
    };

    const newcolumnOperators = {
      ...columnOperators,
      fieldIds: fieldsOperators
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newColumnField.id]: newColumnField,
        [newcolumnTarget.id]: newcolumnTarget,
        [newcolumnOperators.id]: newcolumnOperators
      }
    };

    this.setState(newState);
    return;
  }

  onDragStart = start => {
    // Used to disable droppable: for Operator to field and field to operator
    const sourceid = start.source.droppableId;
    const targetDragsourceid = this.state.fields[start.draggableId].type;

    //console.log("sourceid: " + sourceid);
    //console.log("targetid: " + targetDragsourceid);

    // Set source id and targetDrage source id to state
    this.setState({
      sourceid,
      targetDragsourceid
    });
  };
 
  UpdateFormulaToPoint = updatedFormula => {
    this.props.Formula(updatedFormula);
  };

  onDragEnd = result => {
    // clearing source id
    // this.setState({
    //   sourceid: null
    // });

    const { destination, source, draggableId } = result;

    //debugger;
    if (!destination) {
      return;
    }

    // if (
    //   destination.droppableId === source.droppableId &&
    //   destination.index === source.index
    // ) {
    //   return;
    // }

    //debugger;

    const start = this.state.columns[source.droppableId];
    const finish = this.state.columns[destination.droppableId];

    if (start === finish) {
      const newFieldIds = Array.from(start.fieldIds);
      newFieldIds.splice(source.index, 1);
      newFieldIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        fieldIds: newFieldIds
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      };

      this.setState(newState);
      //this.props.Formula(newState);
      this.UpdateFormulaToPoint(newState);
      return;
    }

    // Get all fieldIds of droppable
    var startFieldIds = Array.from(start.fieldIds);
    //console.log("start field id:" + start.id);

    // Do not remove source id of Field and Operator from list of field ids
    if (!(start.id === "Operators" || start.id === "Field")) {
      // Remove draggable id from field ids except operators
      // We will not remove operator id from field ids list
      startFieldIds.splice(source.index, 1);
    }
    
    // update start column
    const newStart = {
      ...start,
      fieldIds: startFieldIds
    };

    // Get all field ids of droppable column
    var finishFieldIds = Array.from(finish.fieldIds);
    //finishFieldIds.splice(destination.index, 0, draggableId);

    // Store draggable id to variable
    var newdraggableId = draggableId;

    // Get Fake id from state
    var FakeId = this.state.FakeId;

    // When you drag and drop operators/field to Target then create fake object of operator/field 
    // and add into list of fields and target fieldIds
    if ((start.id === "Operators" && finish.id === "Target") || (start.id === "Field" && finish.id === "Target")) {

      // Define type of fake field
      var faketype;
      if (start.id === "Field") {
        faketype = "FakeField";
       }
      else if (start.id === "Operators") {
        faketype = "FakeOperator"
      }

      //console.log(faketype);

      newdraggableId = this.state.FakeId;
      var newObj;
      const opcontent = this.state.fields[draggableId].content;
      newObj = {
        [newdraggableId]: {
          id: newdraggableId,
          type: faketype,
          content: opcontent
        }
      };

      // Update fake object to list of fields
      Object.assign(this.state.fields, newObj);
      FakeId -= 1;
    }

    // Do not add fake operators/fields to operators/fields list
    if (!((start.id === "Target" && finish.id === "Operators") || (start.id === "Target" && finish.id === "Field"))) {
      // Insert new draggableId to column fieldIds
      finishFieldIds.splice(destination.index, 0, newdraggableId);
    }

    //debugger;

    // Remove Fake Id of operators/fields from List of fields
    const allFieldIds = this.state.fields;
    if ((start.id === "Target" && finish.id === "Operators") || (start.id === "Target" && finish.id === "Field")) {
      delete allFieldIds[newdraggableId];
    }

    // Update prepared fieldIds
    const newFinish = {
      ...finish,
      fieldIds: finishFieldIds
    };

    var newState;  
    // Prepare new state data
    newState = {
      ...this.state,
      //...this.state.fields,
      fields: allFieldIds,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      },
      FakeId: String(FakeId)
    };    
     
    // Update new state
    this.setState(newState);

    // Update parent form Formula with updated state value
    //this.props.Formula(newState);
    this.UpdateFormulaToPoint(newState);

    //console.log(this.state);
  };

  render() {

    const { classes } = this.props;

    return (
      <Maindiv title="Formula Editor">
         <Typography variant="caption" gutterBottom>
          Formula Editor
    </Typography>
       <DragDropContext
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
        >
          <TopContainer>
            <TextField
              id="name"
              label="Constant"
              margin="normal"
             // ref='constant' //02.04 removed by Mustafa
              onChange={this.TextFieldhandleOnChange}
            />
            <Button size="medium" variant="fab" color="primary" aria-label="add"  className={classes.button}>
              <AddIcon onClick={this.AddConstant} />
    </Button> 
          </TopContainer>
          <Container>
            { this.state.columnOrder.map(columnId => {
              const column = this.state.columns[columnId];
              const fields = column.fieldIds.map(
                fieldId => this.state.fields[fieldId]
              );

              var isDropDisabled = false;

              // Disable drop: Do not allow field to be drop to operator and vise versa.
              if (
                (this.state.sourceid === "Field" &&
                  (column.id === "Operators" || column.id === "Constant")) ||
                (this.state.sourceid === "Operators" &&
                  (column.id === "Field" || column.id === "Constant")) ||
                (this.state.sourceid === "Constant" &&
                  (column.id === "Field" || column.id === "Operators")) ||
                (this.state.sourceid === "Target" &&
                  this.state.targetDragsourceid === "F" &&
                  (column.id === "Operators" || column.id === "Constant")) ||
                (this.state.sourceid === "Target" &&
                  this.state.targetDragsourceid === "O" &&
                  (column.id === "Field" || column.id === "Constant")) ||
                (this.state.sourceid === "Target" &&
                  this.state.targetDragsourceid === "FakeOperator" &&
                  (column.id === "Field" || column.id === "Constant")) ||
                (this.state.sourceid === "Target" &&
                  this.state.targetDragsourceid === "FakeField" &&
                  (column.id === "Operator" || column.id === "Constant")) ||
                (this.state.sourceid === "Target" &&
                  this.state.targetDragsourceid === "Constant" &&
                  (column.id === "Field" || column.id === "Operators"))
              ) {
                isDropDisabled = true;
              }

              return (
                <Column
                  key={column.id}
                  column={column}
                  fields={fields}
                  isDropDisabled={isDropDisabled}
                />
              );
            })}
          </Container>
        </DragDropContext>  
       <TopContainer>
          <ConLable>
            Note: To craete formula, Drag and drop "Field", "Operators" and "Constant" to "Target"
          </ConLable>
        </TopContainer>
      
      </Maindiv>
    );
  }
}

export default withStyles(styles)(FormulaEditor);