import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { InvoiceCheckItemShowActions } from './InvoiceCheckItemActions';
import ConsoleLog from '../Common/ConsoleLog';
import { connect } from 'react-redux';

const InvoiceCheckItemShow = ({naviPath,...props}) => {
    console.log("Show", naviPath);
    //debugger;
    return (
        <Show actions={<InvoiceCheckItemShowActions record={naviPath}           
            permissions={props.location ? props.location.state ?
                props.location.state.record : {
                    UpdateRight: "N",
                    DeleteRight: "N"
                } :
                {
                    UpdateRight: "N",
                    DeleteRight: "N"
                }} />}  {...props} title="Invoice Check Item Details" >
            <SimpleShowLayout>
                {/* <ConsoleLog> "Invoice Check show props {props}" </ConsoleLog> */}
                <TextField source="id" />
                <TextField source="ic_Name" label="Name" />
                <TextField source="Point1" label="Point1" />
                <TextField source="Point2" label="Point2" />
            </SimpleShowLayout>
        </Show>
    )
};

const mapStateToProps = state => {
    return {
        naviPath:{
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
            invoiceCheck: state.selectionState.navItem.invoiceCheck,
            invoiceCheckName: state.selectionState.navItem.invoiceCheck.value.ic_Name,
            invoiceCheckId: state.selectionState.navItem.invoiceCheck.value.id,
            invoiceCheckItems: state.selectionState.navItem.invoiceCheckItems,
            // invoiceCheckItemsName: state.selectionState.navItem.invoiceCheckItems.value.ic_Name,
            // invoiceCheckItemsId: state.selectionState.navItem.invoiceCheckItems.value.id,
            
       },
        isLoggedIn: state.admin.auth.isLoggedIn,
    }
}
const InvoiceCheckItemShow2Redux=connect(
    mapStateToProps,
    null
) (InvoiceCheckItemShow);

export default InvoiceCheckItemShow2Redux;