import React from 'react';
import {
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, Toolbar
} from 'react-admin';

import UpdateCrudButton from '../../Common/UpdateCrud';
import DeleteWithConfirmButton from '../../Common/DeleteCrud';
import { withStyles } from '@material-ui/core';
import { styles, toolbarStyles } from '../../Common/Styles';
import { EditActions } from '../../Common/Actions';

const SupplierEdit = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    const posDigitNumbers = (min,max, message = `Please enter  ${min}-${max} digits`) =>
        value => value &&  (value.length < min || value.length > max || isNaN(value) || value < 0 ) ? message : undefined;

    return (
        <Edit actions={<EditActions />} {...props} >
            <SimpleForm toolbar={<UpdateToolbar basePath="/Countries" />}>
                <TextInput label="Supplier Name" source="sp_Name" validate={required()} className={classes.textFld} />
                <TextInput label="Supplier ERP Number" source="sp_ERP_Number" validate={required(), posDigitNumbers(5,8)} className={classes.textFld} />
                <ReferenceInput label="Country" source="sp_co_id" reference="Countries" resource="Countries"
                    filter={{ is_all: true }}
                    resettable validate={required()}  >
                    <SelectInput optionText="co_Name" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export default withStyles(styles)(SupplierEdit);

const UpdateToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath="/Countries"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            disabled={false}
        />
        <DeleteWithConfirmButton
            label="Delete"
            redirect="list"
            basePath="/Countries/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="Countries"
            undoable={false}
            disabled={false}
        />
    </Toolbar>
));
