import React, { Fragment } from "react";
import { connect } from 'react-redux';
import {
  EditController,
  EditView,
  SimpleForm,
  FunctionField,
  TextInput,
  ReferenceInput,
  FormDataConsumer,
  SelectInput,
  Toolbar,
  withDataProvider,
  required,
} from "react-admin";
import { styles, toolbarStyles } from "../Common/Styles";
import { withStyles } from "@material-ui/core";
import UpdateCrudButton from "../Common/UpdateCrud";
import DeleteWithConfirmButton from "../Common/DeleteCrud";
import ConsoleLog from "../Common/ConsoleLog";
import { ModelEditActions } from "./ModelActions";
import compose from 'recompose/compose';

const EditTitle = ({ record }) => {
  return <span>Edit Model # {record ? `${record.id}` : ""}</span>;
};

const ModelEdit = ({ classes, naviPath, modelPermissions, ...props }) => {



  //console.log("Model edit function ", { props });

  /* const required = (message = "Required") => (value) =>
     value ? undefined : message;*/

  //console.log(props);
  return (
    <EditController {...props}>
      {(controllerProps) => (
        <EditView
          actions={
            <ModelEditActions naviPath={naviPath} toolbarPermissions={modelPermissions} />
          }
          title={<EditTitle />}
          {...props}
          {...controllerProps}
        >
          <SimpleForm
            toolbar={
              <UpdateToolbar
                basePath="Models"
                record={controllerProps.record}
                toolbarPermissions={modelPermissions}
              />
            }
          >
            <TextInput
              label="Model Name"
              source="m_Name"
              className={classes.textFld}
            />
            <TextInput
              label="Model Description"
              source="m_Description"
              className={classes.textFld}
            />
            <ReferenceInput
              label="Time Zone"
              source="m_tz_id"
              reference="TimeZone"
              resource="TimeZone"
              resettable
              validate={required()}
            >
              <SelectInput optionText="tz_Name" />
            </ReferenceInput>
            <TextInput
              label="Purchase Order"
              source="m_PurchaseOrder"
              className={classes.textFld}
            />
          </SimpleForm>
        </EditView>
      )}
    </EditController>
  );
};

const UpdateToolbar = ({ toolbarPermissions, naviPath, ...rest }) => {
  //console.log(rest.basePath);
  return (
    <Toolbar {...rest} style={{ display: "flex" }} >

      <UpdateCrudButton
        label="Save"
        redirect="list"
        basePath={rest.basePath}
        submitOnEnter={false}
        variant="flat"
        color="primary"
        record={rest.record}
        disabled={toolbarPermissions.UpdateRight === 'N'}
      />
      <div style={{ marginLeft: "auto" }}>
        <DeleteWithConfirmButton
          label="DeleteFromAction"
          redirect="list"
          basePath="/Models/:id"
          submitOnEnter={false}
          variant="flat"
          color="primary"
          resource="Models"
          undoable={false}
          record={rest.record}
          disabled={toolbarPermissions.DeleteRight === 'N'}
        />
      </div>
    </Toolbar>
  )
};


const mapStateToProps = state => {
  return {
    naviPath: {
      contract: state.selectionState.navItem.contract,
      contractName: state.selectionState.navItem.contract.value.c_Name,
      model: state.selectionState.navItem.model,
      modelId: state.selectionState.navItem.model.value.id,
      modelName: state.selectionState.navItem.model.value.Name
    },
    modelPermissions: {
      UpdateRight: state.selectionState.navItem.model.value.UpdateRight,
      DeleteRight: state.selectionState.navItem.model.value.DeleteRight
    },
    isLoggedIn: state.admin.auth.isLoggedIn,
  }
}
const ModelEdit2Redux = connect(
  mapStateToProps,
  null
)
  (ModelEdit);

const enhance = compose(
  withDataProvider,
  withStyles(styles)
);

export default enhance(ModelEdit2Redux);


