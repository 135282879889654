export default ( State={'navItem':{}},{ type, payload }) => {

    switch(type){
        case 'ADD_SELECTION':  let newObj={
                                        type: payload.type,
                                        value:payload.value,
                                        valid: true,
                                        };
                                    return {
                                            ...State,
                                            navItem: {
                                              ...State.navItem, [payload.type]: newObj
                                            }
                                          }
                                    
                                /***use if state=[] ***/
                                /*let idx =[...State].findIndex(v => v.type === payload.type);
                                if(idx ===-1)
                                    return[...State,newObj];
                                else  State[idx]=newObj; */


        case 'REMOVE_SELECTION': console.log('REMOVE_SELECTION');
                                /*return {
                                    added: false  
                                    };*/
                                    return State;


        default:
            return State;

    }
   
}
