import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SaveButton,
    Toolbar,
    ReferenceInput, 
    SelectInput
} from 'react-admin';
import { CreateActions } from '../../Common/Actions';
import { withStyles } from '@material-ui/core';
import { styles, toolbarStyles } from '../../Common/Styles';
import ConsoleLog from '../../Common/ConsoleLog';

const EditTitle = ({ record }) => {
    return <span>Copy Model #  {record.m_id}</span>;
};

const CopyModelCreate = props => {

    const { classes, location } = props;
    
    const required = (message = 'Required') =>
        value => value ? undefined : message;

    const number = (message = 'Must be a number') =>
        value => value && isNaN(Number(value)) ? message : undefined;

    const DateValidation = (value, allValues) => {

        if (!value) {
            return 'The date is required';
        }
        if (value < allValues.Valid_from) {

            return 'Must be in future ';
        }
    };

    const validateDate = [required(), DateValidation];

    const CreateToolbar = withStyles(toolbarStyles)(props => (
        <Toolbar {...props}>
            <SaveButton label="Copy" variant="flat" color="primary" />
        </Toolbar>
    ));

    const redirect = (basePath, id, data) => `/Models`;

    return (
        <Create actions={<CreateActions basePath="/Models" />} {...props} title={<EditTitle record={location.state.record} />}>
            <SimpleForm toolbar={<CreateToolbar />} redirect={redirect} >
                <TextInput label="New Model" source="m_Name" defaultValue={location.state.record.data.Name} validate={required()} className={classes.textFld} />
                <TextInput label="New Model Description" defaultValue={location.state.record.data.Description} source="m_Description" validate={required()} className={classes.textFld} />
                <ReferenceInput label="Time Zone" source="tzId" defaultValue={location.state.record.data.m_tz_id} reference="TimeZone" resource="TimeZone"
                    resettable validate={required()}  >
                    <SelectInput optionText="tz_Name" />
                </ReferenceInput>
                <TextInput
                    label="Purchase Order"
                    source="m_PurchaseOrder"
                    className={classes.textFld}
                />
            </SimpleForm>
        </Create>
    );
};

export default withStyles(styles)(CopyModelCreate);
