import React from 'react';
import { CardActions, RefreshButton,ListButton,CreateButton } from 'react-admin';
import  NavigationPath from '../../Common/NavigationPath'
import { cardActionStyle } from '../../Common/Styles';





export const ApprovalAuditListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    naviPath,
    total
}) => {return (
<CardActions style={cardActionStyle}  >
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <NavigationPath {...naviPath}/>
        <div style={cardActionStyle.actionBlock}>
            <CreateButton 
                label="Create" 
                basePath={basePath} 
                /*to={{
                    pathname: '/Points/create',
                    state: { record: { modelId: record.modelId } },
                }} */
                //resource={} 
                variant="flat" 
                color="primary"
                //record={record}
                disabled={true}
            />
            {/* <ConsoleLog > "Model Action:" + {record} </ConsoleLog> */}
            {/* <ConsoleLog>{this.props}</ConsoleLog> */}
            {/*<ExportButton resource= "Contracts"/>*/}
            <RefreshButton variant="flat" color="primary" />
        </div>
    </CardActions>

)};

export default ApprovalAuditListActions;