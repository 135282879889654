import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { showNotification } from 'react-admin';
import Button from '@material-ui/core/Button';
import { FlashOn, Receipt, ViewList, Visibility, PlaylistAddCheck, BlurCircular, ContentCopy } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

// Define icons for Navigation button
const icons = {
    CopyContract: ContentCopy,
    CopyModel: ContentCopy,
    Models: FlashOn,
    Points: BlurCircular,
    InvoiceChecks: Receipt,
    InvoiceCheckItems: ViewList,
    ApprovalData: Visibility,
    ApprovalAudit: PlaylistAddCheck,
};

// Return Icon based on pathname
const FieldIcon = ({ pathName }) => {
    //console.log(name);
    pathName = pathName;
    const Icon = icons[pathName];
    return Icon ? (<Icon />) : null;
};

// Define path for checking basepath
const Epath = {
    CopyContract: "CopyContract",
    CopyModel: "CopyModel",
    Models: "Models",
    Points: "Points",
    InvoiceChecks: "InvoiceChecks",
    InvoiceCheckItems: "InvoiceCheckItems",
    ApprovalData: "ApprovalData",
    ApprovalAudit: "ApprovalAudit"

}


 class NavigationButton extends Component {
    constructor(props) {
        super(props);
    
   
    }

    handleClick = (e) => {
        //debugger;
        const { record,storeSelection,path } = this.props;
        console.log("*********Navi-Button*************************");
        console.log(this.props);
        
        if(e==="contractId")
            storeSelection("contract",record) 
        else if(e==="modelId") 
            storeSelection("model",record)

        //if (this.props.basePath == Epath.InvoiceChecks) 
        //    console.log("************INVOICECHECKS**********************")
            
        else if(e==="invoiceCheckId"){
            storeSelection("invoiceCheck",record)
        }
        else if (path == Epath.ApprovalAudit) {
             storeSelection("audit",record)
         }


       

        
        //e.stopPropagation();
    }

    render() {

        //debugger;
        //console.log(this.props);
        const { path, record } = this.props;
        var to = null;
        var disabled = false;
        var value=null;
        console.log("Navigation bar",record.Name);
        // Define route (pathname and state) based on path passed through navigantion button attributeF
        if (path == Epath.CopyContract.toString()) {
           
            to = {
                pathname: `${path}/create`,                
                state: {
                    record: {
                        c_id: record.id
                    }
                }
            }
            value= "contractId"
            disabled = (record.UpdateRight === 'N');
        }
        else if (path == Epath.CopyModel.toString()) {
            to = {
                pathname: `${path}/create`,                
                state: {
                    record: {
                        m_id: record.id,
                        data: record
                    }
                }
            }
            value= "modelId"
            disabled = (record.UpdateRight === 'N');
        }
        else if (path == Epath.Models.toString() || path == Epath.InvoiceChecks.toString()) {
            
            to = {
                pathname: path,
                state: {
                    record: {
                        contractName:record.Name,
                        contractId: record.id,
                        UpdateRight: record.UpdateRight,
                        DeleteRight: record.DeleteRight
                    },
                   
                }
            }

            if (path === Epath.InvoiceChecks.toString()) {
                disabled = (record.ExecuteRight === 'Y') ? false : true;
            }
             value= "contractId";
        }
        else if (path == Epath.Points.toString()) {
            
            to = {
                pathname: path,
                state: {
                    record: {
                        modelName:record.Name,
                        modelId: record.id,
                        UpdateRight: record.UpdateRight,
                        DeleteRight: record.DeleteRight
                    }
                    
                }
            }
             value= 'modelId'
        }
        else if (path == Epath.InvoiceCheckItems.toString()) {
            console.log("recordInvoice:", record)
              //debugger; 
            to = {
                pathname: path,
                state: {
                    record: {
                        invoiceCheckId: record.id,
                        ic_m_id_1: record.ic_m_id_1,
                        ic_m_id_2: record.ic_m_id_2,
                    }
                }
            }
            disabled = (record.ExecuteRight === 'Y') ? false : true;
            value= 'invoiceCheckId';
        }
        else if (path == Epath.ApprovalData.toString()) {
            to = {
                pathname: `/${path}`,
                state: {
                    record: {
                        ic_id: record.ic_id,
                        StartDateTime: record.a_StartDateTime,
                        EndDateTime: record.a_EndDateTime,
                    }
                },
            }
            disabled = (record.ViewRight === 'Y') ? false : true;
        }
        else if (path == Epath.ApprovalAudit.toString()) {
            to = {
                pathname: `/${path}`,
                state: {
                    record: {
                        ic_id: record.ic_id,
                    }
                },
            }
            disabled = (record.ViewRight === 'Y') ? false : true;
        }

        //console.log(to);

        return (<Button
            label=""
            onClick={()=>this.handleClick(value)}
            component={Link}
            to={to}
            disabled={disabled}
            color="primary"
        >
            <FieldIcon pathName={path} /> </Button>);
    }
}

NavigationButton.propTypes = {
    record: PropTypes.object,
    path: PropTypes.string,
    basePath: PropTypes.string,
};

NavigationButton.defaultProps = {
    undoable: "false",
};

const mapState = (state) => {
    return {
      readContracts: state.admin.resources.Contracts.data,
      isLoading: state.admin.loading,
    };
  };

  const mapDispatch = (dispatch) => {
    return {
        storeSelection: (typeName,record) => record ? dispatch({type: "ADD_SELECTION",payload:{type: typeName,value: record}}) : null,
        showNotification,
      
    }
  }

export default connect(null, mapDispatch)(NavigationButton);
