import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { InvoiceCheckItemListActions } from './InvoiceCheckItemActions';
import EditButton from '../Common/EditButton';
import  ShowButton  from '../Common/ShowButton';
import DeleteCrud from '../Common/DeleteCrud';
import ConsoleLog from '../Common/ConsoleLog';
import { PostPagination } from '../Common/Pagination';
import { MuiThemeProvider } from '@material-ui/core';
import { tableMuiTheme } from "../Common/Styles";
import { AccessDenied } from "../Common/NoAccess";
import ButtonWithHandleSubmit from '../Common/ButtonWithHandleSubmit';
import CheckPagePermission from "../Common/CheckPagePermissionJs";
import { connect } from 'react-redux';

const InvoiceCheckItemList = ({ pagePermissions,contract,invoiceCheck, ...props }) => {

   //debugger;
    console.log("Ivoice: ", props);
    const naviPath= {
        contractId: contract.value.id,
        contractName: contract.value.c_Name,
        invoiceCheckId: invoiceCheck.value.id,
        invoiceCheckName: invoiceCheck.value.ic_Name
    }

    /*record={props.location.state ?
        props.location.state.record : {
            invoiceCheckId: props.record.invoiceCheckId,
            ic_m_id_1: props.record.ic_m_id_1,
            ic_m_id_2: props.record.ic_m_id_2,
        }}*/
    return (
       
        <CheckPagePermission
            authParams={{ Admin: false }}
            location={window.location}
            match="InvoiceCheckItems"
            render={({ pagePermissions }) =>
                <div>
                    {pagePermissions && pagePermissions.View === "Y" ?
                        <MuiThemeProvider theme={tableMuiTheme}>
                            <List actions={<InvoiceCheckItemListActions record={naviPath } permissions={pagePermissions} />}
                                filter={{
                                    invoiceCheckId: naviPath.invoiceCheckId
                                }}
                                pagination={<PostPagination />}
                                title="Invoice Check Items" {...props}
                                sort={{ field: 'ici_Order', order: 'DESC' }}
                                bulkActionButtons={false}
                            >
                                <Datagrid rowClick="show" >
                                    {/* <ConsoleLog>"Invoice check item list props: {props.location.state} "</ConsoleLog> */}
                                    <TextField source="id" />
                                    <TextField source="ic_Name" label="Name" />
                                    <TextField source="Point1" label="Point1" />
                                    <TextField source="Point2" label="Point2" />
                                    <ButtonWithHandleSubmit resource="InvoiceCheckItems" path="InvoiceCheckItems/ShiftUp" basePath={props.basePath}
                                        label="ShiftUp" record={props.record} />
                                    <ButtonWithHandleSubmit resource="InvoiceCheckItems" path="InvoiceCheckItems/ShiftDown" basePath={props.basePath}
                                        label="ShiftDown" record={props.record} />
                                    <EditButton label="Edit" resource="/InvoiceCheckItems/:id" basePath={props.basePath} record={props.record} />
                                    <ShowButton label="Show" resource="/InvoiceCheckItems/:id" basePath={props.basePath} record={props.record} />
                                    <DeleteCrud label="Delete" resource="/InvoiceCheckItems/:id" basePath={props.basePath} record={props.record} />
                                </Datagrid>
                            </List>
                        </MuiThemeProvider>
                        : <AccessDenied />
                    }
                </div>}
        />
    )
};

const mapState = state => {
    return {
        contract: state.selectionState.navItem.contract,
        invoiceCheck: state.selectionState.navItem.invoiceCheck,
        isLoggedIn: state.admin.auth.isLoggedIn,
      

    }
}
export default connect(mapState, null)(InvoiceCheckItemList);
// aside={<Aside title="Models" body="Model is editable and details are only shown if authorized" />}