import React, { Fragment } from 'react';
import { EditController, EditView, SimpleForm, ReferenceInput, SelectInput, Toolbar } from 'react-admin';
import { styles, toolbarStyles } from '../Common/Styles';
import { withStyles } from '@material-ui/core';
import UpdateCrudButton from '../Common/UpdateCrud';
import DeleteWithConfirmButton from '../Common/DeleteCrud';
import ConsoleLog from '../Common/ConsoleLog';
import { reset } from 'redux-form';
import { InvoiceCheckItemEditActions } from './InvoiceCheckItemActions';
import { connect } from 'react-redux';

const EditTitle = ({ record }) => {
    return <span>Edit Invoice Check #  {record ? `${record.id}` : ''}</span>;
};

const required = (message = 'Required') =>
value => value ? undefined : message;

const InvoiceCheckItemEdit = ({naviPath,classes,...props}) => {

   //debugger;
    //console.log(props);

    const Permission = props.location ? props.location.state ? props.location.state.record :
        {
            UpdateRight: "N",
            DeleteRight: "N"
        } : {
            UpdateRight: "N",
            DeleteRight: "N"
        };
        
    return (
        <EditController   {...props}>
            {controllerProps =>
                <EditView actions={<InvoiceCheckItemEditActions record= {naviPath}/>}
                    title={<EditTitle />} permission = {Permission} {...props} {...controllerProps} >
                    <SimpleForm toolbar={<UpdateToolbar basePath={controllerProps.basePath} permission = {Permission} />} >
                        <ReferenceInput label="Point1" source="ici_p_id_1" reference="/Points/All" resource="/Points/All"
                            filter={{
                                modelId: controllerProps.record.ic_m_id_1 
                            }}
                            resettable 
                            //validate={required}  
                            >
                            <SelectInput optionText="p_Name" />
                        </ReferenceInput>
                        <ReferenceInput label="Point2" source="ici_p_id_2" reference="/Points/All" resource="/Points/All"
                            filter={{
                                modelId: controllerProps.record.ic_m_id_2 
                            }}
                            resettable 
                            //validate={required}  
                            >
                            <SelectInput  optionText="p_Name"  />
                        </ReferenceInput>
                    </SimpleForm>
                </EditView>
            }
        </EditController >
    )
};

const UpdateToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath={props.basePath}
            submitOnEnter={false}
            variant="flat"
            color="primary"
            disabled={props.permission ? props.permission.UpdateRight === "N" : "N"}
        />
        {/* <ConsoleLog>"InvoiceCheckItemEdit update crud button: {props}"</ConsoleLog> */}
        <DeleteWithConfirmButton
            label="DeleteFromAction"
            redirect="list"
            basePath="/InvoiceCheckItems/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="InvoiceCheckItems"
            undoable={false}
            disabled={props.permission ? props.permission.DeleteRight === "N" : "N"}
        />
    </Toolbar>
));



const mapStateToProps = state => {
    return {
        naviPath:{
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
            invoiceCheck: state.selectionState.navItem.invoiceCheck,
            invoiceCheckName: state.selectionState.navItem.invoiceCheck.value.ic_Name,
            invoiceCheckId: state.selectionState.navItem.invoiceCheck.value.id,
            invoiceCheckItems: state.selectionState.navItem.invoiceCheckItems,
            invoiceCheckItemsName: state.selectionState.navItem.invoiceCheckItems.value.ic_Name,
            invoiceCheckItemsId: state.selectionState.navItem.invoiceCheckItems.value.id,
            
       },
        isLoggedIn: state.admin.auth.isLoggedIn,
    }
}
const InvoiceCheckItemEdit2Redux=connect(
    mapStateToProps,
    null
) (InvoiceCheckItemEdit);

export default withStyles(styles)(InvoiceCheckItemEdit2Redux);
