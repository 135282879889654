import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {BooleanField} from 'react-admin';


const colorStyle = {
    true: { color: 'green' },
    false: { color: 'red' },
};

const StyledBooleanField = withStyles(colorStyle)(
    ({ classes,record, ...props }) => (
        <BooleanField
            className={classnames({
                [classes.true]: record[props.source] ==='Y',
                [classes.false]:record[props.source] === 'N',
            })}

            record={{
                [props.source]: record[props.source] ==='Y' ? true : false,     
            }}

            {...props}
        />
    ));
    
    export default  StyledBooleanField;