import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListActions from '../../Common/Actions';
import EditButton from '../../Common/EditButton';
import { PostPagination } from '../../Common/Pagination';
import CheckPagePermission from "../../Common/CheckPagePermissionJs";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { isEqual } from 'lodash';
import { tableMuiTheme } from "../../Common/Styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const FrequencyList = ({permissions,...props}) => 
<CheckPagePermission
authParams={{ Admin: false }}
location={window.location}
match="Frequencies"
render={({ pagePermissions }) =>
<div>
  {
  pagePermissions ?
  (pagePermissions.View ==='Y') ?   
            <MuiThemeProvider theme={tableMuiTheme}>
                <List actions={<ListActions permissions {...props} />}
                    pagination={<PostPagination />}
                    sort={{ field: 'fq_id', order: 'DESC' }}
                    title="Frequencies"
                    {...props}
                    bulkActionButtons={false}
                >
                    <Datagrid rowClick="show"  >
                        <TextField source="id" label="Id" />
                        <TextField source="fq_Name" label="Name" />
                        <TextField source="fq_Minute" label="Minute" />
                        <TextField source="fq_Day" label="Day" />
                        <TextField source="fq_Month" label="Month" />
                        <EditButton label='Edit' resource="/Frequencies/:id" basePath={props.basePath} />
                    </Datagrid>
                </List>
            </MuiThemeProvider> : null : null
}</div>
}/>