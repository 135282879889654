import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { ShowActions } from '../../Common/Actions';

export const RegionShow = props => (
    <Show actions={<ShowActions />}  {...props} title="Region Details" >
        <SimpleShowLayout>
            <TextField source="id" label="Id" />
            <TextField source="r_Name" label="Name" />            
        </SimpleShowLayout>
    </Show>
);