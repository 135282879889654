import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { toolbarStyles } from './Styles';
import { withStyles } from '@material-ui/core';

const CreateToolbar = props => {
    return (
        <Toolbar {...props}>
            <SaveButton label="Save" variant="flat" color="primary" />
        </Toolbar>
    );
};

export default withStyles(toolbarStyles)(CreateToolbar);