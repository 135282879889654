import React from 'react';
import { CardActions, CreateButton, RefreshButton, ListButton } from 'react-admin';
import ConsoleLog from '../Common/ConsoleLog';
import { cardActionStyle } from '../Common/Styles';
import  EditButton  from '../Common/EditButton';
import  NavigationPath from '../Common/NavigationPath';

export const InvoiceCheckItemListActions = ({
    create,
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions,
    record, ...rest }) => (
        <CardActions style={cardActionStyle}  >
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <NavigationPath {...record}/>
            <div  style={cardActionStyle.actionBlock}>
            <CreateButton label="Create" basePath={basePath} to={{
                pathname: '/InvoiceCheckItems/create',
                state: {
                    record: {
                        invoiceCheckId: record.invoiceCheckId,
                        ic_m_id_1: record.ic_m_id_1,
                        ic_m_id_2: record.ic_m_id_2,
                    }
                },
            }} resource={create} variant="flat" color="primary"
                record={record}
                disabled={permissions.Insert == "N"}
            />
            {/* <ConsoleLog > "InvoiceCheckItem Action:" + {record} </ConsoleLog> */}
            {/* <ConsoleLog>{this.props}</ConsoleLog> */}
            {/*<ExportButton resource= "invoiceChecks"/>*/}
            <RefreshButton variant="flat" color="primary" />
            </div>
        </CardActions>
    );

export default InvoiceCheckItemListActions;

export const InvoiceCheckItemShowActions = ({ basePath, data,record, resource, permissions }) => (
    <CardActions style={cardActionStyle}>
         <NavigationPath {...record}/>
        <div  style={cardActionStyle.actionBlock}>
        <EditButton label="EditFromAction" basePath={basePath} to={{
            pathname: `/InvoiceCheckItems/${data.id}`,
            state: {
                record: {
                    invoiceCheckId: data.ici_ic_id,
                    ic_m_id_1: data.ic_m_id_1,
                    ic_m_id_2: data.ic_m_id_2,
                    //data: data
                }
            },
        }} record={{ ...data, ...permissions }} />
        <ListButton label="Table" basePath={basePath} to={{
            pathname: `${basePath}`,
            state: {
                record: {
                    invoiceCheckId: data.ici_ic_id,
                    ic_m_id_1: data.ic_m_id_1,
                    ic_m_id_2: data.ic_m_id_2,               
                    //data: data
                }
            },
        }} variant="flat" color="primary" />
        {/* <ConsoleLog>"Data:" + {data}</ConsoleLog> */}
        </div>
    </CardActions>
);

export const InvoiceCheckItemCreateActions = ({ basePath, record, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <ConsoleLog>$"InvoiceCheckItem Create Action: {record}"</ConsoleLog> */}
        <NavigationPath {...record}/>
        <div  style={cardActionStyle.actionBlock}>
        <ListButton label="Table" basePath={basePath} to={{
            pathname: `/InvoiceCheckItems`,
            state: {
                record: {
                    invoiceCheckId: record.invoiceCheckId,
                    ic_m_id_1: record.ic_m_id_1,
                    ic_m_id_2: record.ic_m_id_2,
                    //data : record
                }
            },
        }} variant="flat" color="primary" />
        </div>
    </CardActions>
);

export const InvoiceCheckItemEditActions = ({ basePath, record, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <ConsoleLog >"InvoiceCheckItem edit action record:: {record}"</ConsoleLog> */}
        <NavigationPath {...record}/>
        <div  style={cardActionStyle.actionBlock}>
        <ListButton label="Table"
            basePath={{
                pathname: `${basePath}`,
                state: {
                    record: {
                        invoiceCheckId: record.invoiceCheckId,
                        ic_m_id_1: record.ic_m_id_1,
                        ic_m_id_2: record.ic_m_id_2,
                    }
                },
            }}
            //basePath={basePath}
            variant="flat" color="primary" />
            </div>
    </CardActions >
);