import InvoiceCheckList from './InvoiceCheckList';
import InvoiceCheckShow from './InvoiceCheckShow';
import InvoiceCheckCreate from './InvoiceCheckCreate';
import InvoiceCheckEdit from './InvoiceCheckEdit';

export default {
    list: InvoiceCheckList,
    create: InvoiceCheckCreate,
    edit: InvoiceCheckEdit,
    show: InvoiceCheckShow
};