export default ( State={'pageRegistry':{}},{ type, payload }) => {

    switch(type){
       

        case 'REMOVE_PERMISSONS': console.log('REMOVE_PERMISSONS');
                               
                                    return State;


        case 'ADD_PERMISSIONS':  let newObj2={
                                        type: payload.type,
                                        value:payload.value,
                                        };

                                return {
                                    ...State,
                                    pageRegistry: {
                                      ...State.pageRegistry, [payload.value.Name]: newObj2
                                    }
                                  }
            case 'ADD_PERMISSIONS_SUCCDEEDED': 
                                  return State;
            case 'ADD_PERMISSIONS_FAILED': 
                                  return State;

        default:
            return State;

    }
   
}
