import React from 'react';
import { CardActions, CreateButton, RefreshButton, ListButton, ShowButton } from 'react-admin';
import { cardActionStyle } from '../Common/Styles';
import  EditButton  from '../Common/EditButton';
import { disableActionCreateButton } from '../Common/Actions';
import  NavigationPath from '../Common/NavigationPath'

export const ContractListActions = ({
    create,
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    permissions,
    total }) => (
        <CardActions style={cardActionStyle.actionBlock}  >
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {/* <CreateButton basePath="/Contracts" color="primary" /> */}
            {/*<ExportButton resource= "Contracts"/>*/}
            
            <CreateButton basePath={basePath} variant="flat" color="primary" disabled={permissions.Insert == "N"} />
            <RefreshButton variant="flat" color="primary" />

        </CardActions>
    );

export const ContractShowActions = ({ basePath, data,naviPath,contractPermissions, resource, permissions }) => {
    const contract =naviPath.contract.value;

    return (
        <CardActions style={{ display: "flex" }}>
            <NavigationPath {...naviPath} />
            <div  style={{ marginLeft: "auto" }}>
            <EditButton label="EditFromAction" basePath= {basePath}   record ={{...contract,...permissions}} variant="flat" color="primary" />
            <ListButton label="Table" basePath={basePath} variant="flat" color="primary" />
            </div>
        </CardActions>
    )
};

export const ContractCreateActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton label="Table" basePath={basePath} variant="flat" color="primary" />
    </CardActions>
);

export const ContractEditActions = ({ basePath, record, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <ShowButton label="Details" basePath={basePath} record={record} resource="/Contracts/:id" /> */}
        <ListButton label="Table" basePath={basePath} variant="flat" color="primary" />
    </CardActions >
);