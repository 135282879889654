import React from 'react';
import {
    Create, SimpleForm, TextInput, AutocompleteInput
} from 'react-admin';
import { CreateActions } from '../../Common/Actions';
import CreateToolbar from '../../Common/CreateToolbar';
import { withStyles } from '@material-ui/core';
import { styles } from '../../Common/Styles';

const UnitCreate = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    return (
        <Create actions={<CreateActions />} {...props} >
            <SimpleForm toolbar={<CreateToolbar {...props} />} redirect="show">
                <TextInput label="Unit Name" source="un_Name" validate={required()} className={classes.textFld} />
                <AutocompleteInput label="IsCurrency" source="un_IsCurrency" choices={choices} optionText="name" optionValue="_id" />
            </SimpleForm>
        </Create>
    );
};

export const choices = [
    { _id: 'Y', name: 'Yes' },
    { _id: 'N', name: 'No' },
];

export default withStyles(styles)(UnitCreate);