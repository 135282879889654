import React from 'react';
import {
    Create, SimpleForm, TextInput, SaveButton, Toolbar,
    ReferenceInput, SelectInput
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import { InvoiceCheckCreateActions } from './InvoiceCheckActions';
import { reset } from 'redux-form';
import { styles, toolbarStyles } from '../Common/Styles';
import {required } from  '../Common/Validator';
import { connect } from 'react-redux';


/*const required = (message = 'Required') =>
value => value ? undefined : message;*/

const InvoiceCheckCreate = ({classes,naviPath,...props}) => {

    const CreateToolbar = withStyles(toolbarStyles)(props => (
        <Toolbar {...props}>
            <SaveButton label="Save" variant="flat" color="primary" />
        </Toolbar>
    ));

    return (
        <Create title="Create Invoice check" actions={<InvoiceCheckCreateActions record={naviPath} />} {...props} >
            <SimpleForm toolbar={<CreateToolbar />} redirect="list">
                <TextInput label="Name" source="ic_Name" validate={required()} className={classes.textFld} />
                <ReferenceInput label="Model1" source="ic_m_id_1" reference="Models" resource="Models"
                    filter={
                        props.location.state ?
                            props.location.state.record : {
                                contractId: props.record.contractId,
                            }}
                    sort={{ field: 'Name', order: 'ASC' }}
                    resettable validate={required()}  >
                    <SelectInput optionText="Name" />
                </ReferenceInput>
                <ReferenceInput label="Model2" source="ic_m_id_2" reference="Models" resource="Models"
                    filter={
                        props.location.state ?
                            props.location.state.record : {
                                contractId: props.record.contractId,
                            }}
                    sort={{ field: 'Name', order: 'ASC' }}
                    resettable validate={required()}  >
                    <SelectInput optionText="Name" />
                </ReferenceInput>
                <ReferenceInput label="Frequency" source="ic_fq_id" reference="Frequencies"
                    filter={{ is_all: true }}>
                    <SelectInput source="Frequencies" optionText="fq_Name" />
                </ReferenceInput>
                {/* <ReferenceInput label="Role" source="ic_ro_id" reference="Roles">
                    <SelectInput source="Roles" optionText="ro_Name" />
                </ReferenceInput> */}
                <TextInput
                    label="Valid From"
                    type="month"
                    source="ic_ValidFrom"
                    defaultValue=""
                    className={classes.margin}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    ///style={styleMargin.margin}
                    validate={required()}
                    {...reset}
                />
                <TextInput
                    label="Valid To"
                    type="month"
                    source="ic_ValidTo"
                    defaultValue=""
                    className={classes.margin}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    ///style={styleMargin.margin}
                    validate={required()}
                    {...reset}
                />
            </SimpleForm>
        </Create>
    );
};

const mapStateToProps = state => {
    return {
        naviPath:{
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
       },
        isLoggedIn: state.admin.auth.isLoggedIn,
    }
}
const InvoiceCheckCreate2Redux=connect(
    mapStateToProps,
    null
) (InvoiceCheckCreate);

export default withStyles(styles)(InvoiceCheckCreate2Redux);