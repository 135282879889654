import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { connect } from 'react-redux';
import { PointShowActions } from './PointActions'
import { styles } from '../Common/Styles';
import { withStyles } from '@material-ui/core';
import ConsoleLog from '../Common/ConsoleLog';

const PointShow = withStyles(styles)(({ classes, ...props }) =>
    < RenderShow {...props} />
    // <ConsoleLog> "Point show props {props}" </ConsoleLog>
);

const mapStateToProps = state => {
    return {
        naviPath: {
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
            model: state.selectionState.navItem.model,
            modelId: state.selectionState.navItem.model.value.id,
            modelName: state.selectionState.navItem.model.value.Name,
            point: state.selectionState.navItem.point,
            pointId: state.selectionState.navItem.point.value.id,
            pointName: state.selectionState.navItem.point.value.p_Name,
        },
        pointPermissions: {
            UpdateRight: state.selectionState.navItem.point.value.UpdateRight,
            DeleteRight: state.selectionState.navItem.point.value.DeleteRight
        },
    }
}
const PointShow2Redux = connect(
    mapStateToProps,
    null
)
    (PointShow);

export default PointShow2Redux;


const RenderShow = ({ permissions, pointPermissions, ...props }) => {
    console.log("redershow", props);

    var PointTypeId = props.location.state ? props.location.state.record ? props.location.state.record.pointId : 0 : 0;
    // < ConsoleLog > "PointTypeId {PointTypeId} "</ConsoleLog>
    // return (<Fragment>
    //     < ConsoleLog > "Render edit props {props} "</ConsoleLog>
    //     < ConsoleLog > "Point Type id {PointTypeId}  "</ConsoleLog>
    //     {/* <ConsoleLog> {record.point.pointtype}</ConsoleLog> */}
    //     <PointDetailsAggregation {...props} />
    // </Fragment>);

    //debugger;

    var permission = pointPermissions;/*props.location ? props.location.state ?
        props.location.state.record : permissions ? {
            UpdateRight: permissions.Update,
            DeleteRight: permissions.Delete
        } : {
                UpdateRight: "N",
                DeleteRight: "N"
            } : {
            UpdateRight: "N",
            DeleteRight: "N"
        };*/

    if (PointTypeId == 1) {
        return (
            <PointDetailsRaw pointPermissions={pointPermissions} {...props} />
        );
    }
    else if (PointTypeId == 2) {
        return (
            <PointDetailsPrice pointPermissions={pointPermissions} {...props} />
        );
    }
    else if (PointTypeId == 3) {
        return (
            <PointDetailsHistorian pointPermissions={pointPermissions} {...props} />
        );
    }
    else if (PointTypeId == 4) {
        return (
            <PointDetailsConstant pointPermissions={pointPermissions} {...props} />
        );
    }
    else if (PointTypeId == 5) {
        return (
            <PointDetailsAggregation pointPermissions={pointPermissions} {...props} />
        );
    }
    else if (PointTypeId == 6) {
        return (
            <PointDetailsFormula pointPermissions={pointPermissions} {...props} />
        );
    }
    else if (PointTypeId == 0) {
        return (
            <PointDetailsRaw pointPermissions={pointPermissions} {...props} />
        );
    }
};

const PointDetailsRaw = ({ pointPermissions, naviPath, ...props }) => {

    return (
        <Show actions={<PointShowActions permissions={pointPermissions} naviPath={naviPath} />}  {...props} title="Point Details Raw" >
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="point.p_Name" label="Name" />
                <TextField source="point.p_Description" label="Description" />
                <TextField source="point.Frequency.fq_Name" label="Frequency" />
                <TextField source="point.Unit.un_Name" label="Unit" />
                <TextField source="point.p_Account" label="Account" />
                <TextField source="point.p_CostCenter" label="Cost Center" />
            </SimpleShowLayout>
        </Show>
    )
};

const PointDetailsPrice = ({ pointPermissions, naviPath, ...props }) => (
    <Show actions={<PointShowActions permissions={pointPermissions} naviPath={naviPath} />}  {...props} title="Point Details Price" >
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="point.p_Name" label="Name" />
            <TextField source="point.p_Description" label="Description" />
            <TextField source="point.Frequency.fq_Name" label="Frequency" />
            <TextField source="point.Unit.un_Name" label="Unit" />
            <TextField source="point.p_Public" label="Public" />
            <TextField source="point.Country.ca_Name" label="Country" />
        </SimpleShowLayout>
    </Show>
);

const PointDetailsHistorian = ({ pointPermissions, naviPath, ...props }) => (
    <Show actions={<PointShowActions permissions={pointPermissions} naviPath={naviPath} />}  {...props} title="Point Details Historian" >
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="point.p_Name" label="Name" />
            <TextField source="point.p_Description" label="Description" />
            <TextField source="point.Frequency.fq_Name" label="Frequency" />
            <TextField source="point.Unit.un_Name" label="Unit" />
            {/* <TextField source="point.HistorianTag.ht_Tag" label="Historian Tag" /> */}
            <TextField source="point.p_HistorianTag" label="Historian Tag" />
            <TextField source="point.p_HistorianServer" label="Historian Server" />
        </SimpleShowLayout>
    </Show>
);

const PointDetailsConstant = ({ pointPermissions, naviPath, ...props }) => (
    <Show actions={<PointShowActions permissions={pointPermissions} naviPath={naviPath} />}  {...props} title="Point Details Constant" >
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="point.p_Name" label="Name" />
            <TextField source="point.p_Description" label="Description" />
            <TextField source="point.Frequency.fq_Name" label="Frequency" />
            <TextField source="point.Unit.un_Name" label="Unit" />
            <TextField source="point.p_Constant" label="Constant" />
        </SimpleShowLayout>
    </Show>
);

const PointDetailsAggregation = ({ pointPermissions, naviPath, ...props }) => (
    <Show actions={<PointShowActions permissions={pointPermissions} naviPath={naviPath} />}  {...props} title="Point Details Aggregation" >
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="point.p_Name" label="Name" />
            <TextField source="point.p_Description" label="Description" />
            <TextField source="point.Frequency.fq_Name" label="Frequency" />
            <TextField source="point.Unit.un_Name" label="Unit" />
            <TextField source="point.Category.ca_Name" label="Category" />
            <TextField source="point.AggregateFunction.af_Name" label="Aggregation Function" />
            <TextField source="aggregationReferencePoint.p_Name" label="Aggregation Reference Point" />
            <TextField source="aggregationparameterfrom.ap_Name" label="Aggregation From" />
            <TextField source="aggregationparameterto.ap_Name" label="Aggregation To" />
            <TextField source="point.p_Account" label="Account" />
            <TextField source="point.p_CostCenter" label="Cost Center" />
            {/* Block pending */}
        </SimpleShowLayout>
    </Show>
);

const PointDetailsFormula = ({ pointPermissions, naviPath, ...props }) => (
    <Show actions={<PointShowActions permissions={pointPermissions} naviPath={naviPath} />}  {...props} title="Point Details Raw" >
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="point.p_Name" label="Name" />
            <TextField source="point.p_Description" label="Description" />
            <TextField source="point.Frequency.fq_Name" label="Frequency" />
            <TextField source="point.Unit.un_Name" label="Unit" />
            <TextField source="point.Category.ca_Name" label="Category" />
            <TextField source="point.p_Account" label="Account" />
            <TextField source="point.p_CostCenter" label="Cost Center" />
            <TextField source="formula" label="Formula" />
        </SimpleShowLayout>
    </Show>
);

