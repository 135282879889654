// in src/bitcoinSaga.js
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { showNotification, GET_LIST } from 'react-admin';
import { AUTH_GET_PERMISSIONS } from 'ra-core';
import auth from '../AuthProvider/clientAuthServices';
import dataProvider from '../dataProvider/rest';
import authProvider from '../AuthProvider/authProvider';


async function fetchPermission(endpoint) {

    console.log('Permission pathname:' + endpoint);
  //  try {
    const permissions = await dataProvider(GET_LIST, 'permission',
        {
            query: { pa_Name: endpoint ? endpoint : 'Default' },
        });/*,
        {
            onSuccess: {
                notification: { body: 'Comment approved', level: 'info' },
                redirectTo: '/dashboard',
            },
            onFailure: {
                notification: { body: 'Error: comment not approved', level: 'warning' }
            }
        });*/
        return permissions;
   // }catch(e){
   //    console.warn('call of authProvider to get Permissions: ' + e )//+ JSON.stringify(permissions));
      
   // }

    
    

};


async function getpermission(pageName) {

    let authorizedPageUserInfo =null; 
    var sitePermission = { id: 0, Name: "Default", View: "Y", Insert: "N", Update: "N", Delete: "N", Execute: "N" };
   //debugger;
    var userInfo = auth.getUserInfo();
    if (userInfo != null)
        if (pageName !== "login") {
            //var storage = localStorage.getItem(pageName);
           //debugger;
          // try{
            sitePermission = //(storage === null) ?
                  await fetchPermission(pageName)
                    .then(response => {
                        //localStorage.setItem(response.Name, JSON.stringify(response));
                        //console.log("Permission for stored:", response.Name)
                        return response;
                    })
               // : JSON.parse(storage);
                /*    }catch(e)
                    {
                        console.error(e);
                    }*/

        }
    if(sitePermission)
        authorizedPageUserInfo = { ...sitePermission, ...userInfo };

    return Promise.resolve(authorizedPageUserInfo);


}

export default function* permissionSaga() {
    yield takeEvery('ADD_PERMISSION_SAGA', function* (param) {
       //debugger;
       
        try {
            const ret = yield call(getpermission, param.pageName);
           //debugger;
            if(ret===null)
                throw new Error('Can not load permissions');
                try{
                    yield put({
                    type: 'ADD_PERMISSIONS', payload: {
                        type: 'BREAD_PAGE', // (browse, read, edit, add, delete)
                        value: ret  //{id: 0,Name:"Default", View:"Y", Insert: "N", Update:"N", Delete: "N",Execute:"N"}
                        }
                    });

                    yield put({
                        type: 'ADD_PERMISSIONS_SUCCEDED', payload: {
                            type: 'BREAD_PAGE_VALID', 
                           // value: ret  
                            }
                        });
        }catch(e){
            yield put({
                type: 'ADD_PERMISSIONS_FAILED', payload: {
                    type: 'BREAD_PAGE_UNVALID', 
                    //value: ret  
                    }
                });

        }
        } catch (e) {
            yield put(showNotification(e.message, 'warning'));
        }

    })
}

