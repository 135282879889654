import React from 'react';
import {  UserMenu, MenuItemLink, translate } from 'react-admin';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles } from '@material-ui/core/styles';
//import AppBar from "@material-ui/core/AppBar";
import { AppBar } from 'react-admin';

import Logo from './Logo';
import { blue } from '@material-ui/core/colors';

const styles = {
    bar: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        backgroundColor: '#015a92',
        color: 'yellow',
        position: 'relative'
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,

    },
};

 

 

const CustomAppBar = ({ classes, ...props }) => (
    <AppBar {...props} color="inherit" className={classes.bar}>
      <Logo />
        <Typography
            variant="title"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
     
    </AppBar>
);

CustomAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(CustomAppBar);

//export default CustomAppBar;