import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContentCreate from '@material-ui/icons/Create';
import { showNotification, Button } from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConsoleLog from './ConsoleLog';

// Define path for Edit button
const Epath = {
    Contracts: "/Contracts",
    Models: "/Models",
    Points: "/Points",
    InvoiceChecks: "/InvoiceChecks",
    InvoiceCheckItems: "/InvoiceCheckItems",
    Admin: "/Admin/",
}

const styles = ({ spacing }) =>
    createStyles({
        button: {
            position: 'relative',
        },
        leftIcon: {
            marginRight: spacing.unit,
        },
        icon: {
            fontSize: 18,
        },
    });

const defaultIcon = <ContentCreate />;

class EditButton extends Component {
    constructor(props) {
        super(props);


    }

    handleEditClick = (e) => {
       //debugger;
        const { showNotification, record, storeSelection, dispatch } = this.props;

        e.stopPropagation();

        if (this.props.basePath == Epath.Points) {
            storeSelection("point", record)

        }

        else if (this.props.basePath == Epath.Models) {
            storeSelection("model", record)

        }

        else if (this.props.basePath == Epath.Contracts) {
            storeSelection("contract", record)

        }
        else if (this.props.basePath == Epath.InvoiceCheckItems) {
           //debugger;
            //
            storeSelection("invoiceCheckItems", record)
            //e.preventDefault();

        }
        else if (this.props.basePath == Epath.InvoiceChecks) {
           //debugger;
            if (record.hasInvoiceCheckItem) {
                e.preventDefault();
                dispatch(showNotification("Cannot edit element", 'info'));

            }
            else {
                storeSelection("invoiceCheck", record)
            }
        }
       
    }

    render() {
        if (this.props.basePath.includes(Epath.Admin)) {
            this.props.record.UpdateRight = 'Y'
            this.props.record.DeleteRight = 'Y'
        }
       //debugger;
        const { basePath, record, showNotification, storeSelection } = this.props;
        let { label } = this.props;
        // let itemName=record.Name;
        if (label === "EditFromAction") {

            // Using this EditButton component in InvoiceCheckShowActions 
            // In that action we have to define label as Edit            
            label = "Edit";
        }
        else {

            // In List component of Contracts/Models/etc.. we have to define column name 
            // as Edit, we do not want label in datagrid row
            label = "";
        }
        //debugger;
        var to = {};
        // Define route (pathname and state) based on basePath

        if (basePath == Epath.Points) {
            // itemName="points";
            to = {
                pathname: `${basePath}/${record.id}`,
                state: {
                    record: {
                        PointTypeId: record.PointTypeId,
                        FormulaEditorData: record.FormulaEditorData,
                        UpdateRight: record.UpdateRight,
                        DeleteRight: record.DeleteRight,
                    }
                },
            }
        }
        else if (basePath == Epath.InvoiceChecks) {
            // itemName="invoiceChecks";

            to = {
                pathname: `${basePath}/${record.id}`,
                state: {
                    record: {
                        contractId: record.ic_c_id,
                        UpdateRight: record.UpdateRight,
                        DeleteRight: record.DeleteRight,
                    }
                },
            };
        }
        else if (basePath == Epath.InvoiceCheckItems) {
            // itemName="invoiceCheckItems";  
           //debugger;
            to = {
                pathname: `${basePath}/${record.id}`,
                state: {
                    record: {
                        invoiceCheckId: record.ici_ic_id,
                        ic_m_id_1: record.ic_m_id_1,
                        ic_m_id_2: record.ic_m_id_2,
                        UpdateRight: record.UpdateRight,
                        DeleteRight: record.DeleteRight,
                    }
                },
            };

        }
        else {
            to = {
                pathname: `${basePath}/${record.id}`,
                state: {
                    record: {
                        UpdateRight: record.UpdateRight,
                        DeleteRight: record.DeleteRight,
                    }
                },
            }

        }

        //console.log(to);
        //  storeSelection(itemName,record);
        return (
            <Button
                component={Link}
                to={to}
                disabled={(record.UpdateRight === 'Y') ? false : true}
                onClick={this.handleEditClick}
                aria-label="Edit"
                component={Link}
                color="primary"
                label={label}
            >
                {defaultIcon}
            </Button>
        );
    }
}

// {/* <ConsoleLog>"Edit Button Prop: {this.props}"</ConsoleLog> */}
EditButton.defaultProps = {
    undoable: false,
    label: ""
};

EditButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
    label: PropTypes.string
};


const mapDispatch = (dispatch) => {
    return {
        dispatch,
        showNotification,
        storeSelection: (typeName, record) => record ? dispatch({ type: "ADD_SELECTION", payload: { type: typeName, value: record } }) : null,
    }
}

const enhance = compose(
    connect(null, mapDispatch),
    withStyles(styles)
);


export default enhance(EditButton);