import { FrequencyList } from './FrequencyList';
import { FrequencyShow } from './FrequencyShow';
import FrequencyCreate from './FrequencyCreate';
import FrequencyEdit from './FrequencyEdit';

export default {
    list: FrequencyList,
    create: FrequencyCreate,
    edit: FrequencyEdit,
    show: FrequencyShow
};