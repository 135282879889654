export default (previousState =  { staged_at: undefined, staged: false}, { type, payload, meta }) => {
    if (type === 'UPLOAD_TO_STAGE_SUCCESS') {
        return {
            staged_at: meta.staged_at,
            staged: true,
            entity: meta.entity
        };
    }

    if(type === 'UPLOAD_COMMIT_DONE')
    {//set to UploadState.staged=false
            console.log('UPLOAD_COMMIT_DONE');
            return {
                staged: false  
            };

    }

    return previousState;
}