import React, { Component } from 'react';
import { Create, TextInput,TextField, SimpleForm, required, SaveButton, Toolbar, translate} from 'react-admin';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { mapProps } from 'recompose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';



const styles = {
    price: { 
    color: 'purple',
    fontWeight: 'bold'  }
};
// withStyles(styles)(({ classes, ...props })
const LabelField =translate(({ onCancel, translate, ...props }) => (
   <span style={styles}>{props.text}</span>
));


const CommentToolbar = translate(({ onCancel,onSave, translate, ...props }) => { 
//console.log("commenttoolbar",props); 
//console.log("onCancel",onCancel); 
//console.log("onSave",typeof onSave === 'function');
 return (
       <Toolbar {...props}>
           <SaveButton 
                label={props.label === "Set Approval" ? "approve" : "revoke"}  
                variant="flat" 
                color="primary"
                onClick={()=>onSave({message: props.newRecord.Comment})}
                handleSubmitWithRedirect={()=> ()=>{}} 
            />
           <Button onClick={onCancel}>{translate('ra.action.cancel')}</Button>
       </Toolbar>
    ); });


const CommentEval = ({ onCancel,onSave, basePath,...props }) => (
    <Create title=" " basePath {...props}>
        <SimpleForm  toolbar={<CommentToolbar  onCancel={onCancel} onSave={onSave} {...props} />}>
            <LabelField text={props.label + " for " + props.record.ic_Name}   />
            <TextInput multiline source="Comment" />
        </SimpleForm>
    </Create>
);

const mapStateToProps = state => ({
    newRecord: getFormValues('record-form')(state)
});

export default connect(mapStateToProps)( CommentEval);