import React, { useState } from 'react';
import { Datagrid, Query, TextField, Pagination, Loading, List } from 'react-admin';
import { PostPagination } from '../Common/Pagination';
import CalculationListActions from './CalculationActions';
import { MuiThemeProvider } from '@material-ui/core';
import { tableMuiTheme } from '../Common/Styles';
import { AccessDenied } from '../Common/NoAccess';
import CheckPagePermission from "../Common/CheckPagePermissionJs";


const CalcualtionStatusList = ({ record, dataProvider, dispatch, permissions, ...rest }) => {

  //debugger;
  return (
    <CheckPagePermission
      authParams={{ Admin: false }}
      location={window.location}
      match="InvoiceCheckItems"
      render={({ pagePermissions }) =>
        <div>
          {pagePermissions && pagePermissions.View === "Y" ?
            <MuiThemeProvider theme={tableMuiTheme} >
              <List
                {...rest}
                exporter={false}
                title=" "
                pagination={<PostPagination />}
                resource="Calculation"
                sort={{ field: 'cs_StartDateTimeLocal', order: 'ASC' }}
                filter={{ modelId: record ? record.Model : 0, month: record ? record.month : 0 }}
                actions={<CalculationListActions />}
                bulkActionButtons={false}
              >
                <Datagrid>
                  <TextField source="id" />
                  <TextField label="Model" source="Model" />
                  <TextField label="Start" source="Start Datetime Local" />
                  <TextField label="End" source="End Datetime Local" />
                  <TextField label="Progress" source="Progress" />
                  <TextField label="Status" source="ReturnMSG" />
                  <TextField label="Duration" source="Duration" />
                </Datagrid>
              </List>
            </MuiThemeProvider>
            : <AccessDenied />
          }
        </div>}
    />
  );
}

export default CalcualtionStatusList;
