import lodashMemoize from 'lodash/memoize';



/* eslint-disable no-underscore-dangle */
/* @link http://stackoverflow.com/questions/46155/validate-email-address-in-javascript */
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape


export const isEmpty = (value) =>
typeof value === 'undefined' || value === null || value === '';



// If we define validation functions directly in JSX, it will
// result in a new function at every render, and then trigger infinite re-render.
// Hence, we memoize every built-in validator to prevent a "Maximum call stack" error.
const memorize= (fn) =>
    lodashMemoize(fn, (...args) => JSON.stringify(args));

  
 export const required = memorize((message = 'Required') =>
    value => value ? undefined : message);
  
 export    const number = (message = 'Must be a number') =>
    value => value && isNaN(Number(value)) ? message : undefined;
  
  export const DateValidation = (value, allValues) => {
  
    if (!value) {
        return 'The date is required';
    }
    if (value < allValues.Valid_from) {
  
        return 'Must be in future ';
    }
  };
  
  export const validateDate = [required(), DateValidation];