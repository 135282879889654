import React from "react";
import styled from "styled-components";
import Field from "./field";
import { Droppable } from "react-beautiful-dnd";

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: ${props => (props.type === "Operators" ? "500px" : "inherit")};  
  display: flex;
  flex-direction: column;
`;
// max-height: 380px;
const Title = styled.h3`
  padding: 8px;
  color: rgba(0, 0, 0, 0.54);
`;

const FieldList = styled.div`
  margin-left: ${props =>
    props.type === "Operators" || props.type === "Fake" ? "8px" : "inherit"};
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 8px;
  flex-glow: 1;
  background: ${props => (props.isDraggingOver ? "#b7dcfb" : "inherit")};
  weight: inherit; 
  overflow-y: scroll;
  min-height: 650px;
`;
//min-height: inherit;   
// max-height: 350px;
// min-height: 300px;
function RenderAddConstant() {
  return (
    <div>
      <input />
      <button>Add</button>
    </div>
  );
}

class InnerList extends React.PureComponent {
  //debugger;
  render() {
    return this.props.fields.map((field, index) => (
      <Field
        key={field.id}
        field={field}
        index={index}
        columnType={this.props.columnType}
      />
    ));
  }
}

export default class Column extends React.Component {
  render() {
    return (
      <Container type={this.props.column.id}>
        <Title>{this.props.column.title}</Title>
        <Droppable
          droppableId={this.props.column.id}
          isDropDisabled={this.props.isDropDisabled}
          droptype={this.props.column.id}
        >
          {(provided, snapshot) => (
            <FieldList
              ref={provided.innerRef}
              {...provided.droppableProps}
              type={this.props.column.id}
              isDraggingOver={snapshot.isDraggingOver}
            >
              <InnerList
                fields={this.props.fields}
                columnType={this.props.column.id}
              />
              {provided.placeholder}
            </FieldList>
          )}
        </Droppable>
      </Container>
    );
  }
}
