import { MediumList } from './MediumList';
import { MediumShow } from './MediumShow';
import MediumCreate from './MediumCreate';
import MediumEdit from './MediumEdit';

export default {
    list: MediumList,
    create: MediumCreate,
    edit: MediumEdit,
    show: MediumShow
};