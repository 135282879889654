import React, { Component } from 'react';
import { AUTH_LOGIN, AUTH_LOGOUT,AUTH_CHECK, AUTH_ERROR,GET_LIST, withDataProvider,} from 'react-admin';

import { AUTH_GET_PERMISSIONS } from 'ra-core';
import { connect} from 'react-redux';
import auth from './clientAuthServices';
import dataProviderFactory from '../dataProvider/dataProviderFactory';
import dataProvider from '../dataProvider/rest';
import ConsoleLog from '../Common/ConsoleLog';

//const dataProvider = dataProviderFactory('rest');

const endPoint = () => { 
	const regex = /\?|\//;
	const resource = window.location.hash.substr(2).split(regex) ;
	if (window.location.href.indexOf("Admin") != -1)
		return resource[1]
	else return resource[0] 

	/*const op = ["create", "edit", "show"];
	var ep = resource.pop()
	const ret= op.includes(ep) ?  resource.pop() :  ep;*/
	
    }

async function fetchPermission(endpoint) {
  												
		console.log('Permission pathname:',endpoint);
		const permissions = await dataProvider(GET_LIST,'permission',
			{
				query: { pa_Name: endpoint ? endpoint : 'Default'},	
			},
			{
				          onSuccess: {
				               notification: { body: 'Comment approved', level: 'info' },
				               redirectTo: '/dashboard',
				           },
				           onFailure: {
				               notification: { body: 'Error: comment not approved', level: 'warning' }
				           }
				       });
			
        console.log('call of authProvider to get Permissions: ' + JSON.stringify(permissions));
        return permissions;
	
};

//dataProvider('GET_LIST', 'posts', { filter: { status: 'pending' }})
   
export const AuthProvider = async (type, params)=>{


	if (type === AUTH_LOGIN) {
		
		console.log("start Login");
		return Promise.resolve(auth.signIn());

	}

	if (type === AUTH_LOGOUT) {
		console.log("start Logout!");

	   	auth.signOut();
	   	localStorage.clear();
		return Promise.resolve();
        
	}

	if (type === AUTH_CHECK) {
   
	
		const resource = window.location.hash.substring(2, window.location.hash.indexOf('/', 2));
		console.log('AuthCheck location:' + resource );
		console.log('AuthCheck pathname:' + window.location.hash );
		
		// auth.showWelcomeMessage();
		// const token = null; //auth.getToken();
   
		// if(params.isLoggedIn ==true && token != null )
		// {
		// 	console.log('params.logged  is false + reject');
		// 	return Promise.resolve();
        
		// }

		// if(params.isLoggedIn ==false && token != null )
		// {
		// 	console.log('params.logged  is false + reject');
		// 	auth.signOut();
		// 	return Promise.resolve();
		// 	//
		// 	//return Promise.reject();
		// }

		// return Promise.reject({ redirectTo: '/Dashboard' });  //If promise.reject then trigger Auth_logout
		// return Promise.reject({ redirectTo: '/login' });*/
		
		return Promise.resolve();
	}

	if (type === AUTH_GET_PERMISSIONS)
	{
			//debugger;
			console.log("Auth_GET_PERMISSION _ORG"  );	
		return Promise.resolve();
	}


	if (type === "GET_PAGE_PERMISSIONS") 
	{
		//debugger;
		//console.trace();
	 /* var endpoint = endPoint();
	   console.log("AUTH_GET_PERMISSION: "+ endpoint);
	   var userInfo = auth.getUserInfo();
	  //console.log("userinfo.name: "+ userInfo.UserName );
	   endpoint= (endpoint==="") ? "Default" : endpoint;
	   endpoint= (endpoint==="login") ? "Default" : endpoint;
	   var sitePermission={id: 0,Name:"Default", View:"Y", Insert: "N", Update:"N", Delete: "N",Execute:"N"};

		if(userInfo !=null)
	  		if(endpoint!=="login")
	  		{
	   			var storage = localStorage.getItem(endpoint);
	   
	   			sitePermission = (storage ===null) ? 
	   			await fetchPermission(endpoint)
	   			.then(response => {
					localStorage.setItem(response.Name, JSON.stringify(response));
					//storePermissions(response.Name,JSON.stringify(response))
					return response;
				}
			)
		 :  JSON.parse(storage);

		}
	

	   let authorizedPageUserInfo = {...sitePermission,...userInfo};
	   console.log(authorizedPageUserInfo);
		return  Promise.resolve(authorizedPageUserInfo);*/
		//console.log("GET_PERMISSION");
	  console.log("GET_PAGE_PERMISSIONS"  );	
	 
		//console.log(params.permissions.pageRegistry);
		//if(params===undefined)
		let pagePermission = params.pageRegistry[params.pageName];
		
		if(params && pagePermission.type==="BREAD_PAGE")
			pagePermission=pagePermission.value;

		else 
			pagePermission={id: 500,Name:"Default", View:"Y", Insert: "N", Update:"N", Delete: "N",Execute:"N"}
		//debugger;
		return  Promise.resolve(pagePermission);
		//return Promise.resolve();
	}
 
	if (type === AUTH_ERROR)
	{
		//debugger;
		 console.log("AuthError/Fetch ERROR"  );
        
		return Promise.resolve();
	}

    
   return Promise.reject('Unknown method');

	
}
    

const mapState = state => {
	return {
		isLoggedIn: state.admin.auth.isLoggedIn,
        
	}
}




export default AuthProvider; 
//export default connect(mapState, mapDispatch)(withDataProvider(AuthProvider));