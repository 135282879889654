import React from 'react';
import {Link } from 'react-admin';
import { cardActionStyle } from '../Common/Styles';
import ConsoleLog from '../Common/ConsoleLog';
 const NavigationPath = (props) =>             
        <div style={cardActionStyle.naviBlock}>
        <ConsoleLog>{props}</ConsoleLog>
        {props.contractName && <span> <Link to={'/Contracts'} >Contract:</Link> <span >{props.contractName} </span> </span> }
        {props.modelName && <span> <Link to={'/Models'} >Model:</Link> <span >{props.modelName} </span> </span>} 
        {props.pointName && <span> <Link to={'/Points'} >Point:</Link> <span >{props.pointName} </span> </span>} 
        {props.invoiceCheckName && <span> <Link to={'/InvoiceChecks'} >Invoice Check:</Link> <span >{props.invoiceCheckName} </span> </span>} 
        {props.invoiceCheckItemsName && <span> <Link to={'/InvoiceCheckItems'} >Check Items:</Link> <span >{props.invoiceCheckItemsName} </span> </span>} 
        {props.auditItem && <span> <Link to={'Approvals/create'} >Audit Item:</Link> <span >{props.auditItem} </span> </span>} 
        </div>
 
 

 export default NavigationPath;