import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { ShowActions } from '../../Common/Actions';

export const QualityShow = props => (
    <Show actions={<ShowActions />}  {...props} title="Quality Details" >
        <SimpleShowLayout>
            <TextField source="id" label="Id" />
            <TextField source="q_Name" label="Name" />
            <TextField source="q_Description" label="Description" />
            <TextField source="q_ColorRedByte" label="Quality ColorRedByte" />
            <TextField source="q_ColorBlueByte" label="Quality ColorBlueByte" />
            <TextField source="q_ColorGreenByte" label="Quality ColorGreenByte" />
        </SimpleShowLayout>
    </Show>
);