import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { ShowActions } from '../../Common/Actions';

export const CategoryShow = props => (
    <Show actions={<ShowActions />}  {...props} title="Category Details" >
        <SimpleShowLayout>
            <TextField source="id" label="Id" />
            <TextField source="ca_Name" label="Name" />            
        </SimpleShowLayout>
    </Show>
);