import React from 'react';
import {
    SimpleForm, SaveButton, Toolbar, ReferenceInput, SelectInput,
    CreateController, CreateView
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import { InvoiceCheckItemCreateActions } from './InvoiceCheckItemActions';
import { styles, toolbarStyles } from '../Common/Styles';
// import ConsoleLog from '../Common/ConsoleLog';
import { connect } from 'react-redux';

const required = (message = 'Required') =>
        value => value ? undefined : message;

const InvoiceCheckItemCreate = ({classes,naviPath,...props}) => {

    const CreateToolbar = withStyles(toolbarStyles)(props => (
        <Toolbar {...props}>
            <SaveButton label="Save" variant="flat" color="primary" />
        </Toolbar>
    ));

    return (
        <CreateController {...props}>
            {controllerProps =>
                <CreateView actions={<InvoiceCheckItemCreateActions
                    record={naviPath} />}
                    {...props} {...controllerProps} title="Create Invoice check item" >
                    <SimpleForm toolbar={<CreateToolbar />} redirect="show">
                        {/* <ConsoleLog>$"Controller props: {controllerProps}"</ConsoleLog>                         */}
                        <ReferenceInput label="Point1" source="ici_p_id_1" reference="/Points/All" resource="/Points/All"
                            filter={{
                                // 2021-11-30 Daniel Rzehak fix no points displayed bug
                                modelId: naviPath.m_id_1,
                            }}
                            resettable 
                            //validate={required}  
                            >
                            <SelectInput optionText="p_Name" />
                        </ReferenceInput>
                        <ReferenceInput label="Point2" source="ici_p_id_2" reference="/Points/All" resource="/Points/All"
                            filter={{
                                // 2021-11-30 Daniel Rzehak fix no points displayed bug
                                modelId: naviPath.m_id_2,
                            }}
                            resettable 
                            //validate={required}  
                            >
                            <SelectInput optionText="p_Name" />
                        </ReferenceInput>
                    </SimpleForm>
                </CreateView>
            }
        </CreateController>
    );
};

const mapStateToProps = state => {
    return {
        naviPath:{
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
            invoiceCheck: state.selectionState.navItem.invoiceCheck,
            invoiceCheckName: state.selectionState.navItem.invoiceCheck.value.ic_Name,
            invoiceCheckId: state.selectionState.navItem.invoiceCheck.value.id,
            // 2021-11-30 Daniel Rzehak fix no points displayed bug
            m_id_1: state.selectionState.navItem.invoiceCheck.value.ic_m_id_1,
            m_id_2: state.selectionState.navItem.invoiceCheck.value.ic_m_id_2,
       },
        isLoggedIn: state.admin.auth.isLoggedIn,
    }
}
const InvoiceCheckItemCreate2Redux=connect(
    mapStateToProps,
    null
) (InvoiceCheckItemCreate);


export default withStyles(styles)(InvoiceCheckItemCreate2Redux);