import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadAction } from './UploadActions';
import { refreshView as refreshViewAction } from 'react';

class UploadButtonRdx extends Component {

    handleClick = event => {
        event.preventDefault();
        const { uploadAction, formState } = this.props;
        //console.log("UplaodButtonRdx" +  JSON.stringify(formState));
        if(formState.files !==undefined)
        {
            uploadAction(formState);
          //  this.props.refreshView();
        }
}


  

    render() {
        {console.log("gesamt: " + (this.props.formState.disableButton) )}
        return <Button onClick={this.handleClick} disabled={this.props.formState.disableButton || this.props.loading==1} >Upload To Stage</Button>;
    }
}

UploadButtonRdx.propTypes = {
    uploadAction: PropTypes.func.isRequired,
    refreshView: PropTypes.func.isRequired,
    record: PropTypes.object,
};

function mapStateToProps(state) {
    return {
      loading: state.admin.loading
    }
  }

export default connect(mapStateToProps, { uploadAction, refreshView: refreshViewAction })(UploadButtonRdx);