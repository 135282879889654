import React from "react";
import {
  CardActions,
  CreateButton,
  RefreshButton,
  ListButton,
  Title,
  Link
} from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { stringify } from "query-string";
import ConsoleLog from "../Common/ConsoleLog";
import { cardActionStyle } from "../Common/Styles";
import { AccessDenied } from "../Common/NoAccess";
import EditButton  from "../Common/EditButton";
import { disableActionCreateButton } from "../Common/Actions";
import  NavigationPath from '../Common/NavigationPath'

export const ModelListActions = ({
  create,
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  record,
  permissions,
  ...rest
}) => (
    <CardActions style={cardActionStyle}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <NavigationPath  {...record}  />
    
      <div  style={cardActionStyle.actionBlock}>
      <CreateButton
        label="Create"
        basePath={basePath}
        to={{
          pathname: "/Models/create",
          state: { record: { contractId: record.contractId } },
        }}
        resource={create}
        variant="flat"
        color="primary"
        record={record}
        disabled={permissions.UpdateRight !== "Y"}
      />
      <RefreshButton variant="flat" color="primary" />
      </div>
      
    </CardActions>
  );

export default ModelListActions;

export const ModelShowActions = ({ basePath, data, naviPath, permissions }) => {

 const model =naviPath.model.value;
  return (
    <CardActions style={{ display: "flex" }}>
       <NavigationPath {...naviPath} />
       <div  style={{ marginLeft: "auto" }}>
      <EditButton label="EditFromAction" basePath={basePath} record={{...model, ...permissions }} />
      <ListButton
        label="Table"
        to={{
          pathname: `/Models`,
          state: {
            record: {
              contractId: data.m_c_id,
              UpdateRight: permissions.UpdateRight
            }
          },
        }}
        variant="flat"
        color="primary"
      />
      </div>
      {/* <ConsoleLog>"Data:" + {data}</ConsoleLog> */}
    </CardActions>
  )
};

export const ModelCreateActions = ({ basePath,naviPath }) => (
  <CardActions style={{ display: "flex" }}>
    <NavigationPath {...naviPath} />
     
    <ListButton
      label="Table"
      to={{
        pathname: `${basePath}`,
        state: { record: { contractId: naviPath.contractId } },
      }}
      variant="flat"
      color="primary"
      style={{ marginLeft: "auto" }}
    />
 
  </CardActions>
);

export const ModelEditActions = ({ basePath, naviPath }) => (
  <CardActions style={{ display: "flex" }}>
    <NavigationPath {...naviPath} />
    <ListButton
    style={{ marginLeft: "auto" }}
      label="Table"
      to={{
        pathname: `${basePath}`,
        state: { record: { contractId: naviPath.contractId } },
      }}
      //basePath={basePath}
      variant="flat"
      color="primary"
    />
  </CardActions>
);
