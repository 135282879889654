import React from 'react';
import { Create, SimpleForm, TextInput, SaveButton, Toolbar, resetForm, ReferenceInput, SelectInput } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { ModelCreateActions } from './ModelActions';
import { CreateActions } from '../Common/Actions';
import {required } from  '../Common/Validator';
import { change, reset } from 'redux-form';
import { connect } from 'react-redux';
import { styles, toolbarStyles } from '../Common/Styles';

const ModelCreate = ({classes,naviPath,...props}) => {

   /* const required = (message = 'Required') =>
        value => value ? undefined : message;*/

    const CreateToolbar = withStyles(toolbarStyles)(props => (
        <Toolbar {...props}>
            <SaveButton label="Save" variant="flat" color="primary" />
        </Toolbar>
    ));

    return (
        <Create actions={<ModelCreateActions naviPath={naviPath} />} {...props} >
            <SimpleForm toolbar={<CreateToolbar />} redirect="list">
                <TextInput label="Name" source="m_Name" validate={required()} className={classes.textFld} />
                <TextInput label="Description" source="m_Description" validate={required()} className={classes.textFld} />
                <ReferenceInput label="Time Zone" source="tzId" reference="TimeZone" resource="TimeZone"
                    resettable validate={required()}  >
                    <SelectInput optionText="tz_Name" />
                </ReferenceInput>
                <TextInput
                    label="Purchase Order"
                    source="m_PurchaseOrder"
                    className={classes.textFld}
                />
            </SimpleForm>
        </Create>
    );
};

const mapStateToProps = state => {
    return {
      naviPath: {
        contract: state.selectionState.navItem.contract,
        contractName: state.selectionState.navItem.contract.value.c_Name,
        
      },
      modelPermissions: {
        UpdateRight: state.selectionState.navItem.contract.value.UpdateRight,
        DeleteRight: state.selectionState.navItem.contract.value.DeleteRight
      },
      isLoggedIn: state.admin.auth.isLoggedIn,
    }
  }


const ModelCreate2Redux=connect(
    mapStateToProps,
    null
) (ModelCreate);

export default withStyles(styles)(ModelCreate2Redux);