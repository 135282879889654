
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles,
    createStyles,
    Theme,
} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import { Admin, userLogin } from 'react-admin';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

import backgrdImg from './Images/Plant2.jpg'

//import defaultTheme from '../defaultTheme';
//import Notification from '../layout/Notification';
//import DefaultLoginForm from './LoginForm';

/*interface Props {
    backgroundImage?: string;
    theme: object;
    staticContext?: object;
}*/

const styles = (theme) =>
    createStyles({
        root: {
            backgroundSize: 'cover',
            //height: '100%',
            overflow: 'hidden',
            backgroundRepeat  : 'no-repeat',
            backgroundPosition: 'center center',
            minHeight: '100%',
            backgroundAttachment: 'fixed',
    

        },
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        card: {
           
            minWidth: 300,
            marginTop: '6em',
        },
        avatar: {
           
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
           // backgroundColor: theme.palette.secondary[500],
            backgroundColor: "DodgerBlue",
        },
        text: {
            color: "gray",
           // padding: "10px",
            fontFamily: "Arial",
            fontSize: '1em',
            fontWeight: 'bold',
            textAlign: 'center',
            justifyContent: 'center',
          },
          button: {
            backgroundColor: "DodgerBlue",
            minWidth: 300,
            marginTop: '6em',
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
            color: "white",
        },


    });



class LoginPage extends Component {
    theme = createMuiTheme(this.props.theme);
    backgroundImageLoaded = false;
    
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            account: null,
            error: null,
            emailMessages: null,
            graphProfile: null,
            loading: false,
            msg:  "Please click Button to Log in"
        };
    }


    updateBackgroundImage = () => {
        if (!this.backgroundImageLoaded && this.containerRef.current) {
            const { backgroundImage } = this.props;
            this.containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            this.backgroundImageLoaded = true;
           
        }
    };

    // Load background image asynchronously to speed up time to interactive
    lazyLoadBackgroundImage = () => {
        const { backgroundImage } = this.props;
      
        if (backgroundImage) {
            const img = new Image();
            img.onload = this.updateBackgroundImage;
            img.src = backgroundImage;
            img.className =this.props.classes.root;
        }
    }

 

    async componentDidMount() {
         console.log("loginPage did Mount");
         this.lazyLoadBackgroundImage();
         this.setState({ loading: false });
        
    }

    


    handler = (e) => {
        e.preventDefault();
      
      //  const credentials = { username: 'test'  }
      //  this.props.userLogin(credentials)
     
      this.props.userLogin();
      this.setState({ msg: "Please click Button if you are not redirected"});
   
    }

    render() {
        const {userLogin,
            backgroundImage,
            classes,
            className,
            staticContext,
            isLoggedIn,
            isLoading,
            ...rest
        } = this.props;

        //this.setState({ loading: !isLoggedIn });
        return (
            <MuiThemeProvider theme={this.theme}  >
                 <div
                    className={classnames(classes.main, className)}
                    {...rest}
                     ref={this.containerRef}
                >
                      <Card className={classes.card}>
                        <div className={classes.avatar}>
                            <Avatar className={classes.avatar}>
                                <LockIcon />
                            </Avatar>
                         </div>
                         
                        <h1  className={classes.text}> {this.state["msg"]}</h1>
                        <div>{ 
                            isLoading && (
                                <CircularProgress
                                className={classes.icon}
                                size={18}
                                thickness={2}
                                />
                                 )
                             }
                    </div>
                       <Button onClick={e=>this.handler(e)} className={classes.button} disabled={isLoading}>
                        LogIn
                    </Button>
                      
                    </Card>
                
                </div>
            </MuiThemeProvider>
        );
    }
};

LoginPage.propTypes = {
    backgroundImage: PropTypes.string,
   // theme: PropTypes.object,
   // staticContext: PropTypes.object,
};

LoginPage = withStyles(styles)(LoginPage)
LoginPage.defaultProps = {
   // backgroundImage: 'https://source.unsplash.com/random/1600x900/daily',
    backgroundImage: backgrdImg,
    //theme: defaultTheme
};

function mapStateToProps(state) {
    return {
        isLoggedIn: state.admin.auth.isLoggedIn,
        isLoading:  (state.admin.loading == 1) 
    };
}


export default connect(mapStateToProps, { userLogin })(LoginPage);