import React, { useState, useEffect } from 'react';
import { Datagrid, DatagridBody, List, TextField,Loading} from 'react-admin';
import CheckPagePermission from "../../Common/CheckPagePermissionJs";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditButton  from '../../Common/EditButton';
import DeleteCrud from '../../Common/DeleteCrud';
import StyledBooleanField from '../../Common/StyledBooleanField';

import  {ContractListActions} from '../../Contract/ContractAction';
import { PostPagination } from '../../Common/Pagination';
import {AccessDenied}  from "../../Common/NoAccess";

import { MuiThemeProvider } from "@material-ui/core/styles";
import {isEqual} from 'lodash';
import { tableMuiTheme} from "../../Common/Styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';




const unselectAll = (selected, selectedIds) => {  if(selectedIds.length ==1 ){ return selected;} if(selectedIds.length > 0) {selectedIds.shift(); return false;} else return false; }

const UsersDatagridRow = ({ record, resource, id, onToggleItem, selectedIds, children, selected, basePath }) => (
 
    <TableRow key={id}  >
        {React.Children.map(children, field => (
            <TableCell key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                })}
            </TableCell>
        ))}
    </TableRow>
);


const UsersDatagridBody = props => <DatagridBody   {...props} row={<UsersDatagridRow {...props} />} />;
const UsersDatagrid = props =>{  return <Datagrid  {...props}  hasBulkActions = {false} body={<UsersDatagridBody />}  rowClick="show"  />};



 const UsersList = ({loading, classes, permissions,dispatch,isLoggedIn,isLoading,selectedIds, ...props}) => 
    <CheckPagePermission
    authParams={{ Admin: false }}
    location={window.location}
    match="Users"
    render={({ pagePermissions }) =>
    <div>
      {
      pagePermissions ?
      (pagePermissions.View ==='Y') ?   
      <MuiThemeProvider theme={tableMuiTheme}>
          <List  
            actions={<ContractListActions  permissions {...props}/>} pagination={<PostPagination />} title="Users"  {...props}  >
        <UsersDatagrid>
            <TextField source="id" label="Id" />
            <TextField source="Domain" label="Domain" />
            <TextField source="GivenName" label="Given Name" />
            <TextField source="Surname" label="Family Name" />
            <TextField source="Email" label="Email" />
            <StyledBooleanField source="Active" label="Active" />
           <EditButton  label="Edit" resource="Admin/Users/:id" basePath={props.basePath} record ={props.record} />
           <DeleteCrud  label="Delete" resource="Admin/Users/:id" basePath={props.basePath} record ={props.record} />
        </UsersDatagrid>
    </List> 
    </MuiThemeProvider> :<AccessDenied/> 
    :  <Loading loadingPrimary="Please wait a request for permission is running" loadingSecondary="Content won't load until page is fully loaded" /> 
      }</div>
    }/>;


UsersList.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,  
};

const mapState = state => {
    return {
        isLoggedIn: state.admin.auth.isLoggedIn,
        isLoading: state.admin.loading
        
    }
};

const areEqual = (prevProps, nextProps) => {  
   return isEqual(prevProps, nextProps) 
  }

export default React.memo(connect(mapState, null) (UsersList),areEqual);

