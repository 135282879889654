import React, { Component,useEffect } from 'react';
import { 
    EditController, 
    EditView, 
    SimpleForm,
    TextInput, 
    LinearProgress,
    CheckboxGroupInput,
    ReferenceInput,
    SelectInput, 
    Toolbar,
    Checkbox,
    ReferenceArrayInput, 
    SelectArrayInput ,
ArrayField,
SingleFieldList,
ReferenceManyField,
ChipField} from 'react-admin';

import { EditActions } from '../../Common/Actions';
import { styles, toolbarStyles } from '../../Common/Styles';
import { withStyles } from '@material-ui/core';
import UpdateCrudButton from '../../Common/UpdateCrud';
import DeleteWithConfirmButton from '../../Common/DeleteCrud';
import SelectInputByName from '../../Common/SelectInputByName';
import ConsoleLog from '../../Common/ConsoleLog';
import { Loading } from 'ra-ui-materialui/lib/layout';





const useStyles = {
    field:{
        background: '#fff',
        padding: 0,
        borderStyle: 'none',
        borderWidth:  1,
       // borderBottomColor: '#603d7b',
        boxShadow: "1px 1px 1px #9E9E9E",
        width: '80%',
       
    },

    ul: { 
        display: 'table',
        listStyleType: "none",
        padding: 6,
},
    li: { 
        listStylePosition:'inside',
        color: 'rgba(0 0 0 0.87)',
        background: '#E0E0E0',
        /*paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '7px',
        paddingBottom: '7px',*/
        padding: "0px 12px",
        //display: 'inline-flex',
        display:'table-cell',
       // textDecoration: 'none',
        listStyleType: "none",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        textAlign: 'center',
        
    },  

     label: { 
         display: 'block',
         color: 'rgba(0 0 0 0.87)',
         cursor: 'pointer',
         //background: 'yellow',
         padding: "6px 32px 7px 0px",
         margin: "16px 0px 8px",
         fontSize: 16,
         font: "system-ui,BlinkMacSystemFont",
         backgroundRepeat: 'no-repeat',
         textDecoration: 'none'
     },

     input: {
         display: 'none',
         

     },


};





const EditTitle = ({ record }) => {
    return <span>Edit Page Access for {record.Name ? `${record.Name}`: record.PageAccess[0].Name}</span>;
};


class PageAccessEdit extends Component {
    constructor(props) {
        super(props);
     
      }

    required = (message = 'Required') =>
        value => value ? undefined : message;

   
    initCheck= ({PageAccess,...record}) =>{
        
        var paArray = PageAccess.filter(v=>v && v.Authorised ==='Y').map(x=>{if(x.Authorised ==='Y') return x.id } )
        return paArray;

      }

    componentDidMount() {
       
    }
 
  render(){

    return(
        <EditController {...this.props}>
        { 
            (controller) => 
                <EditView actions={<EditActions /> }
                    title={<EditTitle />} 
                    {...controller}  >
                  <SimpleForm toolbar={<UpdateToolbar basePath="/Admin/PageAccess"  />}   > 
                        { controller.record.PageAccess ? <>
                        <div>
                            <TextInput source="PageName" label="Page Name" defaultValue={controller.record.PageAccess[0].Name}  validate={()=>this.required()}/>  
                        </div>
                        <div>
                            <SelectInput label ="Role" source ="Roles_id" choices={controller.record.Roles} optionText="Name" optionValue="id" defaultValue={controller.record.PageAccess[0].Role_id} validate={this.required()}/>
                        </div> 
                        <CheckboxGroupInput    
                        source="Permissions" 
                        choices={controller.record.PageAccess}  
                        optionText="Permission" optionValue="id" 
                        defaultValue={this.initCheck(controller.record)}
                        />
                        </>: 
                        <LinearProgress />}       
                    </SimpleForm>  
            </EditView>
                
            }
                 
    </EditController>
    );
}
}

const UpdateToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
      
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath="/Admin/PageAccess"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            disabled={props.record.PageAccess==null}
        />
        <DeleteWithConfirmButton
            label="Delete"
            redirect="list"
            basePath="/Admin/PageAccess/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="/Admin/PageAccess"
            undoable={false}
            disabled={props.record.PageAccess==null}
        />
    </Toolbar>
));

export default React.memo(withStyles(styles)(PageAccessEdit));
