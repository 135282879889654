import React from 'react';
import { CardActions, ExportButton ,downloadCSV} from 'react-admin';
import { cardActionStyle } from '../Common/Styles';
import ConsoleLog from '../Common/ConsoleLog';
//import XLSX from 'xlsx';
import * as XLSX from 'xlsx';

function s2ab(s) { 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
}


const downloadXLSX=(wbout, filename) => {
    console.log(wbout);
    const fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    const blob = new Blob([s2ab(wbout)],{type:"application/octet-stream"});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        window.navigator.msSaveOrOpenBlob(blob, `${filename}.xlsx`);
    } else {
        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', `${filename}.xlsx`);
        fakeLink.click();
    }
};

function xlsExporter(data,selectionState) {
    var wb = XLSX.utils.book_new();
    console.log("exporterselectionState",selectionState);

    wb.Props = {
        Title: "Result",
        //CreatedDate: Date.now()
    };

/*End Datetime UTC: "2020-12-31T23:15:00"
EndDateTimeLocal: "2021-01-01T00:15:00"
Start Datetime UTC: "2020-12-31T23:00:00"
StartDateTimeLocal: "2021-01-01T00:00:00"
d_CreationTimeStamp: "2021-06-14T11:18:30.217"
d_CreationUser: "Daniel Rzehak"
d_Value: 392.4
d_p_id: 998
d_q_id: 3
id: 4741038
p_Name: "Test DE095601:E_Power_CO2Comp2"
q_Name: "FINALIZED"
*/

/* Id, Start , End, Value, Quality, Creation User, Creation Time Stamp*/

//let newArray = studentArr.map((item) => ({ name: item.name, age: item.age }));
  /*  let dataForExport = data.map(item => ({ 
        Id: item["id"],
        Start: item["Start Datetime UTC"],
        End: item["End Datetime UTC"],
        Value: item["d_Value"],
        Quality: item["q_Name"],
        "Creation User":item["d_CreationUser"],
        "Creation Time Stamp":item["d_CreationTimeStamp"]
    }));*/

    let dataForExport = data.map(item => ([
        item["id"],
        item["Start Datetime UTC"],
        item["End Datetime UTC"],
        item["d_Value"],
        item["q_Name"],
        item["d_CreationUser"],
        item["d_CreationTimeStamp"]
    ]));

    console.log("dataforExport",dataForExport)


    var sheetName="Result "+selectionState.Month;
    wb.SheetNames.push(sheetName);

    var ws_header = [['Contract:', selectionState.Contract.c_Name, 'Model:', selectionState.Model.m_Name,'Point:', selectionState.Point.p_Name,],
    ['Id:','Start:','End:','Value:','Quality:','Creation User','Creation Time Stamp']]; 
    var ws_data = ws_header.concat(dataForExport);
    console.log("ws_data",ws_data);

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    

    wb.Sheets[sheetName] = ws;

    var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

    downloadXLSX(wbout, "Result")
    console.log("data", data);

};

export const ResultListActions = ({
    create,
    selectionState,
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
     ...rest }) => {
     
        return(
        <CardActions style={cardActionStyle}  >
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues: filterValues,
                context: 'button',
            })}
            {/* <ConsoleLog>{record}</ConsoleLog> */}
            <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            exporter={(data)=>xlsExporter(data,selectionState)}           
            filter={{ contractId: selectionState.Contract.id, modelId: selectionState.Model.id, pointId: selectionState.Point.id, month: selectionState.Month, isExport: true }}
            variant="flat" color="primary"                  
        />
        </CardActions>
    )};

export default ResultListActions;