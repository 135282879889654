import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import ActionDelete from '@material-ui/icons/Delete';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import inflection from 'inflection';
import { translate, crudDeleteMany } from 'ra-core';

import { Confirm } from 'ra-ui-materialui';
import { Button } from '@material-ui/core';

import dataProvider from '../dataProvider/rest';
import { GET_LIST, DELETE, refreshView } from 'ra-core';
import { showNotification } from 'react-admin';

const sanitizeRestProps = ({
    basePath,
    classes,
    crudDeleteMany,
    filterValues,
    label,
    resource,
    selectedIds,
    dispatch,
    ...rest
}) => rest;

const styles = theme =>
    createStyles({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    });

class PointBulkDelete extends Component {
    static propTypes = {
        basePath: PropTypes.string,
        classes: PropTypes.object,
        crudDeleteMany: PropTypes.func.isRequired,
        label: PropTypes.string,
        resource: PropTypes.string.isRequired,
        selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
        icon: PropTypes.element,
        showNotification: PropTypes.func,
        refreshView: PropTypes.func.isRequired,
    };

    static defaultProps = {
        label: 'ra.action.delete',
        icon: <ActionDelete />,
    };

    state = { isOpen: false };

    handleClick = e => {
        this.setState({ isOpen: true });
        e.stopPropagation();
    };

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleDelete = () => {
        //debugger;
        const {
            basePath,
            crudDeleteMany,
            resource,
            selectedIds,
            onClick,
            showNotification,
            refreshView,
        } = this.props;

        //console.log(selectedIds);
        this.setState({ isOpen: false });
        //crudDeleteMany(resource, selectedIds, basePath);

        var updatedSelectedIds = [];

        dataProvider(GET_LIST, "Points/IsDeletePoint",
            {
                pagination: { page: 1, perPage: 5 },
                sort: { field: 'p_id', order: 'ASC' },
                filter: { ids: selectedIds }
            })
            .then((response) => {
                //console.log(response);
                //debugger;

                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].IsDelete) {
                        var id = response.data[i].id;

                        updatedSelectedIds.push(id);
                    }
                    else {
                        showNotification(response.data[i].ReturnMSG, "info", { autoHideDuration: 5000 });
                    }
                }

            crudDeleteMany(resource, updatedSelectedIds, basePath);            
               
            })
            .catch((e) => {
                showNotification("Error", "warning")
            })
            .finally(() => {
                
            });

        if (typeof onClick === 'function') {
            onClick();
        }
    };

    render() {
        const {
            classes,
            label,
            icon,
            onClick,
            resource,
            selectedIds,
            translate,
            crudDeleteMany,
            refreshView,            
            ...rest
        } = this.props;
        return (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    label= {label}
                    aria-label={label}
                    className={classes.deleteButton}
                    {...sanitizeRestProps(rest)}
                >
                    {icon} {label}
                </Button>
                <Confirm
                    isOpen={this.state.isOpen}
                    title="ra.message.bulk_delete_title"
                    content="ra.message.bulk_delete_content"
                    translateOptions={{
                        smart_count: selectedIds.length,
                        name: inflection.humanize(
                            translate(`resources.${resource}.name`, {
                                smart_count: selectedIds.length,
                                _: inflection.inflect(
                                    resource,
                                    selectedIds.length
                                ),
                            }),
                            true
                        ),
                    }}
                    onConfirm={this.handleDelete}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
}

const EnhancedPointBulkDelete = compose(
    connect(
        undefined,       
        {
            crudDeleteMany,
            showNotification: showNotification,
            refreshView,            
        }
    ),
    translate,
    withStyles(styles)
)(PointBulkDelete);

export default EnhancedPointBulkDelete;
