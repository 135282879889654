import React, { Component } from 'react';
import {FileInput, FileField,FormDataConsumer} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Field, reduxForm } from 'redux-form';
import dataProvider from '../dataProvider/rest';
import {convert} from './fileReader';
import FileUploadStatusList from './errorTableMUI'; 
import CheckPagePermission from "../Common/CheckPagePermissionJs";
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';



const ConsumerForm =({fileSelector,updateState,...props}) => (
        
    <FormDataConsumer >
                    {({formData}) => {
                    
                    return (
                        <div>
                          
                    <FileInput
                                                            source="files"
                                                            label="Upload file (.csv, .xls, .xlsx)"
                                                            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"// text/comma-separated-values"
                                                          
                                                            defaultValue=""
                                                            options={{
                                                                onChange:(e)=>fileSelector(e)
                                                            }}
                                                        //	key={this.state.inputKey}
                                                        >
                                                            <FileField
                                                                source="src"
                                                                title="title"
    
                                                            //key={this.state.inputKey}
                                                            />
    
                                            </FileInput>
                                            {updateState(formData)}
                                         
                                            </div>
                                            ) }}
                                    </FormDataConsumer>
    
    );

const UploadForm = reduxForm({
        form: 'formname'
      })(ConsumerForm)


class fileUpload extends Component {
    state = {
        selectedFile: null,
        disableButton: true,
        showUploadStatus: false,
        data: {}
      
    }

   

    updateState = (formData) => {
       
        console.log("formfiles",  formData);
        var buttonstate=true;
        if(formData != undefined)
            buttonstate= formData.files == undefined ? true : false

       this.setState({ disableButton: buttonstate })
       
    }
 
    fileSelectHandler = event => {
       
        //console.log(event.target.files[0]);
        //console.log("Old " ,event.target.files[0]);
        convert(event.target.files[0])
        .then( res =>  {
            console.log("new: ", res); 
            this.setState({ 
                selectedFile: res,
                 disableButton: false})

                })
        .catch(error=>{
            const {showNotification } = this.props;
            console.error(error)
            this.setState({ 
                selectedFile: "",
                 disableButton: true})

                 var notify=`${error.msgType}: ${error.msg}`;
                 var msgType="warning";

            showNotification(notify,msgType, {
                    undoable: false,
                    autoHideDuration: 10000
                });
        })
         
    }

  
    fileUploadHandler= () => {

    var formdata = new FormData();
    //console.log(this.state.selectedFile.type);
    formdata.append("myfiles", this.state.selectedFile,this.state.selectedFile.name);
    this.setState({ disableButton: true})
    dataProvider('BLOB_UPLOAD', 'BlobUpload/SimpleCSV', {data: formdata})
    .then(ret => {
        const {showNotification,record } = this.props;
       console.log("fileUpload-Record:", record);
        console.log('ret',ret);
        var notify="Something went wrong";
        var msgType="warning";
        var data={};
        var isError=false;
        if( ret && ret["isError"] != undefined){
            notify=ret.errorMsg;
            isError=ret.isError==="true";
            msgType= isError ? "warning" : "info";   
            if(ret.details !==null)         
                var ErrorList = ret.details.map((key,i) => ({['id']:i ,['Error']: key.ReturnMSG}));
            else
                var ErrorList ={}

        
            data=ErrorList;
            
        }
       
        this.setState({ disableButton: false,showUploadStatus: isError, data })
       
        showNotification(notify,msgType, {
            messageArgs: {},
            undoable: false,
            autoHideDuration: 5000
        });
    });

    }  

    render() {  return(
		<CheckPagePermission
			authParams={{ Admin: false }}
			location={window.location}
			match="MeterData"
			render={({ pagePermissions }) => <div>
				{pagePermissions && pagePermissions.View === "Y"  ?
				<Card>
                <UploadForm fileSelector={this.fileSelectHandler} updateState={this.updateState}  ></UploadForm> 
                <Button onClick={this.fileUploadHandler} disabled = {this.state.disableButton}>Upload</Button>               
                </Card>: null}
                {this.state.showUploadStatus ?   
                 <FileUploadStatusList {...this.props}  record={this.state.data}/> 
                 : null  }

            </div>}
            />)}

}
export default connect(null, {showNotification})(fileUpload);
