import { CREATE } from 'react-admin';


export const UPLOAD_TO_STAGE = 'UPLOAD_TO_STAGE';

export const uploadAction = ( data) => ({
    type: UPLOAD_TO_STAGE,
    payload: { data: { ...data, is_approved: true } },
    meta: {
        resource: 'MeterData',
        fetch: CREATE,
       onSuccess: {
           notification: {
               body: 'notification.upload_to_stage_successful',
               level: 'info',
           },
           redirectTo: '/MeterData',
           staged_at: new Date(),
           entity: {files: data.files,
                    Contract: data.Contract,
                    Model: data.Model,
                    Point: data.Model
                },
           callback: () => { 
               console.log("Upload Succeeded");
           // setStatus("setStatus: Erfolgreich"); 
           
        },
          // basePath,
           
       },
       onFailure: {
           notification: {
               body: 'notification.upload_to_stage_failed',               
               level: 'warning',
           },
           mymeta: false,
           callback: () => { 
               console.log("Upload Failed"); 
          // setStatus("setStatus: Fehler"); 
        },
       },
    },
});

