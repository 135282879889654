import { fetchUtils, GET_LIST } from 'react-admin';
import { stringify } from 'query-string';
import jsonServerProvider from 'ra-data-json-server';


import auth from "../AuthProvider/clientAuthServices";

const httpClient = async (url, options = {}) => {

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json', 'Content-Type': 'application/json' });

    }

    const accessTokenRequest = {
        // authority: "https://lecustomeraccess.onmicrosoft.com/PrestoPortal/",
        // authority: " https://lecustomeraccess.b2clogin.com/lecustomeraccess.onmicrosoft.com/oauth2/v2.0/authorize",
        //scopes: ["user.read"]
        // scopes:["b8b3440c-9f23-432a-99d1-872cfa333287"]
        scopes: ["https://lecustomeraccess.onmicrosoft.com/PrestoPortal/user_impersonation"],
        sid: auth.getUserInfo().sid
    }

    const token = await auth.msalApp
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {

            console.log("Try 1: aquired Token ")
            return response.accessToken;
        }).catch(function (error) {
            //Acquire token silent failure, and send an interactive request
            if (auth.requiresInteraction(error.errorCode)) {
                return auth.msalApp.acquireTokenPopup(accessTokenRequest).then(function (accessTokenResponse) {
                    // Acquire token interactive success
                    console.log("Try 2: aquired Token")
                    let accessToken = accessTokenResponse.accessToken;
                    return accessToken;
                }).catch(function (error) {
                    // Acquire token interactive failure
                    console.log("Failed to aquire Token");
                    console.log(error.errorMessage);
                });
            }
            console.log("Unhandled Error!")
            console.log(error);
        });

    // const token= await auth.getToken();
    //const token = localStorage.getItem("msal.idtoken")
    console.log("for api call get Token Version: " + auth.Version);

    options.headers.set('Authorization', `Bearer ${token}`);
    //options.headers.set('Access-Control-Allow-Credentials', 'true');
    // options.headers.set('Access-Control-Allow-Origin', `${process.env.REACT_APP_WEBSITE_SITE_NAME}`);
    return await fetchUtils.fetchJson(url, options);
};


let restProvider = 0;
let url = process.env.REACT_APP_API_URL

console.log("Client in " + process.env.REACT_APP_NODE_ENV + " mode, url: " + url);
//restProvider = jsonServerProvider(url, httpClient);


export default (type, resource, params) => {

    if (type === GET_LIST && resource === 'permission')
        type = 'GET_PERMISSIONS';

    if (resource === 'CopyContract' || resource === 'CopyModel')
        resource = 'CopyContractModel';

    return new Promise(resolve => {
        switch (type) {
            case 'GET_PERMISSIONS': {

                let apiurl = `${url}/${resource}?${stringify(params.query)}`;
                //debugger;
                let options = {};
                options.method = 'GET';
                options.body = JSON.stringify(params.data);
                setTimeout(() => resolve(
                    httpClient(apiurl, options)
                        .then((response) => response.json[0])
                        .catch(error => {
                            //debugger;
                            if (error.status === 403)
                                throw {
                                    name: 'Error',
                                    message: 'You don\'t have Permission'
                                }
                            if (error.status === 500)
                                throw {
                                    name: 'Error',
                                    message: 'Server Error, please check your connection'
                                }

                        })
                )
                    , 1200);

            } break;


            case 'UPLOAD': {
                console.warn('query:', stringify(params.query));
                console.warn('queryby undefined:', (typeof params.queryby === 'undefined'));
                let apiurl = (typeof params.queryby === 'undefined') ?
                    `${url}/${resource}?${stringify(params.query)}`
                    :
                    `${url}/${resource}/${params.queryby}?${stringify(params.query)}`;

                let options = {};
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                setTimeout(() => resolve(
                    httpClient(apiurl, options)
                        .then(response => {
                            console.log("upload succeeded");
                            return ({
                                data: { ...params.data, id: response.json.id }
                            })
                        })
                        .catch(error => {
                            if (error.status === 403)
                                throw {
                                    name: 'Error',
                                    message: 'You don\'t have Permission'
                                }

                        })
                ), 1200);

            } break;

            case 'BLOB_UPLOAD': {
                console.warn('query:', stringify(params.query));
                console.warn('queryby undefined:', (typeof params.queryby === 'undefined'));
                let apiurl = (typeof params.queryby === 'undefined') ?
                    `${url}/${resource}?${stringify(params.query)}`
                    :
                    `${url}/${resource}/${params.queryby}?${stringify(params.query)}`;

                let options = {};
                options.method = 'POST';
                options.headers=new Headers()
                options.body = params.data;
                setTimeout(() => resolve(
                    httpClient(apiurl, options)
                        .then(response => {
                            console.log("upload succeeded");
                            return (response.json)
                        })
                        .catch(error => {
                            if (error.status === 403)
                                throw {
                                    name: 'Error',
                                    message: 'You don\'t have Permission'
                                }

                        })
                ), 1200);

            } break;


            default: {
                //debugger;
                //console.warn('defaults', url);
                restProvider = jsonServerProvider(url, httpClient);
                setTimeout(() => resolve(
                    restProvider(type, resource, params)
                        .catch(error => {
                            if (error.status === 403)
                                throw {
                                    name: 'Error',
                                    message: 'You don\'t have Permission'
                                }
                        })
                ), 1200);
                break;
            }
        }
        console.log("REST-Type:" + type);
        console.log("REST-Resource:" + resource);
        console.log("REST-PARAMS:" + JSON.stringify(params));

    }
    )
};

export const mapStateToProps = (state) => {
    return { things: state.uploadDone }
};

const mapDispatchToProps = (dispatch) => {

    return {
        uploadDone: () => { dispatch({ type: 'UPLOAD_DONE' }) },
        uploadFailed: () => { dispatch({ type: 'UPLOAD_FAILED' }) },
        dispatch: dispatch

    };
}



