
const Permissions = {
    READ: 1,
    EDIT: 2,
    CREATE: 3,
    EXECUTE: 4,
    properties: {
      1: {name: "read", value: 1},
      2: {name: "edit", value: 2},
      3: {name: "create", value: 3},
      4: {name: "execute", value: 4}
    }
  };


  export const PermissionEnum = Object.freeze(Permissions);