import PointList from './PointList';
import PointShow from './PointShow';
import PointCreate from './PointCreate';
import PointEdit from './PointEdit';

export default {
    list: PointList,
    create: PointCreate,
    edit: PointEdit,
    show: PointShow
};