import React from 'react';
import {
    Edit, SimpleForm, TextInput, Toolbar
} from 'react-admin';

import UpdateCrudButton from '../../Common/UpdateCrud';
import DeleteWithConfirmButton from '../../Common/DeleteCrud';
import { withStyles } from '@material-ui/core';
import { styles, toolbarStyles } from '../../Common/Styles';
import { EditActions } from '../../Common/Actions';

const QualityEdit = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    const maxValue = (max, message = `Max value is ${max}`) =>
        value => value && value > max ? message : undefined;

    const number = (message = 'Must be a number') =>
        value => value && isNaN(Number(value)) ? message : undefined;

    const validation = [required(), number(), maxValue(255)];

    return (
        <Edit actions={<EditActions />} {...props} >
            <SimpleForm toolbar={<UpdateToolbar basePath="/Qualities" />}>
                <TextInput label="Quality Name" source="q_Name" validate={required()} className={classes.textFld} />
                <TextInput label="Quality Description" source="q_Description"
                    validate={required()} className={classes.textFld} />
                <TextInput label="Quality ColorRedByte" source="q_ColorRedByte"
                    validate={validation} className={classes.textFld} />
                <TextInput label="Quality ColorBlueByte" source="q_ColorBlueByte"
                    validate={validation} className={classes.textFld} />
                <TextInput label="Quality ColorGreenByte" source="q_ColorGreenByte"
                    validate={validation} className={classes.textFld} />
            </SimpleForm>
        </Edit>
    );
};

export default withStyles(styles)(QualityEdit);

const UpdateToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath="/Qualities"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            disabled={false}
        />
        <DeleteWithConfirmButton
            label="Delete"
            redirect="list"
            basePath="/Qualities/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="Qualities"
            undoable={false}
            disabled={false}
        />
    </Toolbar>
));
