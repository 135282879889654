import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import inflection from 'inflection';
import { translate, crudDelete, GET_LIST, refreshView } from 'ra-core';

//import Confirm from '../layout/Confirm';
import { Confirm } from 'ra-ui-materialui';
//import Button from './Button';
import { Button } from '@material-ui/core';
import dataProvider from '../dataProvider/rest';
import { showNotification } from 'react-admin';

const sanitizeRestProps = ({
    basePath,
    classes,
    crudDelete,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    submitOnEnter,
    redirect,
    ...rest
}) => rest;

const styles = theme => ({
    deleteButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
});

const defaultIcon = <ActionDelete />;

class DeleteWithConfirmButton extends Component {
    state = { isOpen: false };

    handleClick = e => {
        this.setState({ isOpen: true });
        e.stopPropagation();
    };

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleDelete = () => {
        //event.stopPropagation();
        const { crudDelete, resource, onClick, record, basePath, redirect, showNotification, refreshView } = this.props;

        //debugger;

        var selectedIds = [record.id];

        var deleteBasePath = {
            pathname: `${basePath}`,
            state: { record: { modelId: record.modelId } }
        }

        dataProvider(GET_LIST, "Points/IsDeletePoint",
            {
                pagination: { page: 1, perPage: 5 },
                sort: { field: 'p_id', order: 'ASC' },
                filter: { ids: selectedIds }
            })
            .then((response) => {
                //console.log(response);
                //debugger;

                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].IsDelete) {

                        crudDelete(resource, record.id, record, deleteBasePath, redirect);
                    }
                    else {
                        showNotification(response.data[i].ReturnMSG, "info", { autoHideDuration: 5000 });
                        refreshView();
                        this.setState({ isOpen: false });
                    }
                }
            })
            .catch((e) => {
                showNotification("Error", "warning")
            })
            .finally(() => {

            });

        if (typeof onClick === 'function') {
            onClick();
        }
    };

    render() {
        const {
            translate,
            showNotification,
            classes = {},
            className,
            icon,
            //label = 'ra.action.delete',            
            onClick,
            record,
            resource,
            refreshView,
            ...rest
        } = this.props;

        let { label } = this.props;
        //debugger;
        if (label === "DeleteFromAction") {
            label = "Delete";
        }
        else {
            label = "";
        }

        return (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    label={label}
                    className={classnames(
                        'ra-delete-button',
                        classes.deleteButton,
                        className
                    )}
                    key="button"
                    {...sanitizeRestProps(rest)}
                >
                    {defaultIcon}  {label}
                </Button>
                <Confirm
                    isOpen={this.state.isOpen}
                    title="ra.message.delete_title"
                    content="ra.message.delete_content"
                    translateOptions={{
                        name: inflection.humanize(
                            inflection.singularize(resource),
                            true
                        ),
                        id: record.id,
                    }}
                    onConfirm={this.handleDelete}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
}

DeleteWithConfirmButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    crudDelete: PropTypes.func.isRequired,
    label: PropTypes.string,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string.isRequired,
    translate: PropTypes.func,
    icon: PropTypes.element,
    showNotification: PropTypes.func,
    refreshView: PropTypes.func.isRequired,
};

DeleteWithConfirmButton.defaultProps = {
    label: 'ra.action.delete',
    redirect: 'list',
    icon: <ActionDelete />,
};

const PointDeleteWithConfirmButton = compose(
    connect(
        null,
        {
            crudDelete,
            showNotification: showNotification,
            refreshView,
        }
    ),
    translate,
    withStyles(styles)
)(DeleteWithConfirmButton);

export default PointDeleteWithConfirmButton;