import React, { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    DateTimeInput, 
    AutocompleteInput,
    SaveButton,
    Toolbar,
    resetForm,
    ReferenceArrayInput,
    SelectArrayInput
} from 'react-admin';
import { CreateActions } from '../Common/Actions';
import { withStyles } from '@material-ui/core';
import { styles, toolbarStyles } from '../Common/Styles';
import ConsoleLog from '../Common/ConsoleLog';
import { PermissionEnum } from "../Common/Constants";


//const ContractCreate =  withStyles(styles)(({ classes, ...props }) => {
const ContractCreate = props => {

    const { classes } = props;


    const required = (message = 'Required') =>
        value => value ? undefined : message;

    const number = (message = 'Must be a number') =>
        value => value && isNaN(Number(value)) ? message : undefined;

    const DateValidation = (value, allValues) => {

        if (!value) {
            return 'The date is required';
        }
        if (value < allValues.Valid_from) {

            return 'Must be in future ';
        }
    };

    const validateDate = [required(), DateValidation];

    const CreateToolbar = withStyles(toolbarStyles)(props => (
        <Toolbar {...props}>
            <SaveButton label="Save" variant="flat" color="primary" />
        </Toolbar>
    ));

    return (
        <Create actions={<CreateActions />} {...props} >
            <SimpleForm toolbar={<CreateToolbar />} redirect="show">
                <TextInput source="Contract_Name" validate={required()} className={classes.textFld} />

                <ReferenceArrayInput label="Site" source="Site_id" reference="Sites" resource="Sites"
                    sort={{ field: 's_Name', order: 'ASC' }}
                    filter={{ is_all: true }}
                    //
                    validate={required()} 
                    allowEmpty>
                    <SelectArrayInput optionText={choice => `${choice.s_Name} (ERP: ${choice.s_SiteNumberERP})`}
                    resettable={+true}  />
                </ReferenceArrayInput>
                <ReferenceInput label="Supplier" source="Supplier_id" reference="Suppliers" resource="Suppliers"
                    sort={{ field: 'sp_Name', order: 'ASC' }}
                    filter={{ is_all: true }}
                    resettable={true} 
                    validate={required()}  >
                    <SelectInput optionText="sp_Name" />
                </ReferenceInput>

                <ReferenceInput label="Medium" source="Medium_id" reference="Mediums" resource="Mediums"
                    sort={{ field: 'me_Name', order: 'ASC' }}
                    filter={{ is_all: true }}
                    resettable={true} validate={required()}  >
                    <SelectInput optionText="me_Name" />
                </ReferenceInput>
                <DateTimeInput source="Valid_from" validate={validateDate} />
                <DateTimeInput source="Valid_to" validate={validateDate} />

            </SimpleForm>
        </Create>
    );
};

export default withStyles(styles)(ContractCreate);
