import React from 'react';
import { connect } from 'react-redux';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { ContractShowActions } from './ContractAction';
import { styles, toolbarStyles } from "../Common/Styles";
import { withStyles } from "@material-ui/core";

const ContractShow = ({ naviPath, permissions, isLoggedIn, dispatch, contractPermissions, ...props }) => {

    //debugger;

    var permission = props.location ? props.location.state ?
        props.location.state.record : permissions ? {
            UpdateRight: permissions.Update,
            DeleteRight: permissions.Delete
        } : {
            UpdateRight: "N",
            DeleteRight: "N"
        } : {
        UpdateRight: "N",
        DeleteRight: "N"
    };

    return (
        <Show actions={<ContractShowActions naviPath={naviPath} permissions={permission} />}  {...props} title="Contract Details" >
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="c_Name" label="Contract Name" />
                <TextField source="sp_Name" label="Supplier" />
                <TextField source="me_Name" label="Medium" />
                {/* <TextField source="Contract.c_Evergreen" label="Evergreen" /> */}
                <TextField source="c_ValidFrom" label="Valid From" />
                <TextField source="c_ValidTo" label="Valid To" />
            </SimpleShowLayout>
        </Show>
    );
}


const mapStateToProps = state => {
    return {
        naviPath: {
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.Name,
        },
        contractPermissions: {
            UpdateRight: state.selectionState.navItem.contract.value.UpdateRight,
            DeleteRight: state.selectionState.navItem.contract.value.DeleteRight
        },
        isLoggedIn: state.admin.auth.isLoggedIn,
    }
}
const ShowContract2Redux = connect(
    mapStateToProps,
    null
)
    (ContractShow);

export default withStyles(styles)(ShowContract2Redux);