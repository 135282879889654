import React, { Component,useEffect } from 'react';
import { 
    Create, 
    SimpleForm,
    TextInput,
    Toolbar,
    SaveButton
} from 'react-admin';

import { CreateActions } from '../../Common/Actions';
import { styles, toolbarStyles } from '../../Common/Styles';
import { withStyles } from '@material-ui/core';
import UpdateCrudButton from '../../Common/UpdateCrud';
import CheckboxGroupPA from './Permissions';




const CreateTitle = ({ record }) => {
    return <span>Add New Page Access</span>;
};

const useStyles = {
    field:{
        background: '#fff',
        padding: 0,
        borderStyle: 'none',
        borderWidth:  1,
       // borderBottomColor: '#603d7b',
        boxShadow: "1px 1px 1px #9E9E9E",
        width: '80%',
       
    },

    ul: { 
        display: 'table',
        listStyleType: "none",
        padding: 6,
},
    li: { 
        listStylePosition:'inside',
        color: 'rgba(0 0 0 0.87)',
        background: '#E0E0E0',
        /*paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '7px',
        paddingBottom: '7px',*/
        padding: "0px 12px",
        //display: 'inline-flex',
        display:'table-cell',
       // textDecoration: 'none',
        listStyleType: "none",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        textAlign: 'center',
        
    },  

     label: { 
         display: 'block',
         color: 'rgba(0 0 0 0.87)',
         cursor: 'pointer',
         //background: 'yellow',
         padding: "6px 32px 7px 0px",
         margin: "16px 0px 8px",
         fontSize: 16,
         font: "system-ui,BlinkMacSystemFont",
         backgroundRepeat: 'no-repeat',
         textDecoration: 'none'
     },

     input: {
         display: 'none',
         

     },


};





class PageAccessCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true};
       
      }


   required = (message = 'Required') =>
      value => value ? undefined : message;

 
    handleChange = (e) => { this.setState({loading: false})}

  render(){

    const { classes } = this.props;
    return(    
            <Create actions={<CreateActions /> } title={<CreateTitle />}  {...this.props}>
                <SimpleForm toolbar={<CreateToolbar myloading={this.state.loading} />} redirect="list" > 
                    <TextInput source="PageName" label="Page Name" validate={this.required()} />  
                    <CheckboxGroupPA {...this.props}  onChange={this.handleChange}/>
                </SimpleForm>  
            </Create>
                 
    );
}
}

const CreateToolbar = withStyles(toolbarStyles)(({myloading,...props}) => (
    <Toolbar {...props}>
        <SaveButton label="Save" variant="flat" color="primary" disabled={myloading ? true : false} />
    </Toolbar>
));




export default withStyles(styles)(PageAccessCreate);
