import React, { Component,useEffect } from 'react';
import { 
    EditController, 
    EditView, 
    SimpleForm,
    TextInput, 
    LinearProgress,
    CheckboxGroupInput,
    ReferenceInput,
    SelectInput, 
    Toolbar,
    Checkbox,
    ReferenceArrayInput, 
    SelectArrayInput ,
ArrayField,
SingleFieldList,
ReferenceManyField,
ChipField} from 'react-admin';

import { EditActions } from '../../Common/Actions';
import { styles, toolbarStyles } from '../../Common/Styles';
import { withStyles } from '@material-ui/core';
import UpdateCrudButton from '../../Common/UpdateCrud';
import DeleteWithConfirmButton from '../../Common/DeleteCrud';
import SelectInputByName from '../../Common/SelectInputByName';
import StyledBooleanInputField from '../../Common/StyledBooleanInputField';
import ConsoleLog from '../../Common/ConsoleLog';
import { Loading } from 'ra-ui-materialui/lib/layout';





const useStyles = {
    field:{
        background: '#fff',
        padding: 0,
        borderStyle: 'none',
        borderWidth:  1,
       // borderBottomColor: '#603d7b',
        boxShadow: "1px 1px 1px #9E9E9E",
        width: '80%',
       
    },

    ul: { 
        display: 'table',
        listStyleType: "none",
        padding: 6,
},
    li: { 
        listStylePosition:'inside',
        color: 'rgba(0 0 0 0.87)',
        background: '#E0E0E0',
        /*paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '7px',
        paddingBottom: '7px',*/
        padding: "0px 12px",
        //display: 'inline-flex',
        display:'table-cell',
       // textDecoration: 'none',
        listStyleType: "none",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        textAlign: 'center',
        
    },  

     label: { 
         display: 'block',
         color: 'rgba(0 0 0 0.87)',
         cursor: 'pointer',
         //background: 'yellow',
         padding: "6px 32px 7px 0px",
         margin: "16px 0px 8px",
         fontSize: 16,
         font: "system-ui,BlinkMacSystemFont",
         backgroundRepeat: 'no-repeat',
         textDecoration: 'none'
     },

     input: {
         display: 'none',
         

     },


};





const EditTitle = ({ record }) => {
    return <span>Edit User {record.GivenName ? `${record.GivenName} ${record.Surname}`: null}</span>;
};


class UsersEdit extends Component {
    constructor(props) {
        super(props);
     
      }

    required = (message = 'Required') =>
        value => value ? undefined : message;

    componentDidMount() {
       
    }
 
  render(){

    return(
        <EditController {...this.props}>
        { 
            (controller) => 
                <EditView actions={<EditActions /> }
                    title={<EditTitle />} 
                    {...controller}  >
                  <SimpleForm toolbar={<UpdateToolbar basePath="/Admin/Users"  />}   > 
                        { controller.record ? <>
                        <div>
                            <TextInput source="GivenName" label="Given Name" defaultValue={controller.record.GivenName} validate={this.required()}/>
                        </div>
                            <TextInput source="Surname" label="Family Name" defaultValue={controller.record.SurName} validate={this.required()}/>
                        <div>
                            <TextInput source="Email" label="Email" defaultValue={controller.record.Email} validate={this.required()}/>
                        </div>
                        <div>
                            <TextInput source="Domain" label="Domain"  defaultValue={controller.record.Domain}  validate={this.required()} />
                        
                            <StyledBooleanInputField source="Active" label="Active" record={controller.record} defaultValue={controller.record.Active}/>
                        
                        </div>
                        </>: 
                        <LinearProgress />}       
                    </SimpleForm>  
            </EditView>
                
            }
                 
    </EditController>
    );
}
}

const UpdateToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
      
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath="/Admin/Users"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            //disabled={props.record.Users==null}
            disabled={false}
        />
        <DeleteWithConfirmButton
            label="Delete"
            redirect="list"
            basePath="/Admin/Users/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="/Admin/Users"
            undoable={false}
           // disabled={props.record.Users==null}
           disabled={false}
        />
    </Toolbar>
));

export default React.memo(withStyles(styles)(UsersEdit));
