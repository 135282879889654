import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import InvoiceCheckShowActions from './InvoiceCheckActions';
import ConsoleLog from '../Common/ConsoleLog';
import { connect } from 'react-redux';

const InvoiceCheckShow =  ({naviPath,...props}) => (
    <Show actions={<InvoiceCheckShowActions  record={naviPath} 
    permissions={props.location ? props.location.state ?
        props.location.state.record : {
            UpdateRight: "N",
            DeleteRight: "N"
        } : {
            UpdateRight: "N",
            DeleteRight: "N"
        }} />}  
        {...props} 
        title="Invoice Check Details" >
    
        <SimpleShowLayout>
            {/* <ConsoleLog> "Invoice Check show props {props}" </ConsoleLog> */}
            <TextField source="id" />
            <TextField source="ic_Name" label="Name" />
            <TextField source="c_Name" label="Contract" />
            <TextField source="Model1" label="Model1" />
            <TextField source="Model2" label="Model2" />
            <TextField source="fq_Name" label="Frequency" />
            <TextField source="ro_Name" label="Role" />
            <TextField source="ic_ValidFrom" label="Valid From" />
            <TextField source="ic_ValidTo" label="Valid To" />
        </SimpleShowLayout>
    </Show>
);

const mapStateToProps = state => {
    return {
        naviPath:{
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
            invoiceCheck: state.selectionState.navItem.invoiceCheck,
            invoiceCheckName: state.selectionState.navItem.invoiceCheck.value.ic_Name,
            invoiceCheckId: state.selectionState.navItem.invoiceCheck.value.id,  
       },
        isLoggedIn: state.admin.auth.isLoggedIn,
    }
}
const InvoiceCheckShow2Redux=connect(
    mapStateToProps,
    null
) (InvoiceCheckShow);

export default InvoiceCheckShow2Redux;