import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateTimeInput,
    SaveButton,
    Toolbar,
    withDataProvider,
} from 'react-admin';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import { CopyActions } from '../../Common/Actions';
import { styles, toolbarStyles } from '../../Common/Styles';
import ConsoleLog from '../../Common/ConsoleLog';
import {required,validateDate } from  '../../Common/Validator';

const EditTitle = ({ record }) => {
    return <span>Copy Contract #  {record.c_id}</span>;
};

const CopyContractCreate = props => {

    const {naviPath, classes,location} = props;
    
    /*const required = (message = 'Required') =>
        value => value ? undefined : message;

    const number = (message = 'Must be a number') =>
        value => value && isNaN(Number(value)) ? message : undefined;

    const DateValidation = (value, allValues) => {

        if (!value) {
            return 'The date is required';
        }
        if (value < allValues.Valid_from) {

            return 'Must be in future ';
        }
    };

    const validateDate = [required(), DateValidation];*/

    const CreateToolbar = withStyles(toolbarStyles)(({naviPath,...props}) => (
        <Toolbar {...props}>
            <SaveButton label="Copy" variant="flat" color="primary" />
        </Toolbar>
    ));

    const redirect = (basePath, id, data) => `/Contracts`;
    
    return (
        <Create actions={<CopyActions naviPath= {naviPath} basePath="/Contracts" />} {...props} title={<EditTitle record={location.state.record} />}>
            <SimpleForm toolbar={<CreateToolbar />} redirect={redirect} >
                <TextInput label="New Contract" source="c_Name" validate={required()} className={classes.textFld} />

                <DateTimeInput label="New Valid From" source="Valid_From" validate={validateDate} />

                <DateTimeInput label="New Valid To" source="Valid_To" validate={validateDate} />

            </SimpleForm>
        </Create>
    );
};

const mapStateToProps = state => {
    return {
        naviPath:{
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
       },
       contractPermissions: {
        UpdateRight: state.selectionState.navItem.contract.value.UpdateRight,
        DeleteRight: state.selectionState.navItem.contract.value.DeleteRight
       },
        isLoggedIn: state.admin.auth.isLoggedIn,
    }
}
const CopyContract2Redux=connect(
    mapStateToProps,
    null
)
(CopyContractCreate);

const enhance = compose(
    withDataProvider,
    withStyles(styles)
);

export default enhance( CopyContract2Redux);
