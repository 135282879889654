import React, { useState, useEffect } from 'react';
import { Datagrid, DatagridBody, List, TextField,Loading} from 'react-admin';
import CheckPagePermission from "../../Common/CheckPagePermissionJs";

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditButton  from '../../Common/EditButton';
import DeleteCrud from '../../Common/DeleteCrud';
import StyledBooleanField from '../../Common/StyledBooleanField';

import  {ContractListActions} from '../../Contract/ContractAction';
import { PostPagination } from '../../Common/Pagination';
import { RefreshListActions } from '../../Common/Actions';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { tableMuiTheme} from "../../Common/Styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {AccessDenied}  from "../../Common/NoAccess";
import {isEqual} from 'lodash';




const ROLES= {
            ADMIN:              "Admin",
            INVOICE_CONTROLLER: "InvoiceController",
            CENTRAL_FUNCTION:   "CentralFunction",
            CONTRACT_MANAGER:   "ContractManager",
        };

const CheckPermissions = (permissions,role) => {

    if(  permissions!==null || permissions === undefined )
    console.log("Permision: " +  permissions.includes(role));
    
    return( 
        (  permissions!==null || permissions === undefined )
        ? 
        permissions.includes(role)
        : null
    );


}




const unselectAll = (selected, selectedIds) => {  if(selectedIds.length ==1 ){ return selected;} if(selectedIds.length > 0) {selectedIds.shift(); return false;} else return false; }

const DomainAccessDatagridRow = ({ record, resource, id, onToggleItem, selectedIds, children, selected, basePath }) => (
 
    <TableRow key={id}  >
        {/* first column: selection checkbox 
        <TableCell padding="none" >
            {record.DeleteRight !== 'Y' && <Checkbox
               // disabled={isDisabled}
             
                checked={unselectAll(selected, selectedIds)}
                onClick={() => onToggleItem(id)}
            />}
        </TableCell>*/}
        {/* data columns based on children */}
        {React.Children.map(children, field => (
            <TableCell key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                })}
            </TableCell>
        ))}
    </TableRow>
);


const DomainAccessDatagridBody = props => <DatagridBody   {...props} row={<DomainAccessDatagridRow {...props} />} />;
const DomainAccessDatagrid = props =>{ return <Datagrid  {...props}  hasBulkActions = {false} body={<DomainAccessDatagridBody />}  rowClick="show"  />};



 const DomainAccessList = ({loading, classes, permissions,dispatch,isLoggedIn,isLoading,selectedIds, ...props}) => 
   <CheckPagePermission
    authParams={{ Admin: true }}
    location={window.location}
    match="DomainAccess"
    render={({ pagePermissions }) =>
    <div>

      {
      pagePermissions ?
      (pagePermissions.View ==='Y') ?   
      <MuiThemeProvider theme={tableMuiTheme}>
          <List  
            actions={<RefreshListActions />} pagination={<PostPagination />} title="Domain Access"  {...props}  >
        <DomainAccessDatagrid  >
            <TextField source="id" label="Id" />
            <TextField source="User" label="User" />
            <TextField source="Role" label="Role" />
            <TextField source="Site" label="Site" />
            <TextField source="Region" label="Region" />
            <TextField source="Country" label="Country" />
            
           <EditButton  label="Edit" resource="/DomainAccess/:id" basePath={props.basePath} record ={props.record} />
           <DeleteCrud  label="Delete" resource="/DomainAccess/:id" basePath={props.basePath} record ={props.record} />
        </DomainAccessDatagrid>
    </List> 
    </MuiThemeProvider> :<AccessDenied/> 
    :  <Loading loadingPrimary="Please wait a request for permission is running" loadingSecondary="Content won't load until page is fully loaded" /> 
      }
    </div>
    }/>



DomainAccessList.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,  
};

const mapState = state => {
    return {
        isLoggedIn: state.admin.auth.isLoggedIn,
        isLoading: state.admin.loading
        
    }
};

const areEqual = (prevProps, nextProps) => {  
   return isEqual(prevProps, nextProps) 
  }

export default React.memo(connect(mapState, null) (DomainAccessList),areEqual);

