import React from 'react';
import {
    Edit, SimpleForm, TextInput, Toolbar
} from 'react-admin';

import UpdateCrudButton from '../../Common/UpdateCrud';
import DeleteWithConfirmButton from '../../Common/DeleteCrud';
import { withStyles } from '@material-ui/core';
import { styles, toolbarStyles} from '../../Common/Styles';
import { EditActions } from '../../Common/Actions';


const MediumEdit = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;
   

    return (
        <Edit actions={<EditActions />} {...props}>
            <SimpleForm 
             submitOnEnter={false}
             toolbar={<UpdateToolbar basePath="/Mediums" />}
             >
               <TextInput label="Medium Name" source="me_Name" validate={required()} className={classes.textFld} />     
   
            </SimpleForm>
        </Edit>
    );
};

export default withStyles(styles)(MediumEdit);

const UpdateToolbar =withStyles(toolbarStyles)((props) =>  (
    <Toolbar {...props}>    
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath="/Mediums"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            disabled={false}
        />
        <DeleteWithConfirmButton
            label="Delete"
            redirect="list"
            basePath="/Mediums/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="Mediums"
            undoable={false}
            disabled={false}
        />
    </Toolbar>
));
