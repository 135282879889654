import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Layout, Sidebar } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Menu from './Menu';
import AppBar from './AppBar';


const styles = {

    sidebar: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        backgroundColor: '#a7a7b5',
        position: 'relative',
        minHeight: '100vh',
    }
   
};


const CustomSidebar = withStyles(styles)(({ classes, ...props }) => ( <Sidebar size={200}  className={classes.sidebar} {...props} />));
const CustomLayout = props => (
    <Layout appBar={AppBar} sidebar={CustomSidebar} {...props} menu={Menu} />
);

const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
};

const mapDispatchToProps = dispatch => ({
 //   increment: () => dispatch({ type: "INCREMENT" }),
 //   decrement: () => dispatch({ type: "DECREMENT" })
  });

  const mapStateToProps = state => ({
    //counter: state.counter
    //theme: darkTheme 
  });



const lightTheme = {
    palette: {
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
    },
};

/*export default connect(
    state => ({
        theme: state.theme === 'dark' ? darkTheme : lightTheme,
    }),
    {}
)(CustomLayout);*/

/*export default connect(
    state => ({
        theme: state.theme === 'dark' ? darkTheme : lightTheme,
    }),{}
)(CustomLayout);*/

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomLayout);