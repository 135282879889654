import  ContractList  from './ContractList';
import  ContractShow  from './ContractShow';
import ContractCreate from './ContractCreate';
import ContractEdit from './ContractEdit';

export default {
    list: ContractList,
    create: ContractCreate,
    edit: ContractEdit,
    show: ContractShow
};