import React from 'react';
import { CardActions, CreateButton, RefreshButton, ListButton, EditButton} from 'react-admin';
import { cardActionStyle } from '../Common/Styles';
import { List } from 'ra-ui-materialui/lib/list';
import { endPoint } from '../AuthProvider/authProvider';
import  NavigationPath from '../Common/NavigationPath'
import ConsoleLog from './ConsoleLog';

const ListActions = ({   
    basePath,    
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    permissions,
    ...rest }) => (
        <CardActions style={cardActionStyle.actionBlock}  >
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}            
            
            <CreateButton basePath={basePath} variant="flat" color="primary" disabled={permissions.Insert==="N"} /> 
           
            <RefreshButton variant="flat" color="primary" />
       

        </CardActions>
    );
    export default ListActions;
     
    export const RefreshListActions = ({   
        basePath,    
        displayedFilters,
        filters,
        filterValues,
        resource,
        showFilter,
        ...rest }) => (
            <CardActions style={cardActionStyle.actionBlock}  >
                {filters && React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}            
                <RefreshButton variant="flat" color="primary" />
    
            </CardActions>
        );

export const ShowActions = ({  basePath,data, naviPath, ...rest }) => (
    <CardActions style={{ display: "flex" }} >
        <NavigationPath {...naviPath} />
        <div  style={{ marginLeft: "auto" }}>
            <EditButton label="Edit" basePath={basePath} record={data} variant="flat" color="primary" />
            <ListButton label="Table" basePath={basePath} variant="flat" color="primary" />
        </div>
    </CardActions>
);

export const CreateActions = ({ basePath }) => (
    <CardActions >
        <ListButton 
        label="Table" 
        basePath={basePath} 
        variant="flat" 
        color="primary" 
        />
    </CardActions>
);

export const CopyActions = ({  basePath, naviPath, ...rest }) => (
    <CardActions  style={{ display: "flex" }}>
        <NavigationPath {...naviPath} />
        <ListButton 
        style={{ marginLeft: "auto" }}
        label="Table" 
        basePath={basePath} 
        variant="flat" 
        color="primary" 
        />
    </CardActions>
);

export const EditActions = ({  basePath, naviPath, ...rest }) => (
    <CardActions  style={{ display: "flex" }}>
        <NavigationPath {...naviPath} />
        <ListButton 
        style={{ marginLeft: "auto" }}
        label="Table" 
        basePath={basePath} 
        variant="flat" 
        color="primary" 
        />
    </CardActions >
);
