import React, { Component,useEffect } from 'react';
import { 
    EditController, 
    EditView, 
    SimpleForm,
    TextField,
    TextInput, 
    LinearProgress,
    CheckboxGroupInput,
    ReferenceInput,
    SelectInput, 
    Toolbar,
    Checkbox,
    ReferenceArrayInput, 
    SelectArrayInput ,
    RadioButtonGroupInput,
    FormDataConsumer,
ArrayField,
SingleFieldList,
ReferenceManyField,
ChipField} from 'react-admin';

import { EditActions } from '../../Common/Actions';
import { styles, toolbarStyles } from '../../Common/Styles';
import { withStyles } from '@material-ui/core';
import UpdateCrudButton from '../../Common/UpdateCrud';
import DeleteWithConfirmButton from '../../Common/DeleteCrud';
import SelectInputByName from '../../Common/SelectInputByName';
import ConsoleLog from '../../Common/ConsoleLog';
import { Loading } from 'ra-ui-materialui/lib/layout';





const useStyles = {
    field:{
        background: '#fff',
        padding: 0,
        borderStyle: 'none',
        borderWidth:  1,
       // borderBottomColor: '#603d7b',
        boxShadow: "1px 1px 1px #9E9E9E",
        width: '80%',
       
    },

    ul: { 
        display: 'table',
        listStyleType: "none",
        padding: 6,
},
    li: { 
        listStylePosition:'inside',
        color: 'rgba(0 0 0 0.87)',
        background: '#E0E0E0',
        /*paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '7px',
        paddingBottom: '7px',*/
        padding: "0px 12px",
        //display: 'inline-flex',
        display:'table-cell',
       // textDecoration: 'none',
        listStyleType: "none",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        textAlign: 'center',
        
    },  

     label: { 
         display: 'block',
         color: 'rgba(0 0 0 0.87)',
         cursor: 'pointer',
         //background: 'yellow',
         padding: "6px 32px 7px 0px",
         margin: "16px 0px 8px",
         fontSize: 16,
         font: "system-ui,BlinkMacSystemFont",
         backgroundRepeat: 'no-repeat',
         textDecoration: 'none'
     },

     input: {
         display: 'none',
         

     },


};





const EditTitle = ({ record }) => {
    return <span>Edit Page Access for {record.User ? `${record.User}`: null}</span>;
    
};


class DomainAccessEdit extends Component {
    constructor(props) {
        super(props);
         this.Permission=props.location.state.record;
     
      }

    required = (message = 'Required') =>
        value => value ? undefined : message;

   
    

    componentDidMount() {
        console.log("Permission:", JSON.stringify(this.Permission));
    }
 
  render(){

    return(
        <EditController {...this.props}>
        { 
            controller => 
                <EditView actions={<EditActions /> }
                    title={<EditTitle />} 
                    {...controller}  >
                  <SimpleForm toolbar={<UpdateToolbar basePath="/Admin/DomainAccess" Permission={this.Permission} />}   > 
                        { controller.record? 
                        <div>
                            <TextField source="User" label="Name" record={controller.record} />

                            <ReferenceInput
                                label="Role"
                                source ="Role_id"
                                reference="Roles"
                                sort={{ field: "id", order: "id" }}
                                filter={{ is_all: true }}
                            >
                            <SelectInput   optionText="ro_Name" optionValue="id"  validate={this.required()}/>
                            </ReferenceInput>
                            <RadioButtonGroupInput id="location" source="location" options={{'row':true}}
                                    choices={[{ id: 'site', name: 'Site' },
                                            { id: 'country', name: 'County' },
                                            { id: 'region', name: 'Region' },
                                        ]} defaultValue={"site"}/>
                                      <div>
                        <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            //console.log("FormData:",formData);
                            if (formData && formData.location === "site")
                           return <ReferenceInput
                                    label="Site"
                                    source ="Site_id"
                                    resource="Sites"
                                    reference="Sites"
                                    sort={{ field: "s_Name", order: "ASC" }}
                                    filter={{  is_all: true }}
                                    >
                                    <SelectInput   optionText="s_Name" optionValue="id" />
                                </ReferenceInput>
                            if (formData &&formData.location === "country")
                              return  <TextInput source="porta" label="Country" />
                            if (formData &&formData.location === "region")
                              return  <TextInput source="porta" label="Region" />
                            
                            
                         }}
                        </FormDataConsumer>
                    </div>


                            <TextField source="Site" label="Site" />
                            <TextField source="Region" label="Region" />
                            <TextField source="Country" label="Country" />
                           
                        </div> 
                        : 
                        <LinearProgress />}       
                    </SimpleForm>  
            </EditView>
                
            }
                 
    </EditController>
    );
}
}

const UpdateToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
      
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath="/Admin/DomainAccess"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            disabled={props.Permission.UpdateRight==="N"}
        />
        <DeleteWithConfirmButton
            label="Delete"
            redirect="list"
            basePath="/Admin/DomainAccess/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="/Admin/DomainAccess"
            undoable={false}
            disabled={props.Permission.DeleteRight==="N"}
        />
    </Toolbar>
));

export default React.memo(withStyles(styles)(DomainAccessEdit));
