import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    Edit,
    EditController, EditView, SimpleForm,
    AutocompleteInput, TextInput, ReferenceInput,
    SelectInput, Toolbar, withDataProvider
} from 'react-admin';
import { PointEditActions } from './PointActions';
import { styles, toolbarStyles } from '../Common/Styles';
import { withStyles } from '@material-ui/core';
import UpdateCrudButton from '../Common/UpdateCrud';
import ConsoleLog from '../Common/ConsoleLog';
import ForumlaEditor from '../FormulaEditor/FormulaEditor';
import compose from 'recompose/compose';
import PointDeleteWithConfirmButton from './PointDeleteWithConfirmButton';
import { GET_ONE } from 'ra-core';


const EditTitle = ({ record }) => {
    return <span>Edit Point #  {record ? `${record.id}` : ''}</span>;
};

class PointEdit extends React.Component {

    constructor(props) {
        //debugger;
        super(props);

        this.state = {
            record: {
                PointTypeId: this.props.location.state ? this.props.location.state.record.PointTypeId : 0,
                FormulaEditorData: this.props.location.state ? this.props.location.state.record.FormulaEditorData
                    ? this.props.location.state.record.FormulaEditorData : undefined : undefined,
                id: this.props.location.state ? this.props.location.state.record.data ? this.props.location.state.record.data.id : undefined : undefined,
            }
        }

    }

    async componentWillMount() {
        //debugger;

        if (this.state.record.FormulaEditorData == undefined && this.state.record.PointTypeId == 6) {
            const response = await this.fetchData();
            //debugger;
        }

    }

    fetchData() {
        const { dispatch, dataProvider } = this.props;

        dataProvider(GET_ONE, "Points", { id: this.props.location.state.record.data.id })
            .then((response) => {
                //debugger;
                this.setState({
                    record: {
                        FormulaEditorData: response.data.FormulaEditorData
                    },
                });
            })
            .catch((e) => {
                //showNotification("Error", "warning")
            })
            .finally(() => {

            });

    }

    render() {
        const { dataProvider, dispatch, ...rest } = this.props;

        var PointTypeId = rest.location.state ? rest.location.state.record.PointTypeId : 0
        //console.log("show state:",rest.location.state);
        //console.log('permissions',this.props.pointPermissions);


        if (PointTypeId == 1) {
            return (
                <PointDetailsRaw pointPermissions={this.props.pointPermissions}  {...rest} />
            );
        }
        else if (PointTypeId == 2) {
            return (
                <PointDetailsPrice pointPermissions={this.props.pointPermissions}  {...rest} />
            );
        }
        else if (PointTypeId == 3) {
            return (
                <PointDetailsHistorian pointPermissions={this.props.pointPermissions}  {...rest} />
            );
        }
        else if (PointTypeId == 4) {
            return (
                <PointDetailsConstant pointPermissions={this.props.pointPermissions}  {...rest} />
            );
        }
        else if (PointTypeId == 5) {
            return (
                <PointDetailsAggregation pointPermissions={this.props.pointPermissions}  {...rest} />
            );
        }
        else if (PointTypeId == 6) {
            return (
                <React.Fragment>
                    {this.state.record.FormulaEditorData && <PointDetailsFormula pointPermissions={this.props.pointPermissions} {...rest} />}
                </React.Fragment>

            );
        }
    }
}


const mapStateToProps = state => {
    return {
        naviPath: {
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
            model: state.selectionState.navItem.model,
            modelId: state.selectionState.navItem.model.value.id,
            modelName: state.selectionState.navItem.model.value.Name,
            point: state.selectionState.navItem.point,
            pointId: state.selectionState.navItem.point.value.id,
            pointName: state.selectionState.navItem.point.value.p_Name,
        },
        pointPermissions: {
            UpdateRight: state.selectionState.navItem.point.value.UpdateRight,
            DeleteRight: state.selectionState.navItem.point.value.DeleteRight
        },
        isLoggedIn: state.admin.auth.isLoggedIn,
    }
}
const PointEdit2Redux = connect(
    mapStateToProps,
    null
)(PointEdit);

const enhance = compose(
    withDataProvider,
    withStyles(styles)
);

export default enhance(PointEdit2Redux);

const PointDetailsRaw = withStyles(styles)(({ classes, pointPermissions, naviPath, ...props }) => {

    return (
        <EditController   {...props}>
            {controllerProps =>
                <EditView actions={<PointEditActions naviPath={naviPath} toolbarPermissions={pointPermissions} />}
                    title={<EditTitle />} {...props} {...controllerProps}  >
                    <SimpleForm toolbar={<UpdateToolbar toolbarPermissions={pointPermissions} basePath="Points" />} >
                        <TextInput label="Point Name" source="point.p_Name" className={classes.textFld} />
                        <TextInput label="Point Description" source="point.p_Description" className={classes.textFld} />
                        <ReferenceInput label="Frequency" source="point.p_fq_id" reference="Frequencies"
                            filter={{ is_all: true }}>
                            <SelectInput source="Frequencies" optionText="fq_Name" />
                        </ReferenceInput>
                        <ReferenceInput label="Unit" source="point.p_un_id" reference="Units"
                            sort={{ field: 'un_Name', order: 'ASC' }}
                            filter={{ is_all: true }}>
                            <SelectInput source="Units" optionText="un_Name" />
                        </ReferenceInput>
                        <TextInput label="Account" source="point.p_Account" className={classes.textFld} />
                        <TextInput label="Cost Center" source="point.p_CostCenter" className={classes.textFld} />
                        {/* <PointDetails {...props} /> */}
                    </SimpleForm>
                </EditView>
            }
        </EditController >
    )
})


const PointDetailsPrice = withStyles(styles)(({ classes, pointPermissions, naviPath, ...props }) => (
    <EditController   {...props}>
        {controllerProps =>
            <EditView actions={<PointEditActions naviPath={naviPath} toolbarPermissions={pointPermissions} />}
                title={<EditTitle />} {...props} {...controllerProps}  >
                <SimpleForm toolbar={<UpdateToolbar toolbarPermissions={pointPermissions} basePath="Points" />} >
                    {/* <ConsoleLog> "ControllerProps" + {controllerProps}</ConsoleLog> */}
                    <TextInput label="Point Name" source="point.p_Name" className={classes.textFld} />
                    <TextInput label="Point Description" source="point.p_Description" className={classes.textFld} />
                    <ReferenceInput label="Frequency" source="point.p_fq_id" reference="Frequencies"
                        filter={{ is_all: true }}>
                        <SelectInput source="Frequencies" optionText="fq_Name" />
                    </ReferenceInput>
                    <ReferenceInput label="Unit" source="point.p_un_id" reference="Units"
                        sort={{ field: 'un_Name', order: 'ASC' }}
                        filter={{ is_all: true }}>
                        <SelectInput source="Units" optionText="un_Name" />
                    </ReferenceInput>
                    {/* <PointDetails {...props} /> */}
                    <AutocompleteInput label="Public_id" source="point.p_Public" choices={choices} optionText="name" optionValue="_id" />
                    <ReferenceInput label="Country" source="point.p_co_id" reference="Countries"
                        filter={{ is_all: true }}>
                        <SelectInput source="Countries" optionText="co_Name" />
                    </ReferenceInput>
                </SimpleForm>
            </EditView>
        }
    </EditController >
));

const PointDetailsHistorian = withStyles(styles)(({ classes, pointPermissions, naviPath, ...props }) => (
    <EditController   {...props}>
        {controllerProps =>
            <EditView actions={<PointEditActions naviPath={naviPath} toolbarPermissions={pointPermissions} />}
                title={<EditTitle />} {...props} {...controllerProps}  >
                <SimpleForm toolbar={<UpdateToolbar toolbarPermissions={pointPermissions} basePath="Points" />} >
                    <TextInput label="Point Name" source="point.p_Name" className={classes.textFld} />
                    <TextInput label="Point Description" source="point.p_Description" className={classes.textFld} />
                    <ReferenceInput label="Frequency" source="point.p_fq_id" reference="Frequencies"
                        filter={{ is_all: true }}>
                        <SelectInput source="Frequencies" optionText="fq_Name" />
                    </ReferenceInput>
                    <ReferenceInput label="Unit" source="point.p_un_id" reference="Units"
                        sort={{ field: 'un_Name', order: 'ASC' }}
                        filter={{ is_all: true }}>
                        <SelectInput source="Units" optionText="un_Name" />
                    </ReferenceInput>
                    <TextInput source="point.p_HistorianTag" label="Historian Tag" className={classes.textFld} />
                    <TextInput source="point.p_HistorianServer" label="Historian Server" className={classes.textFld} />
                </SimpleForm>
            </EditView>
        }
    </EditController >
));

const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
const PointDetailsConstant = withStyles(styles)(({ classes, pointPermissions, naviPath, ...props }) => {

    return (
        <EditController   {...props}>
            {controllerProps =>
                <EditView actions={<PointEditActions naviPath={naviPath} toolbarPermissions={pointPermissions} />}
                    title={<EditTitle />} {...props} {...controllerProps}  >
                    <SimpleForm toolbar={<UpdateToolbar toolbarPermissions={pointPermissions} basePath="Points" />} >
                        <TextInput label="Point Name" source="point.p_Name" className={classes.textFld} />
                        <TextInput label="Point Description" source="point.p_Description" className={classes.textFld} />
                        <ReferenceInput
                            label="Frequency"
                            source="point.p_fq_id"
                            reference="Frequencies"
                            filter={{ is_all: true }}>
                            <SelectInput source="Frequencies" optionText="fq_Name" />
                        </ReferenceInput>
                        <ReferenceInput label="Unit" source="point.p_un_id" reference="Units"
                            sort={{ field: 'un_Name', order: 'ASC' }}
                            filter={{ is_all: true }}>
                            <SelectInput source="Units" optionText="un_Name" />
                        </ReferenceInput>
                        <TextInput label="Constant" source="point.p_Constant" className={classes.textFld} validate={number} />
                    </SimpleForm>
                </EditView>
            }
        </EditController >
    )
});

const PointDetailsAggregation = withStyles(styles)(({ classes, pointPermissions, naviPath, ...props }) => (
    <EditController   {...props}>
        {controllerProps =>
            <EditView actions={<PointEditActions naviPath={naviPath} toolbarPermissions={pointPermissions} />}
                title={<EditTitle />} {...props} {...controllerProps}  >
                <SimpleForm toolbar={<UpdateToolbar toolbarPermissions={pointPermissions} basePath="Points" />} >
                    <TextInput label="Point Name" source="point.p_Name" className={classes.textFld} />
                    <TextInput label="Point Description" source="point.p_Description" className={classes.textFld} />
                    <ReferenceInput label="Frequency" source="point.p_fq_id" reference="Frequencies"
                        filter={{ is_all: true }}>
                        <SelectInput source="Frequencies" optionText="fq_Name" />
                    </ReferenceInput>

                    <ReferenceInput label="Unit" source="point.p_un_id" reference="Units"
                        sort={{ field: 'un_Name', order: 'ASC' }}
                        filter={{ is_all: true }}>
                        <SelectInput source="Units" optionText="un_Name" />
                    </ReferenceInput>
                    <ReferenceInput label="Category" source="point.p_ca_id" reference="Categories" resource="Categories"
                        filter={{ is_all: true }}
                        resettable  >
                        <SelectInput optionText="ca_Name" />
                    </ReferenceInput>
                    <ReferenceInput label="Aggregation Function" source="point.p_af_id" reference="AggregateFunction" resource="AggregateFunction"
                        resettable  >
                        <SelectInput optionText="af_Name" />
                    </ReferenceInput>
                    <ReferenceInput label="Aggregation Reference Point" source="point.p_p_id_AggregateRefPoint" reference="/Points/All"
                        filter={{
                            modelId: controllerProps.record ?
                                controllerProps.record.modelId : 12,
                            id: controllerProps.record ?
                                controllerProps.record.id : 1
                        }}
                        resettable  >
                        <SelectInput resource="/Points/All" optionText="p_Name" />
                    </ReferenceInput>
                    <ReferenceInput label="Aggregate From" source="point.p_ap_id_From" reference="AggregateParameter" resource="AggregateParameter"
                        resettable  >
                        <SelectInput optionText="ap_Name" />
                    </ReferenceInput>
                    <ReferenceInput label="Aggregate To" source="point.p_ap_id_To" reference="AggregateParameter" resource="AggregateParameter"
                        resettable  >
                        <SelectInput optionText="ap_Name" />
                    </ReferenceInput>
                    <TextInput label="Account" source="point.p_Account" className={classes.textFld} />
                    <TextInput label="Cost Center" source="point.p_CostCenter" className={classes.textFld} />
                    {/* <ReferenceInput label="Block" source="block.b_id" reference="Block" resource="Block"
                            resettable  >
                            <SelectInput optionText="b_Name" />
                        </ReferenceInput> */}
                </SimpleForm>
            </EditView>
        }
    </EditController >
));

export const choices = [
    { _id: 'Y', name: 'Yes' },
    { _id: 'N', name: 'No' },
];

const PointDetailsFormula = withStyles(styles)(({ classes, pointPermissions, naviPath, ...props }) => {

    const [formulaeditor, setFormulaEditor] = useState(null);

    console.log('PointDetailsFormula ', props);
    const updateFormula = (formuladata) => {
        //debugger;
        // Set updated formula
        setFormulaEditor(formuladata);
    };


    return (
        <EditController   {...props}>
            {controllerProps =>
                <EditView actions={<PointEditActions naviPath={naviPath} toolbarPermissions={pointPermissions} />}
                    title={<EditTitle />} {...props} {...controllerProps}  >
                    <SimpleForm toolbar={<UpdateToolbar toolbarPermissions={pointPermissions} basePath="Points" formula={{ formulaeditor }} />} >
                        <TextInput label="Point Name" source="point.p_Name" className={classes.textFld} />
                        <TextInput label="Point Description" source="point.p_Description" className={classes.textFld} />
                        <ReferenceInput label="Frequency" source="point.p_fq_id" reference="Frequencies"
                            filter={{ is_all: true }}>
                            <SelectInput source="Frequencies" optionText="fq_Name" />
                        </ReferenceInput>
                        <ReferenceInput label="Unit" source="point.p_un_id" reference="Units"
                            sort={{ field: 'un_Name', order: 'ASC' }}
                            filter={{ is_all: true }}>
                            <SelectInput source="Units" optionText="un_Name" />
                        </ReferenceInput>
                        <ReferenceInput label="Category" source="point.Category.id" reference="Categories" resource="Categories"
                            filter={{ is_all: true }}
                            resettable  >
                            <SelectInput optionText="ca_Name" />
                        </ReferenceInput>
                        <TextInput label="Account" source="point.p_Account" className={classes.textFld} />
                        <TextInput label="Cost Center" source="point.p_CostCenter" className={classes.textFld} />
                        <ForumlaEditor {...props} source="FormulaEditorData" Formula={updateFormula} />

                    </SimpleForm>
                </EditView>
            }
        </EditController >
    )
});



const UpdateToolbar = withStyles(toolbarStyles)(({ toolbarPermissions, naviPath, ...props }) => {
    //debugger;

    //console.log("toolbar",toolbarPermissions);
    return (
        <Toolbar {...props}>

            <UpdateCrudButton
                label="Save"
                redirect="list"
                basePath={props.basePath}
                submitOnEnter={false}
                variant="flat"
                color="primary"
                formula={props.formula ? props.formula.formulaeditor : null} // pass updated formula
                disabled={toolbarPermissions.UpdateRight === 'N'}
            />

            <PointDeleteWithConfirmButton
                label="DeleteFromAction"
                redirect="list"
                basePath="/Points"
                submitOnEnter={false}
                variant="flat"
                color="primary"
                resource="Points"
                disabled={toolbarPermissions.DeleteRight === 'N'}
            // undoable={false}  //02.04 removed by Mustafa
            />
        </Toolbar>
    )
});

