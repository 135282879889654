import ModelList from './ModelList';
import ModelShow from './ModelShow';
import ModelCreate from './ModelCreate';
import ModelEdit from './ModelEdit';

export default {
    list: ModelList,
    create: ModelCreate,
    edit: ModelEdit,
    show: ModelShow
};