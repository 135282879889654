import React, { useState, useEffect } from 'react';
import { Datagrid, DatagridBody, List, TextField, Loading } from 'react-admin';
import CheckPagePermission from "../../Common/CheckPagePermissionJs";

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import  EditButton  from '../../Common/EditButton';
import DeleteCrud from '../../Common/DeleteCrud';
import StyledBooleanField from '../../Common/StyledBooleanField';

import { ContractListActions } from '../../Contract/ContractAction';
import { PostPagination } from '../../Common/Pagination';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { tableMuiTheme } from "../../Common/Styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { AccessDenied } from "../../Common/NoAccess";
import { isEqual } from 'lodash';




const ROLES = {
    ADMIN: "Admin",
    INVOICE_CONTROLLER: "InvoiceController",
    CENTRAL_FUNCTION: "CentralFunction",
    CONTRACT_MANAGER: "ContractManager",
};

const CheckPermissions = (permissions, role) => {

    if (permissions !== null || permissions === undefined)
        console.log("Permision: " + permissions.includes(role));

    return (
        (permissions !== null || permissions === undefined)
            ?
            permissions.includes(role)
            : null
    );


}



const unselectAll = (selected, selectedIds) => { if (selectedIds.length == 1) { return selected; } if (selectedIds.length > 0) { selectedIds.shift(); return false; } else return false; }

const PageAccessDatagridRow = ({ record, resource, id, onToggleItem, selectedIds, children, selected, basePath }) => (

    <TableRow key={id}  >
        {/* first column: selection checkbox 
        <TableCell padding="none" >
            {record.DeleteRight !== 'Y' && <Checkbox
               // disabled={isDisabled}
             
                checked={unselectAll(selected, selectedIds)}
                onClick={() => onToggleItem(id)}
            />}
        </TableCell>*/}
        {/* data columns based on children */}
        {React.Children.map(children, field => (
            <TableCell key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                })}
            </TableCell>
        ))}
    </TableRow>
);


const PageAccessDatagridBody = props => <DatagridBody   {...props} row={<PageAccessDatagridRow {...props} />} />;
const PageAccessDatagrid = props => { console.log(props); return <Datagrid  {...props} hasBulkActions={false} body={<PageAccessDatagridBody />} rowClick="show" /> };



const PageAccessList = ({ loading, classes, permissions, dispatch, isLoggedIn, isLoading, selectedIds, ...props }) => 

<CheckPagePermission
    authParams={{ Admin: false }}
    location={window.location}
    match="PageAccess"
    render={({ pagePermissions }) =>
    <div>
      {
      pagePermissions ?
      (pagePermissions.View ==='Y') ?   
                <MuiThemeProvider theme={tableMuiTheme}>
                    <List
                        actions={<ContractListActions permissions {...props} />} pagination={<PostPagination />} title="Page Access"  {...props}  >
                        <PageAccessDatagrid  >
                            <TextField source="ro_id" label="Role Id" />
                            <TextField source="Name" label="Name" />
                            <StyledBooleanField source="View" label="View Right" />
                            <StyledBooleanField source="Insert" label="Insert Right" />
                            <StyledBooleanField source="Update" label="Update Right" />
                            <StyledBooleanField source="Delete" label="Delete Right" />
                            <StyledBooleanField source="Execute" label="Execute Right" />
                            <EditButton label="Edit" resource="/PageAccess/:id" basePath={props.basePath} record={props.record} />
                            <DeleteCrud label="Delete" resource="/PageAccess/:id" basePath={props.basePath} record={props.record} />
                        </PageAccessDatagrid>
                    </List>
                </MuiThemeProvider> : <AccessDenied />
            : <Loading loadingPrimary="Please wait a request for permission is running" loadingSecondary="Content won't load until page is fully loaded" />
      }
    </div>
    }/>

PageAccessList.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
};

const mapState = state => {
    return {
        isLoggedIn: state.admin.auth.isLoggedIn,
        isLoading: state.admin.loading

    }
};

const areEqual = (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps)
}

export default React.memo(connect(mapState, null)(PageAccessList), areEqual);

