import { UserAgentApplication, AuthenticationParameters } from "msal";

// create a request object for login or token request calls
// In scenarios with incremental consent, the request object can be further customized
class ClientAuthServices {
  constructor() {

    var port= window.location.port==="" ? "" : ":"+window.location.port
    this.redirectUri =  window.location.protocol + "//" + window.location.hostname + port; //"https://" + process.env.REACT_APP_WEBSITE_HOSTNAME;
    this.postLogoutRedirectUri = this.redirectUri + "/login"; // "https://" + process.env.REACT_APP_WEBSITE_HOSTNAME + "/login";

    console.log("REACT_APP_WEBSITE_HOSTNAME:" + process.env.REACT_APP_WEBSITE_HOSTNAME);
  
    
    if (ClientAuthServices.instance instanceof ClientAuthServices) {
     
      return ClientAuthServices.instance;
    }
    // Browser check variables
    var ua = window.navigator.userAgent;
    this.msie = ua.indexOf("MSIE ");
    this.msie11 = ua.indexOf("Trident/");
    this.msedge = ua.indexOf("Edge/");
    this.isIE = this.msie > 0 || this.msie11 > 0;
    this.isEdge = this.msedge > 0;

    this.Version = Math.round(Math.random() * 100000);
    var ResourceId = "https://lecustomeraccess.onmicrosoft.com/PrestoPortal/";

    this.GrapghRequestObj = {
      authority:
        "https://login.microsoftonline.com/f9be925e-f94e-4850-a10d-b459d98a27ca/v2.0/.well-known/openid-configuration",
      scopes: ["openid", "profile", "email", "offline_access"],
    };

    this.CustomScopeRequestObj = {
      authority:
        "https://login.microsoftonline.com/f9be925e-f94e-4850-a10d-b459d98a27ca/",

      //scopes: [ResourceId + "/user_impersonation"],
      //  scopes:[ResourceId + "/.default"]
      // scopes:[ResourceId]
      // scopes:["b8b3440c-9f23-432a-99d1-872cfa333287"+ "/.default"]
      //  scopes:["b8b3440c-9f23-432a-99d1-872cfa333287"]
      scopes: [
        "https://lecustomeraccess.onmicrosoft.com/PrestoPortal/user_impersonation",
      ],
    };

    this.SignInRequestObj = {
      authority:
        "https://login.microsoftonline.com/f9be925e-f94e-4850-a10d-b459d98a27ca/",
      scopes: [
        "https://lecustomeraccess.onmicrosoft.com/PrestoPortal/user_impersonation",
      ],
    };

    this.graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    };

   

  
    console.log("process.env.NODE_ENV:" + process.env.NODE_ENV);
    console.log("process.env.REACT_NODE_ENV:" + process.env.REACT_APP_NODE_ENV);
    console.log("process.env.WEBSITE_INSTANCE_ID:" + process.env.WEBSITE_INSTANCE_ID);

    console.log("redirectUri:" + this.redirectUri);
    console.log("postLogoutRedirectUri:" + this.postLogoutRedirectUri);

    this.msalConfig = {
      auth: {
        clientId: "b8b3440c-9f23-432a-99d1-872cfa333287", // "68b74227-7057-4f01-93d2-a14ecfe424e1", //This is your client ID
        authority:
          "https://login.microsoftonline.com/f9be925e-f94e-4850-a10d-b459d98a27ca/", //This is your tenant info
        //authority: "https://login.microsoftonline.com/f9be925e-f94e-4850-a10d-b459d98a27ca/v2.0/.well-known/openid-configuration",
        postLogoutRedirectUri: this.postLogoutRedirectUri,
        redirectUri: this.redirectUri, //This is your redirect URI
        ////redirectUri:  (process.env.NODE_ENV == 'development' && process.env.WEBSITE_SITE_NAME == null)  ? "http://localhost:3000/": "https://prestoclient-slot2.azurewebsites.net/",//This is your redirect URI
        ////postLogoutRedirectUri: (process.env.NODE_ENV == 'development' && process.env.WEBSITE_SITE_NAME == null)  ? "http://localhost:3000/login" : "https://prestoclient-slot2.azurewebsites.net/login",
        //redirectUri:  "https://prestoclient-slot2.azurewebsites.net/",//This is your redirect URI
        //postLogoutRedirectUri: "https://prestoclient-slot2.azurewebsites.net/login",
        //extraScopesToConsent: ["user_impersonation"],
        validateAuthority: true,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true, //this.isIE
      },
      /*  system: {
            loadFrameTimeout: 20,
            tokenRenewalOffsetSeconds: 5000,
            navigateFrameWait: 1000,
            logger: {
                error: console.error,
                errorPii: console.error,
                info: console.log,
                infoPii: console.log,
                verbose: console.log,
                verbosePii: console.log,
                warning: console.warn,
                warningPii: console.warn
            }
        }*/
    };

    this.msalApp = new UserAgentApplication(this.msalConfig);

    //If you support IE, our recommendation is that you sign-in using Redirect APIs
    //If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check

    // can change this to default an experience outside browser use
    this.loginType = this.isIE ? "REDIRECT" : "POPUP";

    const authRedirectCallBack = (error, response) => {
      if (error) {
        const errorMessage = error.errorMessage
          ? error.errorMessage
          : "Unable to acquire access token.";
        console.log(errorMessage);
      } else {
        if (response.tokenType === "access_token") {
          this.callMSGraph(
            this.graphConfig.graphMeEndpoint,
            response.accessToken,
            this.graphAPICallback
          );
        } else {
          console.log("token type is:" + response.tokenType);
        }
      }
    };

    // Register Callbacks for redirect flow
    //this.msalApp.handleRedirectCallback(authRedirectCallBack);
    // msalApp.handleRedirectCallbacks(acquireTokenRedirectCallBack, acquireTokenErrorRedirectCallBack);
    //this.msalApp.handleRedirectCallback(authRedirectCallBack);

    //this.msalApp.ssoSilent

    Object.freeze(this.msalConfig);
    Object.freeze(this);
    ClientAuthServices.instance = this;
  }

  callMSGraph = (theUrl, accessToken, callback) => {
    //Not Used
    /*   if(callback=== undefined)
      console.log("callMSGraph -- CALLBACK is undefined");

      console.log("theURL: " +  theUrl);
      console.log("callMSGraph -accessToken:" + accessToken);
      
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200)
            callback(JSON.parse(this.responseText));
    }
    xmlHttp.open("GET", theUrl, true); // true for asynchronous
    xmlHttp.setRequestHeader('Authorization', 'Bearer ' + accessToken);
    xmlHttp.send();*/
  };

  requiresInteraction = function (errorCode) {
    if (!errorCode || !errorCode.length) {
      return false;
    }
    return (
      errorCode === "consent_required" ||
      errorCode === "interaction_required" ||
      errorCode === "login_required" ||
      errorCode === "user_login_error"
    );
  };

  getToken = () => {
    var that = this;
    //Always start with acquireTokenSilent to obtain a token in the signed in user from cache
    return this.msalApp
      .acquireTokenSilent(this.CustomRequestObj)
      .then((response) => {
        // get access token from response
        //debugger;
        return response.accessToken;
      })
      .catch((error) => {
        // could also check if err instance of InteractionRequiredAuthError if you can import the class.
        if (error.name === "InteractionRequiredAuthError") {
          //debugger;
          return that.msalApp
            .acquireTokenPopup(that.CustomRequestObj)
            .then((response) => {
              // get access token from response

              return response.accessToken;
            })
            .catch((error) => {
              // handle error
              //debugger;
              console.log("getToken error:" + error);
              throw error;
            });
        }
      });
  };

  signIn = () => {
    var that = this;
    if (!this.msalApp.getAccount()) {
      return this.msalApp
        .loginPopup(this.SignInRequestObj)
        .then(function (loginResponse) {
          //Successful login
          that.showWelcomeMessage();
          console.log("Token",loginResponse.idToken);
          console.log("Scopes",loginResponse.scopes);
          //  this.user= {
          //     Email: loginResponse.account.userName,
          //     UserName: loginResponse.account.name,
          //     sid:loginResponse.account.sid
          //   }

          //this.showWelcomeMessage();

          return loginResponse;
        })
        .catch(function (error) {
          //Please check the console for errors
          console.log("SignIn Error:" + error);
        });
    }
  }

  signOut = () => {
    this.msalApp.logout();
  }

  graphAPICallback = (data) => {
    console.log(JSON.stringify(data, null, 2));
  }

  //This function can be removed if you do not need to support IE
  acquireTokenRedirectAndCallMSGraph = () => {
    //Always start with acquireTokenSilent to obtain a token in the signed in user from cache
    this.msalApp
      .acquireTokenSilent(this.CustomRequestObj)
      .then(function (tokenResponse) {
        this.callMSGraph(
          this.graphConfig.graphMeEndpoint,
          tokenResponse.accessToken,
          this.graphAPICallback
        );
      })
      .catch(function (error) {
        console.log(error);
        // Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
        // Call acquireTokenRedirect
        // this.signOut();
        if (this.requiresInteraction(error.errorCode)) {
          this.msalApp.acquireTokenRedirect(this.CustomRequestObj);
        }
      });
  }

  getAccount = () => {
    // runs on page load, change config to try different login types to see what is best for your application

    if (this.loginType === "POPUP") {
      if (this.msalApp.getAccount()) {
        // avoid duplicate code execution on page load in case of iframe and popup window.
        this.showWelcomeMessage();
        this.acquireTokenPopup();
      }
    } else if (this.loginType === "REDIRECT") {
      document.getElementById("SignIn").onclick = function () {
        this.msalApp.loginRedirect(this.CustomRequestObj);
      };
      if (
        this.msalApp.getAccount() &&
        !this.msalApp.isCallback(window.location.hash)
      ) {
        // avoid duplicate code execution on page load in case of iframe and popup window.
        this.showWelcomeMessage();
        this.acquireTokenRedirect();
      }
    } else {
      console.error("Please set a valid login type");
    }
  }

  showWelcomeMessage = () => {
    console.log(
      "Welcome " +
      this.msalApp.getAccount().userName +
      " to Microsoft Graph API . Your roles are: " +
      JSON.stringify(this.msalApp.getAccount().idTokenClaims.roles)

    );
    console.log("Account " + this.msalApp.getAccount().name);
    console.log("redirectUri:" + this.redirectUri);
    console.log("postLogoutRedirectUr:" + this.postLogoutRedirectUri);
  }

  getUserInfo = () => {

    var account = this.msalApp.getAccount();
    var user =
      account !== null
        ? {
          Email: account.userName,
          UserName: account.name,
          sid: account.sid,
        }
        : null;

    return user;
  }

  getRoles = () => {
    console.log(
      "Roles " + JSON.stringify(this.msalApp.getAccount().idTokenClaims.roles)
    );
    console.log("Account " + JSON.stringify(this.msalApp.getAccount()));


    return this.msalApp.getAccount().idTokenClaims.roles;
  }
}

export default new ClientAuthServices();
