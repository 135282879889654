import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListActions from '../../Common/Actions';
import EditButton from '../../Common/EditButton';
import { PostPagination } from '../../Common/Pagination';
import CheckPagePermission from "../../Common/CheckPagePermissionJs";

import { MuiThemeProvider } from "@material-ui/core/styles";
import {isEqual} from 'lodash';
import { tableMuiTheme} from "../../Common/Styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


export const MediumList = ({permissions,...props}) => 
<CheckPagePermission
authParams={{ Admin: true }}
location={window.location}
match="Mediums"
render={({ pagePermissions }) =>
<div>
  {
   pagePermissions ?
  (pagePermissions.View ==='Y') ?   
        <MuiThemeProvider theme={tableMuiTheme}>
        <List actions={<ListActions permissions {...props}/>}
            pagination={<PostPagination />}
            sort={{ field: 'me_id', order: 'DESC' }}
            title="Mediums" 
            {...props} 
            bulkActionButtons={false}
            >
            <Datagrid rowClick="show"  >
                <TextField source="id" label="Id" />
                <TextField source="me_Name" label="Name" />                
                <EditButton label='Edit' resource="/Mediums/:id" basePath={props.basePath} />
            </Datagrid>
        </List>
        </MuiThemeProvider> :  null:null
}</div>
}/>