import React from 'react';
import {
    Edit, SimpleForm, TextInput, AutocompleteInput, Toolbar
} from 'react-admin';

import UpdateCrudButton from '../../Common/UpdateCrud';
import DeleteWithConfirmButton from '../../Common/DeleteCrud';
import { withStyles } from '@material-ui/core';
import { styles, toolbarStyles} from '../../Common/Styles';
import { EditActions } from '../../Common/Actions';

const UnitEdit = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    return (
        <Edit actions={<EditActions />} {...props} >
            <SimpleForm toolbar={<UpdateToolbar basePath="/Units" />}>
                <TextInput label="Unit Name" source="un_Name" validate={required()} className={classes.textFld} />
                <AutocompleteInput label="IsCurrency" source="un_IsCurrency" choices={choices} optionText="name" 
                optionValue="_id" validate={required()} />
            </SimpleForm>
        </Edit>
    );
};

export const choices = [
    { _id: 'Y', name: 'Yes' },
    { _id: 'N', name: 'No' },
];

export default withStyles(styles)(UnitEdit);

const UpdateToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>    
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath="/Units"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            disabled={false}
        />
        <DeleteWithConfirmButton
            label="Delete"
            redirect="list"
            basePath="/Units/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="Units"
            undoable={false}
            disabled={false}
        />
    </Toolbar>
));
