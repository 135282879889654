import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { ShowActions } from '../../Common/Actions';

export const FrequencyShow = props => (
    <Show actions={<ShowActions />}  {...props} title="Frequncy Details" >
        <SimpleShowLayout>
            <TextField source="id" label="Id" />
            <TextField source="fq_Name" label="Name" />
            <TextField source="fq_Minute" label="Minute" />
            <TextField source="fq_Day" label="Day" />
            <TextField source="fq_Month" label="Month" />
        </SimpleShowLayout>
    </Show>
);