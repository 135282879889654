//import React from 'react';
import React, { useState, useEffect } from "react";
import {
  Datagrid,
  DatagridBody,
  List,
  TextField,
  DateField,
  Loading,
  FunctionField
} from "react-admin";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { Authenticated } from "react-admin";
import { ModelButton } from "./ModelButton";
import  EditButton  from "../Common/EditButton";
import  ShowButton  from "../Common/ShowButton";
import DeleteCrud from "../Common/DeleteCrud";
import NavigationButton from "../Common/NavigationButton";
import { ContractListActions } from "./ContractAction";
import { ContractFilter } from "./ContractFilter";
import { PostPagination } from "../Common/Pagination";
import { connect } from "react-redux";
import { tableMuiTheme } from "../Common/Styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import ConsoleLog from "../Common/ConsoleLog";
import CheckPagePermission from "../Common/CheckPagePermissionJs";
import { AccessDenied } from "../Common/NoAccess";

const ROLES = {
  ADMIN: "Admin",
  INVOICE_CONTROLLER: "InvoiceController",
  CENTRAL_FUNCTION: "CentralFunction",
  CONTRACT_MANAGER: "ContractManager",
};

// const CheckPermissions = (permissions, role) => {
//   if (permissions !== null || permissions === undefined)
//     console.log("Permision: " + permissions.includes(role));

//   return permissions !== null || permissions === undefined
//     ? permissions.includes(role)
//     : null;
// };

// const unselectAll = (selected, selectedIds) => {
//   if (selectedIds.length == 1) {
//     return selected;
//   }
//   if (selectedIds.length > 0) {
//     selectedIds.shift();
//     return false;
//   } else return false;
// };

const ContractDatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  selectedIds,
  children,
  selected,
  basePath,
}) => (
  <TableRow key={id}>
    {/* first column: selection checkbox 
        <TableCell padding="none" >
            {record.DeleteRight !== 'Y' && <Checkbox
               // disabled={isDisabled}
             
                checked={unselectAll(selected, selectedIds)}
                onClick={() => onToggleItem(id)}
            />}
        </TableCell>*/}
    {/* data columns based on children */}
    {React.Children.map(children, (field) => (
      <TableCell key={`${id}-${field.props.source}`}>
        {React.cloneElement(field, {
          record,
          basePath,
          resource,
        })}
      </TableCell>
    ))}
  </TableRow>
);

const ContractDatagridBody = (props) => (
  <DatagridBody {...props} row={<ContractDatagridRow {...props} />} />
);
const ContractDatagrid = ({
  loading,
  classes,
  pagePermissions,
  storeSelection,
  readContracts,
  dispatch,
  // isLoggedIn,
  isLoading,
  selectedIds,
  ...props
}) => {
  //admin.resources.Contracts.data
  return (
    <Datagrid
      {...props}
      hasBulkActions={false}
      body={<ContractDatagridBody />}
      rowClick="show"
    />
  );
};

const ContractList = ({
  loading,
  classes,
  pagePermissions,
  readContracts,
  dispatch,
  storeSelection,
  // isLoggedIn,
  isLoading,
  selectedIds,
  ...props
}) => {
  //const [access, setAccess] = useState(false);

  const [message, setMessage] = useState("Please wait a request for permission is running");

  // permissions
  //   ? console.log(permissions.View)
  //   : console.log("permission is null");
  //debugger;

  return <CheckPagePermission
    authParams={{ Admin: false }}
    location={window.location}
    match="Contracts"
    render={({ pagePermissions }) =>
      <div>
        {pagePermissions && pagePermissions.View === "Y" ? (
          <MuiThemeProvider theme={tableMuiTheme}>
            <List
              actions={<ContractListActions permissions={pagePermissions} />}
              pagination={<PostPagination />}
              title="Contracts"
              sort={{ field: 'c_id', order: 'DESC' }}
              filters={<ContractFilter />}
              {...props}
            >
              <ContractDatagrid>
                <TextField source="id" label="Id" sortBy="c_id"/>
                <TextField source="c_Name" label="Name" />
                <TextField source="sp_Name" label="Supplier" />
                <TextField source="me_Name" label="Medium" />
                <TextField source="co_Name" label="Country" />
                <DateField
                  source="c_ValidFrom"
                  label="Valid From"
                  showTime
                  style={{ fontStyle: "italic" }}
                />
                <DateField
                  source="c_ValidTo"
                  label="Valid To"
                  showTime
                  style={{ fontStyle: "italic" }}
                />
                {/*CheckPermissions(permissions,ROLES.CONTRACT_MANAGER)? <EditButton resource="/Contracts/:id" basePath={props.basePath} /> : null*/}
                <NavigationButton
                  resource="Model"
                  path="Models"
                  label="Model"
                  basePath={props.basePath}
                  record={props.record}
                />
                <NavigationButton
                  resource="InvoiceChecks"
                  path="InvoiceChecks"
                  label="InvoiceCheck"
                  basePath={props.basePath}
                  record={props.record}
                />
                <NavigationButton
                  resource="CopyContract"
                  path="CopyContract"
                  label="Copy"
                  basePath={props.basePath}
                  record={props.record}
                />
                <EditButton
                  label="Edit"
                  resource="/Contracts/:id"
                  basePath={props.basePath}
                  record={props.record}
                />
                <ShowButton
                  label="Show"
                  resource="/Contracts/:id"
                  basePath={props.basePath}
                  record={props.record}
                />
                <DeleteCrud
                  label="Delete"
                  resource="/Contracts/:id"
                  basePath={props.basePath}
                  record={props.record}
                />
              </ContractDatagrid>
            </List>
          </MuiThemeProvider>)
          :
          <AccessDenied />}
      </div>


    }
  />


};
/*
ContractList.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};*/

const mapState = (state) => {
  return {
    readContracts: state.selectionState,
    isLoading: state.admin.loading,
  };
};

const mapDispatch = (dispatch) => {
  return {
    storeSelection: () => dispatch({
      type: "ADD_SELECTION",
      payload: {
        type: "contract",
        value: undefined
      }
    })

  }
}

//const ContractDatagrid2 = connect(mapState, mapDispatch)(ContractDatagrid);
export default connect(mapState, mapDispatch)(ContractList);
