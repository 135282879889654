import React from 'react';
import { Datagrid, TextField, List, NumberField, RichTextField,Show,SimpleShowLayout  } from 'react-admin';
import { PostPagination } from '../../Common/Pagination';
import { MuiThemeProvider } from '@material-ui/core';
import { tableMuiTheme,styles,cardActionStyle } from "../../Common/Styles";

import ApprovalAuditListActions from './ApprovalAuditAction';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

const CommentPanel = props => (
 

       <span>{props.record.a_Comment} </span>
  
 
);

const ApprovalAuditList = ({ permissions,naviPath, ...props }) => {

  //debugger;

  return (
    <MuiThemeProvider theme={tableMuiTheme} >
      <List
        {...props}
        exporter={false}
        title="Approval Audit"
        pagination={<PostPagination />}
        actions={<ApprovalAuditListActions naviPath={naviPath} style={cardActionStyle.bigblueStyle} />}        
        sort={{ field: 'a_ApprovalDate', order: 'DESC' }}
        filter={props.location ? props.location.state ? props.location.state.record : undefined : undefined}
        bulkActionButtons={false}
      >
        <Datagrid  expand={<CommentPanel />}>
          {/* <TextField label="Id" source="id" /> */}
          <TextField label="Invoice Check" source="ic_Name" />
          <TextField label="Approved" source="a_Approved" />
          <TextField label="Approved By" source="ApprovedBy" />
          <TextField label="Approval Date" source="a_ApprovalDate" />
          <TextField label="Start" source="a_StartDateTime" />
          <TextField label="End" source="a_EndDateTime" />

        </Datagrid>
      </List>
    </MuiThemeProvider>
  );
}



const mapStateToProps = state => {
  return {
      naviPath: {
          audit: state.selectionState.navItem.audit,
          auditItem: state.selectionState.navItem.audit.value.c_Name,

      },
      Permissions: {
          UpdateRight: true,//state.selectionState.navItem.contract.value.UpdateRight,
          DeleteRight: true,//state.selectionState.navItem.contract.value.DeleteRight
      },
      isLoggedIn: state.admin.auth.isLoggedIn,
  }
}
const ApprovalAuditList2Redux = connect(
  mapStateToProps,
  null
)(ApprovalAuditList);


export default withStyles(styles)(ApprovalAuditList2Redux);
