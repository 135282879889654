import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { ShowActions } from '../../Common/Actions';

export const CountryShow = props => (
    <Show actions={<ShowActions />}  {...props} title="Country Details" >
        <SimpleShowLayout>
            <TextField source="id" label="Id" />
            <TextField source="co_Name" label="Name" />
            <TextField source="co_Alpha_2" label="Country Alpha 2" />
            <TextField source="co_Alpha_3" label="Country Alpha 3" />
            <TextField source="r_Name" label="Region" />
        </SimpleShowLayout>
    </Show>
);