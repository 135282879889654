import { SupplierList } from './SupplierList';
import { SupplierShow } from './SupplierShow';
import SupplierCreate from './SupplierCreate';
import SupplierEdit from './SupplierEdit';

export default {
    list: SupplierList,
    create: SupplierCreate,
    edit: SupplierEdit,
    show: SupplierShow
};