import { write, writeFile } from 'xlsx';
import * as XLSX from 'xlsx';



export function getExtension(file)
{   const  fileName=file.name;
    var name = [fileName.substring(0,fileName.lastIndexOf('.')), fileName.substring(fileName.lastIndexOf('.'))];//fileName.split('.');
    return name;//parts[parts.length - 1];

}


// Take following code from xlsx@0.15.1.
// They are private scoped and inaccessible from outside of the library.
const base1899 = new Date(1899, 11, 30, 0, 0, 0);
const base1904 = new Date(1904, 1, 1, 0, 0, 0);
const base1900 = new Date(1900, 1, 0, 0, 0, 0);
//const dnthresh = basedate.getTime() + (new Date().getTimezoneOffset() - basedate.getTimezoneOffset()) * 60000;
const dnthresh = base1900.getTime() + (getTimezoneOffsetMS(new Date()) - getTimezoneOffsetMS(base1900));
// var dnthresh = basedate.getTime() + (new Date().getTimezoneOffset() - basedate.getTimezoneOffset()) * 60000;

//0,0000115740729  *60*60*x hours


//https://docs.microsoft.com/en-us/office/troubleshoot/excel/1900-and-1904-date-system

const day_ms = 24 * 60 * 60 * 1000;
//1,462 days is equal to four years and one day (including one leap day).
const days_1462_ms = 1462 * day_ms;

function datenum(v, date1904) {
  let epoch = v.getTime();
  if (date1904) {
    epoch -= days_1462_ms;
  }
  return (epoch - dnthresh) / day_ms;
}

function fixImportedDate(date, is_date1904) {
  // Convert JS Date back to Excel date code and parse them using SSF module.
  const parsed = XLSX.SSF.parse_date_code(datenum(date, false), {date1904: is_date1904});
  //return `${parsed.y}-${parsed.m}-${parsed.d}`;
  // or
  // return parsed;
  // or if you want to stick to JS Date,
   return new Date(parsed.y, parsed.m, parsed.d, parsed.H, parsed.M, parsed.S);
}


function getTimezoneOffsetMS(date) {
  var time = date.getTime();
  var utcTime = Date.UTC(date.getFullYear(),
                         date.getMonth(),
                         date.getDate(),
                         date.getHours(),
                         date.getMinutes(),
                         date.getSeconds(),
                         date.getMilliseconds());
  return time - utcTime;
  //return utcTime;
}

function datenum_local(v, date1904) {
  var newOffset=(getTimezoneOffsetMS(new Date()) - getTimezoneOffsetMS(base1900))

  console.log("NEW_OFFSET",newOffset/60,newOffset,base1900.getTime())
  var dnthresh = base1900.getTime() + newOffset*0.0169;
	var epoch = v.getTime();
	if(date1904) epoch -= 1461*24*60*60*1000;
	else if(v >= base1904) epoch += 24*60*60*1000;
	return (epoch - (dnthresh + (getTimezoneOffsetMS(v) - getTimezoneOffsetMS(base1900)))) / (24 * 60 * 60 * 1000);
	// return (epoch - (dnthresh + (v.getTimezoneOffset() - basedate.getTimezoneOffset()) * 60000)) / (24 * 60 * 60 * 1000);
}

function fixPrecisionLoss2(date, is_date1904) {
  // Convert JS Date back to Excel date code and parse them using SSF module.
  const parsed = XLSX.SSF.parse_date_code(datenum_local(date, false), {date1904: is_date1904});
  
  return new Date(parsed.y, parsed.m, parsed.d, parsed.H, parsed.M, parsed.S);
}


const importBugHotfixDiff = (function () {
  const basedate = new Date(1899, 11, 30, 0, 0, 0);
  const dnthreshAsIs = (new Date().getTimezoneOffset() - basedate.getTimezoneOffset()) * 60000;
  const dnthreshToBe = getTimezoneOffsetMS(new Date()) - getTimezoneOffsetMS(basedate);
  return dnthreshAsIs - dnthreshToBe;
}());

function fixPrecisionLoss(date) {
  return (new Date(date.getTime() - importBugHotfixDiff));
}


export function to_csv(workbook, FS) {
  var result = [];
  var lang= "en-US"
  var headerLength=1
  var errorMsg={}
  var lines
  //console.log(workbook);
  workbook.SheetNames.forEach(function(sheetName) {
   
    var dateFormat= 'dd"."mm"."yyyy HH":"mm":"ss'
    if(lang==="de-DE")
      dateFormat='dd"."mm"."yyyy HH":"mm":"ss'
    else if(lang==="en-US")
      //dateFormat='mm"/"dd"/"yyyy HH":"mm":"ss":"SS'
      dateFormat='mm"/"dd"/"yyyy HH":"mm":"ss'
    
    dateFormat='mm"/"dd"/"yyyy HH":"mm'
      //https://github.com/SheetJS/sheetjs/issues/1212
      //https://github.com/SheetJS/sheetjs/issues/1470 //diff in Time is because of buf in V8
    var csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName], {
      //FS:FS||",",
      FS: ';',
      RS: '\n',
      dateNF: dateFormat,
      strip: false,
      blankrows: false,
      cellDates: true,
      cellText:false, 
  });


    if(csv.length > 0){
   
      console.log("CSV",csv)
      
      //Maybe not needed call dateTime converter check: https://github.com/SheetJS/sheetjs/issues/718
       let {lines,errMsg}=parseCSVDateTime(csv,lang,headerLength)
       console.log("Lines", lines)
      if(lines.length > 0 && Object.keys(errMsg).length === 0 ){
        result.push("SHEET: " + sheetName);
        result.push("");
        result.push(...lines);
      }
      errorMsg=errMsg;
    }
   
   
  });

  // "data:text/csv;charset=utf-8," 
  
  let contents ="" + result.join("\n");
  return {contents,errorMsg}
}


function JSDateToExcelDate(inDate) {

  var serial = 25569.0 + ((inDate.getTime() - (inDate.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
  //var serial=Math.round(returnDateTime)
  //return returnDateTime.toString().substring(0,8);

  var utc_days  = Math.floor(serial - 25569);
   var utc_value = utc_days * 86400;                                        
   var date_info = new Date(utc_value * 1000);

   var fractional_day = serial - Math.floor(serial) + 0.0000001;

   var total_seconds = Math.floor(86400 * fractional_day);

   var seconds = total_seconds % 60;

   total_seconds -= seconds;

   var hours = Math.floor(total_seconds / (60 * 60));
   var minutes = Math.floor(total_seconds / 60) % 60;
   //var newDate= new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes);
  var newDate= new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
   return newDate.toISOString()

}

function convertDate(dateStr,lang){

  if(dateStr.includes('.') ){
    var dtArr=dateStr.split(".");
    var order=[2,1,0]  //Order[ 0:year,1:monthidx,2:day]==>Arr[0: day,1:monthidx,2:year]
  }
  else {
    var dtArr=dateStr.split("/");
    order=[2,0,1]  //Order[0:year,1:monthidx,2:day] ==>Arr[0: monthIdx,1:day,2:year]
  }

  var year      = dtArr[order[0]].substring(0,4)
  var monthIndex= parseInt(dtArr[order[1]])-1
  var day       = dtArr[order[2]]
  var timeStr   = dtArr[order[0]].substring(4,)  // with space ' 13:13.22'
  var timeArr = timeStr.replace(/\s+/g, '').split(":")
  console.log("NEW_1_TimeArray",timeArr)
  var dt = new Date(year,monthIndex,day);
 
  if(dt=="Invalid Date"){
    console.log("Error",dt,"year:",year,"monthIndex:",monthIndex,"day:",day)
  }

    
  //var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var tzoffset = dt.getTimezoneOffset() * 60000; //offset in milliseconds
  
  console.log("OFFSET:",tzoffset)
  /*if(lang ==="de-DE")
    newDate= dt.toISOString(lang,  {  year: '2-digit', month: '2-digit', day: '2-digit',hour12: false, hour:'2-digit', minute:'2-digit' }).replace(',','');
  else if(lang ==="en-US")
   //newDate = (new Date(dt - tzoffset)).toISOString(lang,{  year: '2-digit', month: '2-digit', day: '2-digit',hour12: false, hour:'2-digit', minute:'2-digit' }).slice(0, -1);
   //newDate=newDate.replace("T00:00:00.000","")
   newDate= dt.toISOString(lang, {  year: '2-digit', month: '2-digit', day: '2-digit',hour12: false, hour:'2-digit', minute:'2-digit' })
*/
    //https://github.com/SheetJS/sheetjs/issues/1565

  //var dt2 = new Date(year,monthIndex,day,timeArr[0],timeArr[1],timeArr[2]);
  var dt2 = new Date(year,monthIndex,day,timeArr[0],timeArr[1]);
  console.log("date before removing offset", dt2.toISOString(lang, options ))
  var dt3 = new Date(dateStr);
  
  var dd1 =new Date(dt - tzoffset)
  var dd2 =new Date(dt2 - tzoffset)
  var dd3 =new Date(dt3 - tzoffset)

  console.log("NEW_DateTime 1", dd1.toISOString(lang, options ))
  console.log("NEW_DateTime 2",dd2.toISOString(lang, options ))
  console.log("NEW_DateTime 3",dd3.toISOString(lang, options ))

  var fixedDate=dd3.toISOString(lang, options )//JSDateToExcelDate(dt3)
  
  var options = {  year: '2-digit', month: '2-digit', day: '2-digit',hour12: false, hour:'2-digit', minute:'2-digit' };
  var isoformatedDate=fixedDate.slice(0,-8)//.toISOString(lang, options )
  console.log("NEW_1_fixPrecisionLoss isoformatedDate:",isoformatedDate)

  return isoformatedDate
}

function checkColFormat(rIdx,rowSplitted,colFmt=['str','str','str','dt','dt','empty','num']){
  //check Length
  let errMsg={}
  let isEntityOk=false;
  if(rowSplitted.length !== colFmt.length){
    var Msg=`Row ${rIdx+1} has ${rowSplitted.length } entities, but ${ colFmt.length} entities are expected`;
    errMsg['msgType']="Error";
    errMsg['status']=!isEntityOk;
    errMsg['msg']=Msg;
    return errMsg

  }

  for(let cIdx=0; cIdx<rowSplitted.length; cIdx++){
    switch(colFmt[cIdx]){
      case "str": isEntityOk=true; break;  //check not implemented yet
      case "dt":  isEntityOk=true; break; //check not implemented yet
      case "empty": isEntityOk= rowSplitted[cIdx] === ''; break;
      case "num": isEntityOk=/^\d+\.?\d+$/.test(rowSplitted[cIdx]); break;
      default:
        isEntityOk=false;

    }

    if(!isEntityOk){
      var Msg=` Entity of Row: ${rIdx+1}, Column: ${cIdx+1} is not of type: '${colFmt[cIdx]}' `
      errMsg['msgType']="Error";
      errMsg['status']=!isEntityOk;
      errMsg['msg']=Msg;
      return errMsg
      //break;
    }

  }

  return errMsg;


}

 //Read DateTime start/stop line by line and convert them into german format
 //To Do: split Dates and convert to Datetime
 function parseCSVDateTime(csv,lang,headerLength) {
    
    var allTextLines = csv.split(/\r\n|\n/);
    var lines = [];
    var errMsg={}

    for (var i=headerLength; i<allTextLines.length; i++) {
      console.log("newLine Test",(allTextLines[i]))

      if(allTextLines[i].length > 0){

        var lineSplitted = allTextLines[i].split(';');
        if(lineSplitted.length == 1)
          lineSplitted =  allTextLines[i].split(',');
        errMsg=checkColFormat(i,lineSplitted)
        if( Object.keys(errMsg).length !== 0 )
         break;
        

       
        var tarr = [];
        lineSplitted[3]=convertDate(lineSplitted[3],lang)
        lineSplitted[4]=convertDate(lineSplitted[4],lang)
        allTextLines[i]=lineSplitted.join()
        tarr.push(allTextLines[i]);
          
        lines.push(tarr);
          }
    }
  //console.log(lines);
  return {lines, errMsg };
}

export function parseEx(event) {
  var arrayBuffer =event; // reader.result;
  ////debugger

  var options = { 
    type: 'array',
   // encoding: 'utf-8',
    cellDates: false,
 };

  var workbook = XLSX.read(arrayBuffer, options);
 

  var sheetName = workbook.SheetNames
  var sheet = workbook.Sheets[sheetName]
  var sDate1904 = workbook.Workbook.WBProps.date1904;
  //console.log(sheet.A1);
   console.log("New_Workbook",sDate1904);

  var out = [], txt = "";
  var opts = "" || {};
  if(!sheet || !sheet["!ref"]) return "";
  var r = XLSX.utils.decode_range(sheet["!ref"]);
  var fs = opts.FS||",", rs = opts.RS||"\n";

  var data = [];
  
 

  for(var R = r.s.r; R <= r.e.r; ++R) {
   
    var row = [];
    for(var C = r.s.c; C < 4; ++C) {
      
      var val = sheet[XLSX.utils.encode_cell({c:C,r:R})];

      if(val===undefined && (C == 0 || C==1)) break; 

      if(C<2 && val.t=="n"){            // Work around because of https://github.com/SheetJS/sheetjs/issues/1522
        val.v = parseFloat(val.v.toFixed(6));
        val.z="dd/mm/yyyy hh:mm";
        //console.log("val:" + JSON.stringify(val));
        delete val.w;
      }

      txt = String(XLSX.utils.format_cell(val));
      txt = txt.replace(/ 24:/g,' 00:'); // Workaround 
      //console.log(txt);
       // txt = val;
      /*  if(txt.indexOf(fs)!==-1 || txt.indexOf(rs)!==-1 || txt.indexOf('"')!==-1)
            txt = "\"" + txt.replace(/"/g, '""') + "\"";*/
        row.push(txt);
    
  }
   if(row.length==0 ) continue;
    data.push(row);

  }

  return data;

};



export const convert = (filedata) => new Promise((resolve, reject) => {
    var ext = getExtension(filedata);
    const reader = new FileReader();
    console.log("ext: " + JSON.stringify(ext[1]) );
    if(ext[1]=='.csv')
    {
     
    reader.readAsText(filedata);
    var contents = reader.result;//fs.readFileSync('./dmv_file_reader.txt').toString()
    var blob = new Blob([contents], { type: 'text/plain' });
    var newfile = new File([blob], ext[0] +".txt", {type: "text/plain"});
    //reader.onload = () => resolve(parseCSV(reader.result));
    reader.onload = () => resolve(newfile);


    }
    else if( ext[1]=='.xlsx' || ext[1]=='.xls')
    {

      console.time();
      reader.readAsArrayBuffer(filedata);
      //reader.onload = () => resolve(parseEx(reader.result));
      var options = { 
        type: 'array',
        encoding: 'utf-8',
        cellText:false,
        cellDates: true,
     };
      reader.onload = () => {
        var workbook = XLSX.read(reader.result, options);
        //var sDate1904 = workbook.Workbook.WBProps.date1904;
        var {contents,errorMsg} = to_csv(workbook,'')
        
        console.log("contents:",contents)
        console.log("result:",errorMsg)
        if(Object.keys(errorMsg).length !== 0 )
          reject(errorMsg);

        //var byteArray = [];
      
        /*contents.replace(/([0-9a-f]{2})/gi, function (d) {
          byteArray.push(parseInt(d, 16));
        });
   */

     // var blob = new Blob([new Uint8Array(byteArray)], { type: 'text/plain;charset=UTF-16LE;' });
      var DataStr=strEncodeUTF16(contents);
       //var newstr=toUCS2(contents);
       //var blob = new Blob([newstr], { type: 'text/plain;charset=UTF-16LE;' });
       var blob=new Blob([DataStr],{type:'text/csv;charset=UTF-16LE;'});
       var blobUrl=URL.createObjectURL(blob)

        var newfile = new File([blob],  ext[0] +".csv", {type: "text/csv;charset=UTF-16LE;"});
        resolve(newfile);
      
      }

      console.timeEnd();
    }
    reader.onerror = reject;
  
    }
);

function strEncodeUTF16(str) {
  var buf = new ArrayBuffer((str.length+1)*2);
  var bufView = new Uint16Array(buf);
  bufView[0]=65279; //FEFF
  for (var i=0, strLen=str.length; i < strLen; i++) {
    bufView[i+1] = str.charCodeAt(i);
  }
  return bufView;
}

/*function toUCS2(code) {
  function toPaddedHexString(num, len) {
    const str = num.toString(16).toUpperCase();
    return '0'.repeat(len - str.length) + str;
  }
  const hexers = punycode.ucs2.decode(code);
  let ucs2str = '';
  hexers.forEach((i) => {
    ucs2str += toPaddedHexString(i, 4);
  });
  return ucs2str;
}*/