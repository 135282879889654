import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { CREATE } from 'ra-core';
import { withDataProvider, Create, TextInput, crudGetAll, FormDataConsumer, SelectInput, SimpleForm, Toolbar } from 'react-admin';
import PropTypes from 'prop-types';
import { reset } from "redux-form";
import { withStyles } from '@material-ui/core';
import { margin } from '../Common/Styles';
import compose from 'recompose/compose';
import ResultButton from './ResultButton';
import ResultList from './ResultList';

const toolbarStyles = {
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	}
}

const SanitizedGrid = ({ basePath, ...props }) => {
	return (
		<Grid {...props} />
	);
};
 
const CreateToolbar = withStyles(toolbarStyles)(({handler, ...props }) => {
	
	////debugger;
	//console.log(props);

	return (
	<Toolbar {...props}>
		{/* {console.log(props)} */}
		<ResultButton label="Result"  handler = {handler} variant="flat" color="primary"  />
	</Toolbar>
)});

class Result extends Component {

	constructor(props) {
		super(props);
		this.state = {
			Contract: undefined,
			Model: undefined,
			Point: undefined,
			Month: undefined,
			isShowResult: false,
		
		}
	}	

	allowUpload = (e) => {
		var obj = new Object();
		console.log(e);
		//debugger;
		obj[e.target.name] = e.target.value;
		var choiceList=new Object();
		if (e.target.name === "Contract") {
			obj["Model"] = "";
			obj["Point"] = "";
			choiceList=this.contractList;
		}
		if (e.target.name === "Model")
			choiceList=this.modelList;

		if (e.target.name === "Point")
			choiceList=this.pointList;
			

		for (let i = 0; i < choiceList.length; i++) {
			if (choiceList[i].id == e.target.value) {
				obj[e.target.name] = choiceList[i]
			}
		}

		this.setState(obj);
	}

	componentDidMount() {

		this.fetchData();
	}

	fetchData = () => {
		const { dispatch } = this.props;

		dispatch(
			crudGetAll(
				'Calculation/Details', 'DESC', {isContract: true, isModel: true, isPoint: true, pa_Name: "Result"}, 25,
				({ payload, requestPayload, error }) => {
					//console.log(payload.data);
					this.contractList = payload.data[0].contract;
					this.modelList = payload.data[0].model;
					this.pointList = payload.data[0].point;

					//console.log(this.contractList);
					//console.log(this.modelList);
					//console.log(this.pointList);

					this.setState({
						contractChanged: true,
						modelChanged: true,
						pointChanged: true
					});
				})
		);

	}

	getModelObjectsById = (value) => {
		//console.log(value);
		//console.log(this.modelList);
		
		var reducedArray = [];
		for (let i = 0; i < this.modelList.length; i++) {
			if (this.modelList[i].c_id == value.Contract) {
				reducedArray.push({ id: this.modelList[i].id, m_Name: this.modelList[i].m_Name });
			}

		}
		return reducedArray;
	}

	getPointObjectsById = (value) => {
		//console.log(value);
		var reducedArray = [];
		if(this.state.Model != ""){
			for (let i = 0; i < this.pointList.length; i++) {
				if (this.pointList[i].m_id == value.Model) {
					reducedArray.push({ id: this.pointList[i].id, p_Name: this.pointList[i].p_Name });
				}
			}
		
			reducedArray.push({ id: 'all', p_Name: 'All Points' });
		}
		console.log("state",this.state)
		return reducedArray;
	}

	updateData = (values) => {
		//debugger;
		//console.log(values);
		this.setState({
			isShowResult: true,
			Month: values.month,
			formData: values
		});  
		
			console.log("show2",this.state);
		
    };

	render() {
		if (!this.contractList || !this.modelList || !this.pointList) return null;

		const {			
			classes = {},
			className,
			dataProvider,
			dispatch,
			...rest		
		} = this.props;

		const required = (message = 'Required') =>
			value => value ? undefined : message;
			// toolbar={<CreateToolbar btnState={this.state.disableButton} />}
		return (
			<React.Fragment>
				<Create title="Result" {...rest} >
					{/* <SimpleForm  form="resultform" toolbar={<CreateToolbar handler = {(record) => updateData(record,...this.props)} />} > */}
					<SimpleForm  form="resultform" toolbar={<CreateToolbar handler = {this.updateData} />} >
						<FormDataConsumer>
							{({ formData, ...rest }) => {
								console.log("FormData",formData);
								return (
									<SanitizedGrid container >
										<Grid item >
											<SelectInput optionText="c_Name" choices={this.contractList} source="Contract"
												onClick={(e) => this.allowUpload(e)} defaultValue="" optionValue="id" 
												validate={required()}
												className={classes.margin}
											/>
										</Grid>

										<Grid item>
											<SelectInput optionText="m_Name" choices={this.getModelObjectsById(formData)} source="Model"
												onClick={(e) => this.allowUpload(e)} defaultValue="" optionValue="id"
												validate={required()}
												className={classes.margin}
											/>
										</Grid>

										<Grid item >
											<SelectInput optionText="p_Name" source="Point" choices={this.getPointObjectsById(formData)} 
												onClick={(e) => this.allowUpload(e)} defaultValue={formData.Model =="" ? '' : 'all'} optionValue="id"
												validate={required()} 
												className={classes.margin}
												/>
										</Grid>

										<Grid item >

											<TextInput
												label="Month"
												type="month"
												source="month"												
												defaultValue=""
												className={classes.margin}												
												InputLabelProps={{
													shrink: true,
												}}
												///style={styleMargin.margin}
												validate={required()}
												{...reset}
											/>
										</Grid>										
									</SanitizedGrid>
								);
							}}
						</FormDataConsumer>

					</SimpleForm>
				</Create >

				 {  this.state.isShowResult && <ResultList {...this.props}  selectionState={this.state}/> }  				 
			</React.Fragment>
		);
	}
}

Result.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default compose(
	withDataProvider,
	withStyles(margin)
)(Result);

