import {FileInput, FileField} from 'react-admin';
import React, { Component,Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title, SimpleForm } from 'react-admin';
import PropTypes from 'prop-types';






export class UploadPriceData extends Component {

   

    render(props) {
        
        const { classes, record, hasList } = this.props;
        return (
        <Card  >
         <Title title="Upload Price Data"/>
         <CardContent>
         <SimpleForm>
            <FileInput source="files"  label="Upload file (.csv)"  accepts="text/*," >
                <FileField source="src" title="title" />  
            </FileInput>
            </SimpleForm>
        </CardContent>
        
        </Card>
        );
    }
}

export default UploadPriceData;