import {
  EditController,
  EditView,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  Toolbar,
  DateTimeInput,
} from "react-admin";
import { connect } from 'react-redux';

import { EditActions } from "../Common/Actions";
import { styles, toolbarStyles } from "../Common/Styles";
import { withStyles } from "@material-ui/core";
import UpdateCrudButton from "../Common/UpdateCrud";
import DeleteWithConfirmButton from "../Common/DeleteCrud";
import {required } from  '../Common/Validator';
import SelectInputByName from "../Common/SelectInputByName";
import React, { Component, useEffect } from "react";
import Chip from "@material-ui/core/Chip";

const EditTitle = ({ record }) => {
  return <span>Edit Contract # {record ? `${record.id}` : ""}</span>;
};

const useStyles = {
  field: {
    background: "#fff",
    padding: 0,
    borderStyle: "none",
    borderWidth: 1,
    // borderBottomColor: '#603d7b',
    boxShadow: "1px 1px 1px #9E9E9E",
    width: "80%",
  },

  ul: {
    display: "table",
    listStyleType: "none",
    padding: 6,
  },
  li: {
    listStylePosition: "inside",
    color: "rgba(0 0 0 0.87)",
    background: "#E0E0E0",
    /*paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '7px',
        paddingBottom: '7px',*/
    padding: "0px 12px",
    //display: 'inline-flex',
    display: "table-cell",
    // textDecoration: 'none',
    listStyleType: "none",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    textAlign: "center",
  },

  label: {
    display: "block",
    color: "rgba(0 0 0 0.87)",
    cursor: "pointer",
    //background: 'yellow',
    padding: "6px 32px 7px 0px",
    margin: "16px 0px 8px",
    fontSize: 16,
    font: "system-ui,BlinkMacSystemFont",
    backgroundRepeat: "no-repeat",
    textDecoration: "none",
  },

  input: {
    display: "none",
  },
};

const TagsField = withStyles(useStyles)(({ classes, record }) => {  
  const [toggled, setToggled] = React.useState(false);
  useEffect(() => {
    // Your code here
  }, []);
  return record && record.tags ? (
    <div className={classes.field} record={record}>
      <input
        className={classes.input}
        type="checkbox"
        id="sites"
        onClick={(event) => {
          setToggled(!toggled);
        }}
      />
      <label className={classes.label} htmlFor="sites">
        Applied to
      </label>
      {toggled ? (
        <ul className={classes.ul}>
          {record.tags.map((item) => (            
            <Chip label={item.name} key={item} />
          ))}
        </ul>
      ) : null}
    </div>
  ) : null;
});

const TextArrayField = ({ record, source }) => {
  const array = record[source];
  if (typeof array === "undefined" || array === null || array.length === 0) {
    return <div />;
  } else {
    return (
      <>
        {array.map((item) => (
          <Chip label={item} key={item} />
        ))}
      </>
    );
  }
};
TextArrayField.defaultProps = { addLabel: true };
//TagsField.defaultProps = { addLabel: true };

/*const required = (message = "Required") => (value) =>
  value ? undefined : message;
*/


  const DateValidation = (value, allValues) => {

    if (!value) {
        return 'The date is required';
    }
    if (value < allValues.c_ValidFrom) {

        return 'Must be in future ';
    }
};

const validateDate = [required(), DateValidation];

class ContractEdit extends Component {
  constructor(props) {
    super(props);
     this.Permission= props.location.state ? props.location.state.record : {UpdateRight: "N", DeleteRight:"N"};
  
  }

  componentDidMount() {
   
  }

  render() {
    const {naviPath,  classes, permissions } = this.props;
   //debugger;
    console.log(this.Permission);
    return (
      <EditController {...this.props}>
        {(controllerProps) => {          
          return (
            <EditView
              actions={<EditActions naviPath= {naviPath}  basePath="/Contracts" />}
              title={<EditTitle />}
              {...controllerProps}
            >
              <SimpleForm
                toolbar={
                  <UpdateToolbar
                    basePath="Contracts"
                    Permission={this.Permission}
                  />
                }
              >
                <TextInput
                  label="Contract Name"
                  source="c_Name"
                  className={classes.textFld}
                  validate={required()}
                />
                <ReferenceInput
                  label="Supplier"
                  source="sp_id"
                  reference="Suppliers"
                  sort={{ field: "sp_Name", order: "ASC" }}
                  filter={{ is_all: true }}
                >
                  <SelectInput source="Suppliers" optionText="sp_Name" />
                </ReferenceInput>
                <ReferenceInput
                  label="Medium"
                  source="me_id"
                  reference="Mediums"
                  sort={{ field: "me_Name", order: "ASC" }}
                  filter={{ is_all: true }}
                >
                  <SelectInput source="Mediums" optionText="me_Name" />
                </ReferenceInput>
                <DateTimeInput label="Valid From" source="c_ValidFrom" validate={validateDate} />
                <DateTimeInput label="Valid To" source="c_ValidTo" validate={validateDate} />
                <TagsField label="Applies to" record={controllerProps.record} />
              </SimpleForm>
            </EditView>
          );
        }}
      </EditController>
    );
  }
}
//Update and Delete Button are disabled depending on Role and special Contract access rights
const UpdateToolbar = withStyles(toolbarStyles)(({Permission,...props}) => (
  <Toolbar {...props}>
    <UpdateCrudButton
      label="Save"
      redirect="list"
      basePath="/Contracts"
      submitOnEnter={false}
      variant="flat"
      color="primary"
       disabled={Permission.UpdateRight==="N"}
    />
    <DeleteWithConfirmButton
      label="DeleteFromAction"
      redirect="list"
      basePath="/Contracts/:id"
      submitOnEnter={false}
      variant="flat"
      color="primary"
      resource="Contracts"
      undoable={false}
      record={Permission.DeleteRight==="N"}
    />
  </Toolbar>
));




const mapStateToProps = state => {
  return {
      naviPath:{
          contract: state.selectionState.navItem.contract,
          contractName: state.selectionState.navItem.contract.value.c_Name,
     },
     contractPermissions: {
      UpdateRight: state.selectionState.navItem.contract.value.UpdateRight,
      DeleteRight: state.selectionState.navItem.contract.value.DeleteRight
     },
      isLoggedIn: state.admin.auth.isLoggedIn,
  }
}
const EditContract2Redux=connect(
  mapStateToProps,
  null
)
(ContractEdit);

export default withStyles(styles)(EditContract2Redux);
