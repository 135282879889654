import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Visibility } from '@material-ui/icons';
import { Button,showNotification } from 'react-admin';
import { connect } from 'react-redux';


// Define path for Edit button
const Epath = {
    Contracts: "/Contracts",
    Models: "/Models",
    Points: "/Points",
    InvoiceChecks: "/InvoiceChecks",
    InvoiceCheckItems: "/InvoiceCheckItems",
}

 class ShowButton extends Component {
    constructor(props) {
        super(props);
        

    }
    

    handleShowClick = (e) => {
        const { showNotification, record, storeSelection, dispatch } = this.props;
        if(this.props.basePath == Epath.InvoiceChecks) 
            storeSelection("invoiceCheck",record)
        else if(this.props.basePath == Epath.InvoiceCheckItems)
            storeSelection("invoiceCheckItems",record)
        else if(this.props.basePath == Epath.Points) 
            storeSelection("point",record)

        else if (this.props.basePath == Epath.Contracts) {
                storeSelection("contract",record)       
            }
        else if (this.props.basePath == Epath.Models) {
                storeSelection("model",record)       
            }
    
        
    }


    render() {

        
        var to = {};
        // Define route (pathname and state) based on basePath
        if (this.props.basePath == Epath.Points) {
            to = {
                pathname: `${this.props.basePath}/${this.props.record.id}/show`,
                state: {
                    record: {
                        pointId: this.props.record.PointTypeId,
                        pointName:this.props.record.Name,
                        FormulaEditorData: this.props.record.FormulaEditorData,
                        UpdateRight: this.props.record.UpdateRight,
                        DeleteRight: this.props.record.DeleteRight,
                    }
                },
            };
        
        }
        else if (this.props.basePath == Epath.InvoiceChecks) {
            to = {
                pathname: `${this.props.basePath}/${this.props.record.id}/show`,
                state: {
                    record: {
                        contractId: this.props.record.ic_c_id,
                        UpdateRight: this.props.record.UpdateRight,
                        DeleteRight: this.props.record.DeleteRight,
                    }
                },
            };
        }
        else if (this.props.basePath == Epath.InvoiceCheckItems) {
            //debugger;
            to = {
                pathname: `${this.props.basePath}/${this.props.record.id}/show`,
                state: {
                    record: {
                        invoiceCheckId: this.props.record.ici_ic_id,
                        ic_m_id_1: this.props.record.ic_m_id_1,
                        ic_m_id_2: this.props.record.ic_m_id_2,
                        UpdateRight: this.props.record.UpdateRight,
                        DeleteRight: this.props.record.DeleteRight,
                    }
                },
            };

        }
        else {
            to = {
                pathname: `${this.props.basePath}/${this.props.record.id}/show`,
                state: {
                    record: {
                        UpdateRight: this.props.record.UpdateRight,
                        DeleteRight: this.props.record.DeleteRight,
                    }
                },
            };

        }
        
        return (
            <Button
                disabled={this.disable = (this.props.record.ViewRight === 'Y') ? false : true}
                onClick={this.handleShowClick}
                label=""
                component={Link}

                to={to}
                color="primary"
            >
                <Visibility />
            </Button>
        );
    }
}

// {/* <ConsoleLog>"Edit Button Prop: {this.props}"</ConsoleLog> */}
ShowButton.defaultProps = {
    undoable: false,
};

const mapDispatch = (dispatch) => {
    return {
        storeSelection: (typeName, record) => record ? dispatch({ type: "ADD_SELECTION", payload: { type: typeName, value: record } }) : null,
        showNotification,
      
    }
  }


export default connect(null,  mapDispatch)(ShowButton);

