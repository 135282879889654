import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListActions from '../../Common/Actions';
import EditButton from '../../Common/EditButton';
import { PostPagination } from '../../Common/Pagination';
import CheckPagePermission from "../../Common/CheckPagePermissionJs";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { isEqual } from 'lodash';
import { tableMuiTheme } from "../../Common/Styles";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const UnitList = ({permissions,...props}) =>
<CheckPagePermission
    authParams={{ Admin: false }}
    location={window.location}
    match="Units"
    render={({ pagePermissions }) =>
    <div>
      {
      pagePermissions ?
      (pagePermissions.View ==='Y') ?   
            <MuiThemeProvider theme={tableMuiTheme}>
                <List actions={<ListActions permissions {...props} />}
                    pagination={<PostPagination />}
                    sort={{ field: 'un_id', order: 'DESC' }}
                    title="Units"
                    {...props}
                    bulkActionButtons={false}
                >
                    <Datagrid rowClick="show"  >
                        <TextField source="id" label="Id" />
                        <TextField source="un_Name" label="Name" />
                        <TextField source="un_IsCurrency" label="IsCurrency" />
                        <EditButton label="Edit" resource="/Units/:id" basePath={props.basePath} />
                    </Datagrid>
                </List>
            </MuiThemeProvider> : null : null
    } </div>
}/>