import React from 'react';
import { Query,  LinearProgress,CheckBox,CheckboxGroupInput,SelectInput } from 'react-admin';


const required = (message = 'Required') =>
            value => value ? undefined : message;

const CheckboxGroupPA = ({ record,onChange,...props }) => (
    <Query type="GET_ONE" resource="Admin/PageAccess" payload={{ id: "create" }}>
        {({ data, loading, error }) => {
            if (loading) { return <  LinearProgress />; }
            if (error) { console.log("Query failed:",error) ;return <p>Error</p>; }
            if (data == null)   return null;
           
            return <div>
                  <SelectInput label ="Role" source ="Roles_id" choices={data.Roles} optionText="Name" optionValue="id" validate={required()}  onChange={onChange} />
                    <CheckboxGroupInput source="Permissions" choices={data.PagePermission}  
                    optionText="Name" 
                    optionValue="id" 
                    onChange={onChange}
                    />
                </div>;  
        }}
    </Query>
);

export default React.memo(CheckboxGroupPA)