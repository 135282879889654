import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'ra-core';
import { CREATE } from 'ra-core';
import dataProvider from '../dataProvider/rest';
import FunctionsIcon from '@material-ui/icons/Functions';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import compose from 'recompose/compose';

const styles = ({ spacing }) =>
    createStyles({
        button: {
            position: 'relative',
        },
        leftIcon: {
            marginRight: spacing.unit,
        },
        icon: {
            fontSize: 18,
        },
    });

const sanitizeRestProps = ({
    basePath,
    invalid,
    pristine,
    reset,
    saving,
    submitOnEnter,
    handleSubmit,
    handleSubmitWithRedirect,
    undoable,
    dispatch,
    ...rest
}) => rest

class CalculateButton extends Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = (values) => {
        const { showNotification, reset } = this.props;
        //const { change, fetchStart, fetchEnd, showNotification, refreshView , resetForm, reset, ...rest} = this.props;    

        //showNotification("Processing","info" );     
        dataProvider(CREATE, "Calculation", { data: values })
            .then((response) => {
                //console.log(response);
                // const { json } = response;
                // var re = { data: { ...params.data, id: json.id } };
                //console.log("Response " + response);
                //showNotification('Calcualtion successfully completed','info');
                showNotification("Please check calculation status", "info", { autoHideDuration: 10000 });

                //debugger;
                // set form data to parent component
                //this.props.handler(values);             
                this.callBackHandleSubmit(values);
            })
            .catch((e) => {
                showNotification("Error", "warning")
            })
            .finally(() => {

            });
    };   

    callBackHandleSubmit = (values) => {
        //debugger;
        this.props.handler(values); 
    };

    render() {

        const {
            className,
            classes = {},
            dispatch,
            showNotification,            
            ...rest
        } = this.props;

        return (
            <Button className={classnames(classes.button, className)} type='submit' form="frmCalculation"
                label="Calculation"
                variant="flat" color="primary"
                onClick={this.props.handleSubmit((data) => { this.handleSubmit(data) })}        
                {...sanitizeRestProps(rest)}
            >
                <FunctionsIcon className={classnames(classes.leftIcon, classes.icon)} />
                Calculation
            </Button>
        );
    }
}

CalculateButton.propTypes = {
    //push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
    //reset: PropTypes.func.isRequired,    
};

const enhance = compose(
    connect(null, {
        showNotification: showNotification,
        //push,
        // reset: dispatch(reset('frmCalculation')),
        //refreshView: refreshViewAction,
    }),
    withStyles(styles)
);

export default enhance(CalculateButton);