import { CountryList } from './CountryList';
import { CountryShow } from './CountryShow';
import CountryCreate from './CountryCreate';
import CountryEdit from './CountryEdit';

export default {
    list: CountryList,
    create: CountryCreate,
    edit: CountryEdit,
    show: CountryShow
};