

import { Divider } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

export const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'left',
    width:'100%',
    naviBlock:{
        display: 'inline',
        //color: 'DodgerBlue',
        fontFamily: 'Arial'
},
    actionBlock:{float: 'right'}
};

export const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export const styles = theme =>  ({    
        textFld: {
            //fontStyle: 'italic',
            width: '80%'
        },
    
        inputFld: {
            fontStyle: 'italic',
            //fontFamily: 'Arial',
            color: 'Lavender',
            fontWeight: 500,
            fontFamily: 'sans-serif',
        },      
});

export const margin = theme => createStyles({
    margin: {
        marginLeft: '20px',
    },

    textFld: {
        //fontStyle: 'italic',
        width: '20%',
        marginLeft: '20px',
    },

});

export const tableMuiTheme = createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 12,
          paddingRight: 12,
          "&:first-child": {
            paddingRight: 24,
            paddingLeft: 24,
            },
           "&:last-child": {
            paddingRight: 12,
            paddingLeft: 0,
        }
        
         
        }
      }
    },
  });


  export const subTableMuiTheme = createMuiTheme({
    overrides: {
        MuiTableRow: {
            head: {
              backgroundColor: 'lightgray',
              "& > th ": {
                color: 'black',
                fontWeight: 'bold',
              }
            },
          },
      MuiTableCell: {
        root: {
          backgroundColor: 'lightgray', // '#e0e0e0',
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 12,
          paddingRight: 12,
          "&:first-child": {
            paddingRight: 24,
            paddingLeft: 24,
            },
           "&:last-child": {
        paddingRight: 12,
        paddingLeft: 0,
        "&$selected": {
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "orange",
            },
          },

        }
        
         
        }
      }
    },
    palette: {
        primary: {
          main: purple[500],
        },
        secondary: {
          main: green[500],
        },
      },

  });

 
 