import React from 'react';
import {
    Create, SimpleForm, TextInput
} from 'react-admin';
import { CreateActions } from '../../Common/Actions';
import CreateToolbar from '../../Common/CreateToolbar';
import { withStyles } from '@material-ui/core';
import { styles } from '../../Common/Styles';

const QualityCreate = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    const maxValue = (max, message = `Max value is ${max}`) =>
        value => value && value > max ? message : undefined;

    const number = (message = 'Must be a number') =>
        value => value && isNaN(Number(value)) ? message : undefined;

    const validation = [required(), number(), maxValue(255)];

    return (
        <Create actions={<CreateActions />} {...props} >
            <SimpleForm toolbar={<CreateToolbar {...props} />} redirect="show">
                <TextInput label="Quality Name" source="q_Name" validate={required()} className={classes.textFld} />
                <TextInput label="Quality Description" source="q_Description"
                    validate={required()} className={classes.textFld} />
                <TextInput label="Quality ColorRedByte" source="q_ColorRedByte"
                    validate={validation} className={classes.textFld} />
                <TextInput label="Quality ColorBlueByte" source="q_ColorBlueByte"
                    validate={validation} className={classes.textFld} />
                <TextInput label="Quality ColorGreenByte" source="q_ColorGreenByte"
                    validate={validation} className={classes.textFld} />
            </SimpleForm>
        </Create>
    );
};

export default withStyles(styles)(QualityCreate);