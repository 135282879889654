import React from 'react';
import { Datagrid, TextField, List,NumberField } from 'react-admin';
import { PostPagination } from '../Common/Pagination';
import { MuiThemeProvider } from '@material-ui/core';
import { tableMuiTheme,subTableMuiTheme } from "../Common/Styles";
import NavigationButton from '../Common/NavigationButton';
import { AccessDenied } from '../Common/NoAccess';
import ApprovalListActions from './ApprovalAction';
import ApprovalDataList from './ApprovalData/ApprovalDataList';
import ApproveRevoke from './ApproveRevoke';
import ButtonWithHandleSubmit from '../Common/ButtonWithHandleSubmit';
import CheckPagePermission from "../Common/CheckPagePermissionJs";

import { Route } from 'react-router';
import { Drawer } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from "react-router-redux";





const ShowData = ({ permissions,hasCreate,hasList, resource,...props }) => {

  
  const newProps={
    ...props,
  hasCreate: false,
  hasList:true,
  resource:"ApprovalData",
  title:" ",
  pagination: null,
  bulkActionButtons:false,
  exporter: false,
  basePath: props.basePath,
  actions: null
  };

 //console.log("showData",newProps);
  return (
    <MuiThemeProvider theme={subTableMuiTheme} >
      <List
      
        {...newProps}
        sort={{ field: 'ici_Order', order: 'DESC' }}
        filter={{ic_id: props.record.ic_id, StartDateTime: props.record.a_StartDateTime, EndDateTime: props.record.a_EndDateTime,  }}
      >
        <Datagrid>
          <TextField label="Model1" source="ModelName1" />
          <TextField label="Point1" source="PointName1" />
          <NumberField label="Value1" source="Value1" options={{ maximumFractionDigits: 6 }} />
          <TextField label="Model2" source="ModelName2" />
          <TextField label="Point2" source="PointName2" />
          <NumberField label="Value2" source="Value2" options={{ maximumFractionDigits: 6 }} />
          <NumberField label="Deviation in %" source="DeviationPercent" options={{ maximumFractionDigits: 6 }} />
          <NumberField label="Deviation Absolut" source="DeviationAbsolut" options={{ maximumFractionDigits: 6 }} />
        </Datagrid>
      </List>
    </MuiThemeProvider>
  );
}

const ApprovalList = ({ prevAudit,pagePermissions, dispatch, dataProvider, approvalSelection, ...props }) => {

  //debugger;
 
  return (
    <CheckPagePermission
      authParams={{ Admin: false }}
      location={window.location}
      match="Approvals"
      render={({ pagePermissions }) =>
        <React.Fragment>
          {pagePermissions && pagePermissions.View === "Y" ?
            <MuiThemeProvider theme={tableMuiTheme} >
              <List
                {...props}
                exporter={false}
                title=" "
                pagination={<PostPagination />}
                actions={<ApprovalListActions />}
                sort={{ field: 'ic_id', order: 'ASC' }}
                filter={{ contractId: approvalSelection.Contract, month: approvalSelection.month }}
                bulkActionButtons={false}
              >
                <Datagrid expand={<ShowData {...props}/>}>
                  <TextField label="Contract" source="c_Name" />
                  <TextField label="Invoice Check" source="ic_Name" />
                  <TextField label="Start" source="a_StartDateTime" />
                  <TextField label="End" source="a_EndDateTime" />
                  <TextField label="Approval" source="a_Approved" />
                  <TextField label="Approved By" source="ApprovedBy" />
                  <TextField label="Approval Date" source="a_ApprovalDate" />
                  {//<NavigationButton label="Show" resource="Approvals" path="ApprovalData" basePath={props.basePath} record={record} />
                  }
                  <ApproveRevoke resource="Approvals" path="Approvals/SetApproval" basePath={props.basePath}
                    label="Set Approval" record={props.record} />
                  <ApproveRevoke resource="Approvals" path="Approvals/RevokeApproval" basePath={props.basePath}
                    label="Revoke Approval" record={props.record} />
                  <NavigationButton label="Audit" resource="Approvals" path="ApprovalAudit" basePath={props.basePath} record={approvalSelection} />
                </Datagrid>
              </List> 
                
            </MuiThemeProvider>
            : <AccessDenied />
          }
        </React.Fragment>}
    />
  );
}

//export default ApprovalList;
export default connect(undefined, push )(ApprovalList);