import React, { Component } from 'react';
import { combineReducers } from 'redux';
import { Admin, Resource,ListGuesser,EditGuesser,ShowGuesser} from 'react-admin';
//import polyglotI18nProvider from 'ra-i18n-polyglot'; // install the package
//import englishMessages from 'ra-language-english'; // install the package


import Contract from './components/Contract';
import Model from './components/Model';
import Point from './components/Point';
import Calculation from './components/Calculation';
import Result from './components/Result';
import Approval from './components/Approval';
import ApprovalData from './components/Approval/ApprovalData';
import ApprovalAudit from './components/Approval/ApprovalAudit';
import CommentEval from './components/Approval/CommentEval';
import InvoiceCheck from './components/InvoiceCheck';
import InvoiceCheckItem from './components/InvoiceCheckItem';
import Unit from './components/Admin/Unit';
import Frequency from './components/Admin/Frequency';
import Category from './components/Admin/Category';
import Medium from './components/Admin/Medium';
import Region from './components/Admin/Region';
import Country from './components/Admin/Country';
import Supplier from './components/Admin/Supplier';
import Quality from './components/Admin/Quality';
import PublicPointUpload from './components/Admin/PublicPointUpload/publicPointUpload';

import 'typeface-roboto';
import { createMuiTheme } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import Dashboard from './components/dashboard/Dashboard';
import 'babel-polyfill';
import { Layout } from './components/layout/';
import dataProviderFactory from './components/dataProvider/dataProviderFactory';

import UploadMeterData2 from './components/Upload/uploadMeterData2';
import UploadPriceData from './components/Upload/uploadPriceData';
import FileUpload from './components/FileUpload/fileUpload';
import addUploadFeature from './components/Upload/addUploadFeature';
import uploadReducer from './components/Upload/UploadReducer';
import selectionReducer from './components/Common/SelectionReducer';
import permissionReducer from './components/Common/PermissionReducer';
import permissionSaga from './components/Common/PermissionsSaga';

import FormulaEditor from './components/FormulaEditor/FormulaEditor';

import {AuthProvider} from './components/AuthProvider/authProvider';
import myLoginPage from './components/loginPage';
import NotFound  from "./components/Common/NotFound";

import User from './components/Admin/Users';
import PageAccess from './components/Admin/PageAccess';
import DomainAccess from './components/Admin/DomainAccess';
import CopyContract from './components/Contract/CopyContract';
import CopyModel from './components/Model/CopyModel';



const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: pink,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    overrides: {
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: 'red', // Some CSS
            },
        },
    },
});

const AppTitle = () => (
    <img src='./components/layout/linde.png' />
);

export default class App extends Component {
    static displayName = App.name;
    state = { dataProvider: null };

    async componentWillMount() {

        const dataProvider = await dataProviderFactory(
            'rest'
        );

        this.setState({ dataProvider });
    }

    render() {
        
        const { dataProvider } = this.state;
        if (!dataProvider) {
            return (
                <div className="loader-container">
                    <div className="loader">Loading...</div>
                </div>
            );
        }

        const uploadCapableDataProvider = addUploadFeature(dataProvider);
      //  const reducer =combineReducers({uploadState: uploadReducer, selectionState: selectionReducer, permissionState: permissionReducer});
        //const i18nProvider = polyglotI18nProvider(locale => i18nMessages[locale], 'en', { allowMissing: true });
       // const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', { allowMissing: true });
        return (
            <Admin 
            title={<AppTitle />}  
           // customReducers={reducer} 
            customReducers={{uploadState: uploadReducer, selectionState: selectionReducer, permissionState: permissionReducer}} 
            customSagas={permissionSaga }
            dataProvider={uploadCapableDataProvider} 
            theme={theme} 
            dashboard={Dashboard} 
            appLayout={Layout} 
            authProvider={AuthProvider}  
            //i18nProvider={i18nProvider}
            loginPage={myLoginPage}
            catchAll={NotFound}
            >
               
              
                <Resource name="Contracts" {...Contract} />
                <Resource name="CopyContract" {...CopyContract} />
                <Resource name="Models" {...Model} />
                <Resource name="/Models/:id" />
                <Resource name="CopyModel" {...CopyModel} />
                <Resource name="Points" {...Point} />
                <Resource name="/Points/:id" />
                <Resource name="/Points/All" />
                <Resource name="ContractNames" />

               
		            <Resource name="MeterData/ContractNames"  />
		            <Resource name="MeterData/ModelNames" />
                    <Resource name="MeterData/PointNames" />
                    <Resource name="MeterData" list={UploadMeterData2} />

                    <Resource name="FileUpload" list={FileUpload} />
                   
              

              { /* <Resource name="Supplier" />
                <Resource name="Medium" />
                <Resource name="Country" />
        <Resource name="Region" />*/}
                <Resource name="Suppliers" {...Supplier} />
                <Resource name="Mediums" {...Medium} />
                <Resource name="Countries" {...Country} />
                <Resource name="Regions" {...Region} />
                <Resource name="Sites" />                
                
                <Resource name="PriceData" list={UploadPriceData} />
                <Resource name="Formula" list={FormulaEditor} />
                <Resource name="PointType" />
               
                <Resource name="Categories" {...Category} />
                <Resource name="Frequencies" {...Frequency} />
                <Resource name="MeterData" list={UploadMeterData2} />
               {//<Resource name="Category" />
                //<Resource name="Frequency" />
               // <Resource name="Unit" />
            }
                <Resource name="Units" {...Unit} />
                <Resource name="AggregateFunction" />
                <Resource name="AggregateParameter" />
                <Resource name="Block" />
              
                <Resource name="Calculation" {...Calculation} />
                <Resource name="Result" {...Result} />
                <Resource name="Approvals" {...Approval} />
                <Resource name="ApprovalData" {...ApprovalData} />
                <Resource name="ApprovalAudit" {...ApprovalAudit} />
                <Resource name="Approvals/Comment"  create={CommentEval}/>
                <Resource name="TimeZone" />

                <Resource name="Admin/PageAccess" {...PageAccess}/>
                <Resource name="Admin/DomainAccess" {...DomainAccess}/>
                <Resource name="Admin/PageAccess/create" />
                <Resource name="Admin/Users" {...User}/>
                <Resource name="Admin/Categories" {...Category}/>
                <Resource name="Admin/Mediums" {...Medium} />
                <Resource name="Admin/Units" {...Unit} />
                <Resource name="Admin/Frequencies" {...Frequency} />
                <Resource name="Admin/Regions" {...Region} />
                <Resource name="Admin/Countries" {...Country} />
                <Resource name="Admin/Suppliers" {...Supplier} />
                <Resource name="Admin/Qualities" {...Quality} />
                <Resource name="Admin/PublicPointUpload" list={PublicPointUpload}/>
                <Resource name="InvoiceChecks" {...InvoiceCheck}/>
                <Resource name="InvoiceCheckItems" {...InvoiceCheckItem}/>
                <Resource name="Qualities" {...Quality} />
                <Resource name="Roles" />
                <Resource name="Roles/RoleNames" />
             
             
            </Admin >
        );
    }
}
