import React, { useState } from 'react';
import keyBy from 'lodash/keyBy';
import { Datagrid, NumberField, TextField, Pagination, Loading, List } from 'react-admin';
import { ResultListActions } from './ResultActions';
import { PostPagination } from '../Common/Pagination';
import { MuiThemeProvider } from '@material-ui/core';
import { tableMuiTheme } from "../Common/Styles";
import CheckPagePermission from "../Common/CheckPagePermissionJs";
import { AccessDenied } from "../Common/NoAccess";
const ResultList = ({dataProvider, dispatch,selectionState, ...rest }) => {

  //debugger;

  //set pagination
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(5)

  return (
    // <Query      
    //   type="GET_LIST"
    //   resource="Result"
    //   payload={{
    //     pagination: { page, perPage: perPage },
    //     sort: { field: 'd_id', order: 'ASC' },
    //     filter: { contractId: record.Contract, modelId: record.Model, pointId: record.Point, month: record.month }
    //   }}
    // >
    //   {({ data, total, loading, error }) => {
    //     if (loading) {
    //       return <Loading />
    //     }
    //     if (error) {
    //       return <p>ERROR: {error}</p>
    //     }
    //     return (
    //       <React.Fragment>                     
    //         <Datagrid
    //           data={keyBy(data, 'id')}
    //          ids={data.map(({ id }) => id)}
    //          currentSort={{ field: 'id', order: 'ASC' }}                
    //         >
    //           <TextField source="id" />
    //           <TextField label="Point" source="p_Name" />
    //           <TextField label="Start" source="d_StartDateTime" />
    //           <TextField label="End" source="d_EndDateTime" />
    //           <TextField label="Value" source="d_Value" />
    //           <TextField label="Quantity" source="q_Name" />
    //           <TextField label="Creation User" source="d_CreationUser" />
    //           <TextField label="Creation Timestamp" source="d_CreationTimeStamp" />
    //         </Datagrid>           
    //         <Pagination
    //           page={page}
    //           perPage={perPage}
    //           setPage={setPage}
    //           setPerPage = {setPerPage}
    //           total={total}              
    //         />
    //       </React.Fragment>
    //     )
    //   }}
    // </Query>
    <CheckPagePermission
    authParams={{ Admin: false }}
    location={window.location}
    match="Result"
    render={({ pagePermissions }) =>
      <div>
        {pagePermissions && pagePermissions.View === "Y" ? (
    <MuiThemeProvider theme={tableMuiTheme} >
      <List
        {...rest}
        exporter={false}
        title=" "
        pagination={<PostPagination />}
        resource="Result"
        sort={{ field: 'd_id', order: 'ASC' }}
        filter={{ contractId: selectionState.Contract.id, modelId: selectionState.Model.id, pointId: selectionState.Point.id, month: selectionState.Month }}
        actions={<ResultListActions selectionState={selectionState} />}
        bulkActionButtons={false}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField label="Point" source="p_Name" />
          <TextField label="Start" source="StartDateTimeLocal" />
          <TextField label="End" source="EndDateTimeLocal" />
          <NumberField label="Value" source="d_Value" options={{ maximumFractionDigits: 6 }} />
          <TextField label="Quality" source="q_Name" />
          <TextField label="Creation User" source="d_CreationUser" />
          <TextField label="Creation Timestamp" source="d_CreationTimeStamp" />
        </Datagrid>
      </List>
    </MuiThemeProvider>)
                    :
                    <AccessDenied />}
            </div>


        }
    />
  );
}

export default ResultList;
