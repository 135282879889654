import React from 'react';
import {
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, Toolbar
} from 'react-admin';

import UpdateCrudButton from '../../Common/UpdateCrud';
import DeleteWithConfirmButton from '../../Common/DeleteCrud';
import { withStyles } from '@material-ui/core';
import { styles, toolbarStyles } from '../../Common/Styles';
import { EditActions } from '../../Common/Actions';

const CountryEdit = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    const maxLength = (max, message = `Max length is ${max}`) =>
        value => value && (value.length < max || value.length > max) ? message : undefined;

    return (
        <Edit actions={<EditActions />} {...props} >
            <SimpleForm toolbar={<UpdateToolbar basePath="/Countries" />}>
                <TextInput label="Country Name" source="co_Name" validate={required()} className={classes.textFld} />
                <TextInput label="Country Alpha 2" source="co_Alpha_2" validate={required(), maxLength(2)} className={classes.textFld} />
                <TextInput label="Country Alpha 3" source="co_Alpha_3" validate={required(), maxLength(3)} className={classes.textFld} />
                <ReferenceInput label="Region" source="co_r_id" reference="Regions" resource="Regions"
                    filter={{ is_all: true }}
                    resettable validate={required()}  >
                    <SelectInput optionText="r_Name" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export default withStyles(styles)(CountryEdit);

const UpdateToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath="/Countries"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            disabled={false}
        />
        <DeleteWithConfirmButton
            label="Delete"
            redirect="list"
            basePath="/Countries/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="Countries"
            undoable={false}
            disabled={false}
        />
    </Toolbar>
));
