import React from 'react';
import FlatButton from '@material-ui/core/Button';
import { Mutation, Button } from 'react-admin';
import { connect } from 'react-redux'



export const UPLOAD_COMMIT_DONE = 'UPLOAD_COMMIT_DONE';


const StageCommitButton = (props) => {
 
    const payload = { queryby: 'commit', query: {id: props.userId ,p_id: props.formState.Point}, data: { ...props.record, is_approved: true } };



const options = {
    undoable: false,
    onSuccess: {
        notification: { body: 'ra.notification.updated', level: 'info', messageArgs: {
            smart_count: 1,
        }, },    
        redirectTo: 'MeterData',
        callback: ({ payload, requestPayload }) => { 
            //dispatch  change to state.staged=false
            props.dispatch(
                {type: UPLOAD_COMMIT_DONE}
            );
        } 
    },
    onFailure: {
        notification: { body: 'Error: commit failed', level: 'warning' }
    }
};

    
    return (
        <Mutation
            type="UPLOAD"
            resource="MeterData"
            payload={payload}
            options={options}
        >
            {commit => (
                <FlatButton label={props.label} onClick={commit} disabled={ props.loading == 1 || !props.staged} >
                 Commit & Delete Stage
                 </FlatButton>
            )}
        </Mutation>
    );
};

function mapStateToProps(state) {
    return {
      loading: state.admin.loading,
      staged: state.uploadState.staged
    }
  }

export default connect(mapStateToProps, null)(StageCommitButton);