import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { withDataProvider, Create, TextInput, crudGetAll, FormDataConsumer, SelectInput, SimpleForm, Toolbar } from 'react-admin';
import PropTypes from 'prop-types';
import { reset } from "redux-form";
import { withStyles } from '@material-ui/core';
import { margin } from '../Common/Styles';
import compose from 'recompose/compose';
import CalculateButton from './CalculateButton';
import CalculationStatusList from './CalculationStatusList';
import classnames from 'classnames';

const toolbarStyles = {
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	}
}

const SanitizedGrid = ({ basePath, ...props }) => {
	return (
		<Grid {...props} />
	);
};

const CreateToolbar = withStyles(toolbarStyles)(({ handler, ...props }) => {

	////debugger;
	//console.log(props);

	return (
		<Toolbar {...props}>
			{/* {console.log(props)} */}
			<CalculateButton handler={handler} />
		</Toolbar>
	)
});

class CalculationCreate extends Component {

	constructor(props) {
		super(props);
		this.state = {
			Contract: undefined,
			Model: undefined,
			//Point: undefined,
			isShowCalculationStatus: true,

		}
	}

	allowUpload = (e) => {
		var obj = new Object();
		//console.log(e);
		//debugger;
		obj[e.target.name] = e.target.value;

		if (e.target.name === "Contract") {
			obj["Model"] = "";
			//obj["Point"] = "";
		}
		this.setState(obj);
	}

	componentDidMount() {

		this.fetchData();
	}

	fetchData = () => {
		const { dispatch } = this.props;

		dispatch(
			crudGetAll(
				'Calculation/Details', 'DESC', {isContract: true, isModel: true, isPoint: false, pa_Name:"Calculation"}, 25,
				({ payload, requestPayload, error }) => {
					//console.log(payload.data);
					this.contractList = payload.data[0].contract;
					this.modelList = payload.data[0].model;
					//this.pointList = payload.data[0].point;

					//console.log(this.contractList);
					//console.log(this.modelList);
					//console.log(this.pointList);

					this.setState({
						contractChanged: true,
						modelChanged: true,
						//pointChanged: true
					});
				})
		);

	};


	getModelObjectsById = (value) => {
		//console.log(value);
		//console.log(this.modelList);
		var reducedArray = [];
		for (let i = 0; i < this.modelList.length; i++) {
			if (this.modelList[i].c_id == value.Contract) {
				reducedArray.push({ id: this.modelList[i].id, m_Name: this.modelList[i].m_Name });
			}

		}
		return reducedArray;
	};

	updateData = (values) => {
		//debugger;
		//console.log(values);
		this.setState({
			isShowCalculationStatus: true,
			formData: values
		});
	};

	render() {

		if (!this.contractList || !this.modelList) return null;

		const {
			classes = {},
			className,
			dataProvider,
			dispatch,
			...rest
		} = this.props;

		const required = (message = 'Required') =>
			value => value ? undefined : message;
		//toolbar={<CreateToolbar handler = {(record) => updateData(record,...this.props)}
		return (
			<React.Fragment>
				<Create title="Calculation" {...rest}>
					<SimpleForm form="frmCalculation" toolbar={<CreateToolbar handler={this.updateData} />} >
						<FormDataConsumer>
							{({ formData, ...rest }) => {
								return (
									<SanitizedGrid container >
										<Grid item >
											<SelectInput optionText="c_Name" choices={this.contractList} source="Contract"
												onClick={(e) => this.allowUpload(e)} defaultValue="" optionValue="id"
												validate={required()}
												className={classes.margin}
											/>
										</Grid>

										<Grid item>
											<SelectInput optionText="m_Name" choices={this.getModelObjectsById(formData)} source="Model"
												onClick={(e) => this.allowUpload(e)} defaultValue="" optionValue="id"
												validate={required()}
												className={classes.margin}
											/>
										</Grid>

										<Grid item >

											<TextInput
												label="Month"
												type="month"
												source="month"
												defaultValue=""
												className={classes.margin}
												InputLabelProps={{
													shrink: true,
												}}
												///style={styleMargin.margin}
												validate={required()}
												{...reset}
											/>
										</Grid>
									</SanitizedGrid>
								);
							}}
						</FormDataConsumer>

					</SimpleForm>
				</Create >

				{this.state.isShowCalculationStatus && <CalculationStatusList {...this.props} record={this.state.formData} />}

			</React.Fragment>
		);
	}
}

CalculationCreate.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

const enhance = compose(
	withDataProvider,
	withStyles(margin)
);

export default enhance(CalculationCreate);

