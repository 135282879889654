import React from 'react';
import { EditController, EditView, SimpleForm, TextInput, ReferenceInput, SelectInput, Toolbar } from 'react-admin';
import { styles, toolbarStyles } from '../Common/Styles';
import { withStyles } from '@material-ui/core';
import UpdateCrudButton from '../Common/UpdateCrud';
import DeleteWithConfirmButton from '../Common/DeleteCrud';
import ConsoleLog from '../Common/ConsoleLog';
import { reset } from 'redux-form';
import { InvoiceCheckEditActions } from './InvoiceCheckActions';
import { connect } from 'react-redux';
import {required } from  '../Common/Validator';

const EditTitle = ({ record }) => {
    return <span>Edit Invoice Check #  {record ? `${record.id}` : ''}</span>;
};


const InvoiceCheckEdit = ({naviPath,...props}) => {

    //debugger;
    //console.log(props);
    const { classes } = props;
    

    const Permission = props.location ? props.location.state ? props.location.state.record :
        {
            UpdateRight: "N",
            DeleteRight: "N"
        } : {
            UpdateRight: "N",
            DeleteRight: "N"
        };

    return (
        <EditController   {...props}>
            {controllerProps =>
                <EditView actions={<InvoiceCheckEditActions
                    record={naviPath} />}
                    title={<EditTitle />} 
                    permission = {Permission} {...props} {...controllerProps} >
                    <SimpleForm toolbar={<UpdateToolbar basePath={controllerProps.basePath}  permission = {Permission} />} >
                        <ConsoleLog>$"Controller ${controllerProps}"</ConsoleLog>
                        <TextInput label="Name" source="ic_Name" validate={required()} className={classes.textFld} />
                        <ReferenceInput label="Model1" source="ic_m_id_1" reference="Models" resource="Models"
                            filter={{
                                contractId: controllerProps.record ? controllerProps.record.ic_c_id : undefined,
                            }}
                            sort={{ field: 'Name', order: 'ASC' }}
                            resettable validate={required()}  >
                            <SelectInput optionText="Name" />
                        </ReferenceInput>
                        <ReferenceInput label="Model2" source="ic_m_id_2" reference="Models" resource="Models"
                            filter={{
                                contractId: controllerProps.record ? controllerProps.record.ic_c_id : undefined,
                            }}
                            sort={{ field: 'Name', order: 'ASC' }}
                            resettable validate={required()}  >
                            <SelectInput optionText="Name" />
                        </ReferenceInput>
                        <ReferenceInput label="Frequency" source="ic_fq_id" reference="Frequencies"
                            filter={{ is_all: true }}>
                            <SelectInput source="Frequencies" optionText="fq_Name" />
                        </ReferenceInput>
                        {/* <ReferenceInput label="Role" source="ic_ro_id" reference="Roles">
                            <SelectInput source="Roles" optionText="ro_Name" />
                        </ReferenceInput> */}
                        <TextInput
                            label="Valid From"
                            type="month"
                            source="ic_ValidFrom"
                            defaultValue=""
                            className={classes.margin}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            ///style={styleMargin.margin}
                            validate={required()}
                            {...reset}
                        />
                        <TextInput
                            label="Valid To"
                            type="month"
                            source="ic_ValidTo"
                            defaultValue=""
                            className={classes.margin}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            ///style={styleMargin.margin}
                            validate={required()}
                            {...reset}
                        />
                    </SimpleForm>
                </EditView>
            }
        </EditController >
    )
};

const UpdateToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <UpdateCrudButton
            label="Save"
            redirect="list"
            basePath={props.basePath}
            submitOnEnter={false}
            variant="flat"
            color="primary"
            disabled={props.permission ? props.permission.UpdateRight === "N" : "N"}
        />
        {/* <ConsoleLog>"InvoiceCheckEdit update crud button: {props}"</ConsoleLog> */}
        <DeleteWithConfirmButton
            label="DeleteFromAction"
            redirect="list"
            basePath="/InvoiceChecks/:id"
            submitOnEnter={false}
            variant="flat"
            color="primary"
            resource="InvoiceChecks"
            undoable={false}
            disabled={props.permission ? props.permission.DeleteRight === "N" : "N"}
        />
    </Toolbar>
));

const mapStateToProps = state => {
    return {
        naviPath:{
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
            invoiceCheck: state.selectionState.navItem.invoiceCheck,
            invoiceCheckName: state.selectionState.navItem.invoiceCheck.value.ic_Name,
            invoiceCheckId: state.selectionState.navItem.invoiceCheck.value.id,  
       },
        isLoggedIn: state.admin.auth.isLoggedIn,
    }
}
const InvoiceCheckEdit2Redux=connect(
    mapStateToProps,
    null
) (InvoiceCheckEdit);

export default withStyles(styles)(InvoiceCheckEdit2Redux);