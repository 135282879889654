
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import {
    SaveButton,
    crudUpdate,
} from 'react-admin';


const saveWithNote =  (values, basePath, redirectTo, formula) => {


    var resource = basePath;
   
    if (basePath == "/Models") {
        basePath = {
            pathname: `${basePath}`,
            state: { record: { contractId: values.m_c_id } }
        }
    }
    else if (basePath == "/Points") {
        basePath = {
            pathname: `${basePath}`,
            state: { record: { modelId: values.modelId } }
        }

        values = {
            ...values,
            formula: formula
        }
    }
    else if (basePath == "/InvoiceChecks") {
        basePath = {
            pathname: `${basePath}`,
            state: { record: { contractId: values.ic_c_id } }
        }
    }
    else if (basePath == "/InvoiceCheckItems") {
        basePath = {
            pathname: `${basePath}`,
            state: {
                record: {
                    invoiceCheckId: values.ici_ic_id,
                    ic_m_id_1: values.ic_m_id_1,
                    ic_m_id_2: values.ic_m_id_2
                }
            }
        }
    }

  if (resource.charAt(0)=='/')  {
    resource = resource.replace('/','');
  }
    return crudUpdate(resource, values.id, values, values, basePath, redirectTo, false);
}

 
class UpdateCrudComponent extends Component {
    handleClick = () => {
      
        const { basePath, handleSubmit, redirect, saveWithNote, formula } = this.props;
        return handleSubmit(values => {
           
            saveWithNote(values, basePath, redirect, formula);
        });
    };

    render() {
        const { handleSubmitWithRedirect, saveWithNote, ...props } = this.props;
    
        return (
            <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                disabled={this.props.disabled != null ? this.props.disabled : (this.props.record.UpdateRight ==='Y' ) ?  false : true }
                variant ={this.props.variant}   
            />
        );
    }

}

UpdateCrudComponent.propTypes = {
    values: PropTypes.object
}

const UpdateCrudButton = connect(
    undefined,
    { saveWithNote }
)(UpdateCrudComponent);

export default UpdateCrudButton;