import CategoryList  from './CategoryList';
import { CategoryShow } from './CategoryShow';
import CategoryCreate from './CategoryCreate';
import CategoryEdit from './CategoryEdit';

export default {
    list: CategoryList,
    create: CategoryCreate,
    edit: CategoryEdit,
    show: CategoryShow
};