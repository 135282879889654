import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { connect } from 'react-redux';
import { ModelListActions } from './ModelActions';
import ConsoleLog from '../Common/ConsoleLog';
import { PostPagination } from '../Common/Pagination';
import EditButton from '../Common/EditButton';
import ShowButton from '../Common/ShowButton';
import DeleteCrud from '../Common/DeleteCrud';
import { tableMuiTheme } from "../Common/Styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { AccessDenied } from "../Common/NoAccess";
import NavigationButton from '../Common/NavigationButton';
import CheckPagePermission from "../Common/CheckPagePermissionJs";



const ModelList = ({
    pagePermissions,
    addSelection,
    contract,
    dispatch,
    isLoggedIn,
    ...props }) => {

    const naviPath = {
        contractId: contract.value.id,
        contractName: contract.value.c_Name,
    }

    return (
        <CheckPagePermission
            authParams={{ Admin: false }}
            location={window.location}
            match="Models"
            render={({ pagePermissions }) =>
                <div>
                    {(pagePermissions && pagePermissions.View === "Y") ?
                        <MuiThemeProvider theme={tableMuiTheme}>
                            <List actions={<ModelListActions record={naviPath}

                                permissions={{
                                    UpdateRight: contract.value.UpdateRight,
                                    DeleteRight: contract.value.DeleteRight
                                }}
                            />
                            }
                                /* filter={{
                                     contractId: props.location.state ?
                                         props.location.state.record.contractId : undefined
                                 }}*/
                                filter={{
                                    contractId: contract.value.id
                                }}
                                pagination={<PostPagination />}
                                title="Models"
                                {...props}
                                bulkActionButtons={false}
                            >
                                <Datagrid>
                                    <ConsoleLog>"Model list props: {props} "</ConsoleLog>
                                    <TextField source="id" />
                                    <TextField source="Name" label="Name" />
                                    <TextField source="Description" label="Description" />
                                    <TextField source="TimeZone" label="Time Zone" />
                                    <TextField source="m_PurchaseOrder" label="Purchase Order" />
                                    <NavigationButton resource="Points" path="Points" label="Point" basePath={props.basePath} record={props.record} />
                                    <NavigationButton
                                        resource="CopyModel"
                                        path="CopyModel"
                                        label="Copy"
                                        basePath={props.basePath}
                                        record={props.record}
                                    />
                                    <EditButton label="Edit" resource="/Models/:id" basePath={props.basePath} record={props.record} />
                                    <ShowButton label="Show" resource="/Models/:id" basePath={props.basePath} record={props.record} />
                                    <DeleteCrud label="Delete" resource="/Models/:id" basePath={props.basePath} record={props.record} />
                                </Datagrid>
                            </List>
                        </MuiThemeProvider> : <AccessDenied />
                    }
                </div>}
        />);


};

//export default ModelList;


const mapState = state => {
    return {
        contract: state.selectionState.navItem.contract,
        isLoggedIn: state.admin.auth.isLoggedIn,

    }
}
export default connect(mapState, null)(ModelList);

/*const mapDispatch = (dispatch) => {
    return {
        contract2Store: (id) => dispatch({
            type: "ADD_SELECTION",
            payload:{
                    type: "contractId",
                    value: id
                }})

    }
}*/


/*stageValid:(valid) =>{dispatch({type: 'STAGE_VALID', staged: valid});},
        handleSubmit: submit*/

//export default connect()(ModelList);
//export default connect(mapState, null)(ModelList);