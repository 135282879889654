import InvoiceCheckItemList from './InvoiceCheckItemList';
import InvoiceCheckItemShow from './InvoiceCheckItemShow';
import InvoiceCheckItemCreate from './InvoiceCheckItemCreate';
import InvoiceCheckItemEdit from './InvoiceCheckItemEdit';

export default {
    list: InvoiceCheckItemList,
    create: InvoiceCheckItemCreate,
    edit: InvoiceCheckItemEdit,
    show: InvoiceCheckItemShow
};