import React from 'react';
import {
    Create, SimpleForm, TextInput, AutocompleteInput
} from 'react-admin';
import { CreateActions } from '../../Common/Actions';
import CreateToolbar from '../../Common/CreateToolbar';
import { withStyles } from '@material-ui/core';
import { styles } from '../../Common/Styles';

const FrequencyCreate = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    return (
        <Create actions={<CreateActions />} {...props} >
            <SimpleForm toolbar={<CreateToolbar {...props} />} redirect="show">
                <TextInput label="Frequency Name" source="fq_Name" validate={required()} className={classes.textFld} />
                <AutocompleteInput label="Minute" source="fq_Minute" choices={choices_fq_Minute} optionText="name" optionValue="_id" />
                <AutocompleteInput label="Day" source="fq_Day" choices={choices_fq_Day} optionText="name" optionValue="_id" />
                <AutocompleteInput label="Month" source="fq_Month" choices={choices_fq_Month} optionText="name" optionValue="_id" />
            </SimpleForm>
        </Create>
    );
};

const Getchoices = (number) => {

  return [ ...Array(number).keys() ].map(( i) => ({_id: i+1, name: String(i+1)}));

};

export const choices_fq_Minute = Getchoices(60);
export const choices_fq_Day = Getchoices(30);
export const choices_fq_Month = Getchoices(12);

export default withStyles(styles)(FrequencyCreate);