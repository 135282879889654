import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import Assessment from "@material-ui/icons/Assessment";
import PageViewIcon from "@material-ui/icons/Pageview";
import InputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import SettingIcon from "@material-ui/icons/Settings";
import FileUploadIcon from "@material-ui/icons/FileUpload";
import FunctionsIcon from "@material-ui/icons/Functions";


import {
  Loop,
  Autorenew,
  Functions,
  InsertChart,
  VpnKey,
  AddLocation,
  PersonAdd,
  Build,
  AttachMoney as Money,
  AccessTime,
  AddBox,
  Place,
  PinDrop,
  Flag,
  SupervisorAccount,
  Brightness7,
  PlaylistAddCheck,
} from "@material-ui/icons";
import {
  translate,
  DashboardMenuItem,
  MenuItemLink,
  Responsive,
  WithPermissions,
} from "react-admin";

import SubMenu from "./SubMenu";

import CheckPagePermission from "../Common/CheckPagePermissionJs";

class Menu extends Component {
  state = {
    menuContracts: false,
    menuUpload: false,
    menuEvaluation: false,
    menuAdmin: false,
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
  };

  handleToggle = (menu) => {
    this.setState((state) => ({ [menu]: !state[menu] }));
  };

  render() {
   ////debugger;
    const { onMenuClick, open, logout, translate,dispatch } = this.props;
    return (
      <div>
        {" "}
        <DashboardMenuItem onClick={onMenuClick} />
        <MenuItemLink
          to={`/Contracts`}
          primaryText="All Contracts"
          leftIcon={<PageViewIcon />}
          onClick={onMenuClick}
        />
        {/*<CheckPagePermission authParams={{Admin:false}}
          render={({ permissions }) =>
    permissions && permissions.Insert === "Y" ? (*/}
              <SubMenu
                handleToggle={() => this.handleToggle("menuUpload")}
                isOpen={this.state.menuUpload}
                sidebarIsOpen={open}
                name="Data Upload"
                icon={<FileUploadIcon />}
              >
              {/*<MenuItemLink
                  to={`/MeterData`}
                  primaryText="Meter Data"
                  leftIcon={<InputAntennaIcon />}
                  onClick={onMenuClick}
                />*/}
                <MenuItemLink
                  to={`/FileUpload`}
                  primaryText="File Upload"
                  leftIcon={<InputAntennaIcon />}
                  onClick={onMenuClick}
                />
              </SubMenu>
            {//) : null }/>
            }
        <MenuItemLink
          to={`/Calculation/create`}
          primaryText="Calculation"
          leftIcon={<FunctionsIcon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={`/Result/create`}
          primaryText="Result"
          leftIcon={<Assessment />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={`/Approvals/create`}
          primaryText="Approval"
          leftIcon={<PlaylistAddCheck />}
          onClick={onMenuClick}
        />
      {/*  <SubMenu
          handleToggle={() => this.handleToggle("menuEvaluation")}
          isOpen={this.state.menuEvaluation}
          sidebarIsOpen={open}
          name="Evaluation"
          icon={<Autorenew />}
        >
          <MenuItemLink
            to={`/users`}
            primaryText="Calculation"
            leftIcon={<SettingIcon />}
            onClick={onMenuClick}
          />

          <MenuItemLink
            to={`/users`}
            primaryText="Report"
            leftIcon={<InsertChart />}
            onClick={onMenuClick}
      />
        </SubMenu>*/}
        {/*<CheckPagePermission
          authParams={{Admin:true}} 
          render={({ permissions }) =>
        permissions && permissions.id == 500 ? ( //Admin 4 */}
              <SubMenu
                handleToggle={() => this.handleToggle("menuAdmin")}
                isOpen={this.state.menuAdmin}
                sidebarIsOpen={open}
                name="Admin"
                icon={<Build />}
              >
                <MenuItemLink
                  to={`/Admin/DomainAccess`}
                  primaryText="Domain Access"
                  leftIcon={<AddLocation />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/Admin/PageAccess`}
                  primaryText="Page Access"
                  leftIcon={<AddLocation />}
                  onClick={onMenuClick}
                />
                {/*<MenuItemLink
                  to={`/Sites`}
                  primaryText="Add Sites"
                  leftIcon={<AddLocation />}
                  onClick={onMenuClick}
                />*/}
                <MenuItemLink
                  to={`/Admin/Users`}
                  primaryText="User"
                  leftIcon={<PersonAdd />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/Admin/Units`}
                  primaryText="Unit"
                  leftIcon={<Money />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/Admin/Frequencies`}
                  primaryText="Frequency"
                  leftIcon={<AccessTime />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/Admin/Categories`}
                  primaryText="Category"
                  leftIcon={<AddBox />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/Admin/Mediums`}
                  primaryText="Medium"
                  leftIcon={<Place />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/Admin/Regions`}
                  primaryText="Region"
                  leftIcon={<PinDrop />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/Admin/Countries`}
                  primaryText="Country"
                  leftIcon={<Flag />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/Admin/Suppliers`}
                  primaryText="Supplier"
                  leftIcon={<SupervisorAccount />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/Admin/Qualities`}
                  primaryText="Quality"
                  leftIcon={<Brightness7 />}
                  onClick={onMenuClick}
                />
                <MenuItemLink
                  to={`/Admin/PublicPointUpload`}
                  primaryText="Public Point Upload"
                  leftIcon={<FileUploadIcon />}
                  onClick={onMenuClick}
                />
              </SubMenu>
            {/*  ) : null*/
           //}/>
          }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
  locale: state.i18n.locale,
});

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate);

//export default enhance(Menu);

export default Menu;
