import React from 'react';
import { CardActions, CreateButton, RefreshButton, ListButton, showNotification } from 'react-admin';
import  EditButton  from '../Common/EditButton';
import ConsoleLog from '../Common/ConsoleLog';
import { cardActionStyle } from '../Common/Styles';
import  NavigationPath from '../Common/NavigationPath';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const InvoiceCheckListActions = ({
    create,
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    permissions,
    record, ...rest }) => (
        <CardActions  style={cardActionStyle} >
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <NavigationPath {...record}/>
            <div  style={cardActionStyle.actionBlock} >
            <CreateButton label="Create" basePath={basePath}
                to={{
                    pathname: '/InvoiceChecks/create',
                    state: { record: { contractId: record.contractId } },
                }}
                resource={create}
                variant="flat"
                color="primary"
                record={record}
                disabled={permissions.Insert == "N"}
            />
            {/* <ConsoleLog > "InvoiceCheck Action:" + {record} </ConsoleLog> */}
            {/* <ConsoleLog>{this.props}</ConsoleLog> */}
            {/*<ExportButton resource= "Contracts"/>*/}
            <RefreshButton variant="flat" color="primary" />
            </div>
        </CardActions>
    );

export class InvoiceCheckShowActions extends React.Component {
    render() {

        //debugger;
        const { basePath, data,record, resource, showNotification, permissions } = this.props;
        return (
            <CardActions style={cardActionStyle}>
                <NavigationPath {...record}/>
                <div  style={cardActionStyle.actionBlock} >
                <EditButton
                    label="EditFromAction"
                    resource="/InvoiceChecks/:id"
                    basePath={basePath}
                    record={{ ...data, ...permissions }}
                    showNotification={showNotification}
                />
                <ListButton label="Table" basePath={{
                    pathname: `${basePath}`,
                    state: {
                        record: {
                            contractId: record.contractId,
                            //data: data  
                        }
                    },
                }} 
                variant="flat" 
                color="primary"
                />
                </div>
            </CardActions>
        );
    }
}

InvoiceCheckShowActions.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotification,
})(InvoiceCheckShowActions);

export const InvoiceCheckCreateActions = ({ basePath, record, resource }) => (
    <CardActions style={cardActionStyle}>
        <NavigationPath {...record}/>
        <div  style={cardActionStyle.actionBlock} >
        <ListButton
            label="Table"
            basePath={{
                pathname: `${basePath}`,
                state: { record: { contractId: record.contractId } },
            }} 
            variant="flat" 
            color="primary" 
        />
        </div>
    </CardActions>
);

export const InvoiceCheckEditActions = ({ basePath, record, resource }) => (
    <CardActions style={cardActionStyle}>
        <NavigationPath {...record}/>
        <div  style={cardActionStyle.actionBlock} >
        <ListButton label="Table"
            basePath={{
                pathname: `${basePath}`,
                state: { record: { contractId: record.contractId } },
            }}
            //basePath={basePath}
            variant="flat" color="primary" />
            </div>
    </CardActions >
);