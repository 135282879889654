import { RegionList } from './RegionList';
import { RegionShow } from './RegionShow';
import RegionCreate from './RegionCreate';
import RegionEdit from './RegionEdit';

export default {
    list: RegionList,
    create: RegionCreate,
    edit: RegionEdit,
    show: RegionShow
};