import React from 'react';
import {
    Create, SimpleForm, TextInput, ReferenceInput, SelectInput,
    AutocompleteInput, SaveButton, Toolbar
} from 'react-admin';
import { PointCreateActions } from './PointActions';
import { withStyles } from '@material-ui/core';
import { styles, toolbarStyles } from '../Common/Styles';
import {required } from  '../Common/Validator';
import { connect } from 'react-redux';




const redirecttoshow = (basePath, id, data) => `${basePath}/${id}/show`;

const PointCreate = ({classes, pointPermissions,naviPath,...props}) => {

  
    const CreateToolbar = withStyles(toolbarStyles)(props => (
        <Toolbar {...props}>
            <SaveButton label="Save" variant="flat" color="primary" />
        </Toolbar>
    ));

    
    /*record={props.location.state ?
        props.location.state.record : { modelId: props.record.modelId }} */
    return (
        <Create actions={<PointCreateActions  naviPath={naviPath} />} {...props} >
            <SimpleForm toolbar={<CreateToolbar />} 
            redirect="list"
            >
                <TextInput label="Point Name" source="Point_Name" validate={required()} className={classes.textFld} />
                <TextInput label="Point Description" source="Point_Description" className={classes.textFld} />
                <ReferenceInput label="Type" source="PointTypeId" reference="PointType" resource="PointType"
                    resettable validate={required()}  >
                    <SelectInput optionText="pt_Name" />
                </ReferenceInput>

                <ReferenceInput label="Category" source="Category_id" reference="Categories" resource="Categories"
                    filter={{ is_all: true }}
                    resettable validate={required()}  >
                    <SelectInput optionText="ca_Name" />
                </ReferenceInput>

                <ReferenceInput label="Frequency" source="Frequency_id" reference="Frequencies" resource="Frequencies"
                    filter={{ is_all: true }}
                    resettable validate={required()}  >
                    <SelectInput optionText="fq_Name" />
                </ReferenceInput>

                <ReferenceInput label="Unit" source="Unit_id" reference="Units" resource="Units"
                    sort={{ field: 'un_Name', order: 'ASC' }}
                    filter={{ is_all: true }}
                    resettable validate={required()}  >
                    <SelectInput optionText="un_Name" />
                </ReferenceInput>

                <AutocompleteInput source="Public_id" choices={choices} optionText="name" optionValue="_id" />
            </SimpleForm>
        </Create>
    );
};


export const choices = [
    { _id: 'Y', name: 'Yes' },
    { _id: 'N', name: 'No' },
];


const mapStateToProps = state => {
    return {
        naviPath: {
            contract: state.selectionState.navItem.contract,
            contractName: state.selectionState.navItem.contract.value.c_Name,
            model: state.selectionState.navItem.model,
            modelId: state.selectionState.navItem.model.value.id,
            modelName: state.selectionState.navItem.model.value.Name,

        },
        pointPermissions: {
            UpdateRight: state.selectionState.navItem.model.value.UpdateRight,
            DeleteRight: state.selectionState.navItem.model.value.DeleteRight
        },
        isLoggedIn: state.admin.auth.isLoggedIn,
    }
}
const PointCreate2Redux = connect(
    mapStateToProps,
    null
)(PointCreate);


export default withStyles(styles)(PointCreate2Redux);