import React from 'react';
import { Datagrid, TextField, List, NumberField } from 'react-admin';
import { PostPagination } from '../../Common/Pagination';
import { MuiThemeProvider } from '@material-ui/core';
import { tableMuiTheme } from "../../Common/Styles";
import { ApprovalDataListActions } from './ApprovalDataAction';

const ApprovalDataList = ({ permissions, ...props }) => {

  //debugger;

  return (
    <MuiThemeProvider theme={tableMuiTheme} >
      <List
        {...props}
        exporter={false}
        title="Approval Data"
        pagination={<PostPagination />}
        actions={<ApprovalDataListActions />}        
        sort={{ field: 'ici_Order', order: 'DESC' }}
        filter={props.location ? props.location.state ? props.location.state.record : undefined : undefined}
        bulkActionButtons={false}
      >
        <Datagrid>
          <TextField label="Model1" source="ModelName1" />
          <TextField label="Point1" source="PointName1" />
          <NumberField label="Value1" source="Value1" options={{ maximumFractionDigits: 6 }} />
          <TextField label="Model2" source="ModelName2" />
          <TextField label="Point2" source="PointName2" />
          <NumberField label="Value2" source="Value2" options={{ maximumFractionDigits: 6 }} />
          <NumberField label="Deviation in %" source="DeviationPercent" options={{ maximumFractionDigits: 6 }} />
          <NumberField label="Deviation Absolut" source="DeviationAbsolut" options={{ maximumFractionDigits: 6 }} />
        </Datagrid>
      </List>
    </MuiThemeProvider>
  );
}

export default ApprovalDataList;
