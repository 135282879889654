import React from 'react';
import {
    Create, SimpleForm, TextInput, AutocompleteInput
} from 'react-admin';
import { CreateActions } from '../../Common/Actions';
import CreateToolbar from '../../Common/CreateToolbar';
import { withStyles } from '@material-ui/core';
import { styles } from '../../Common/Styles';

const CategoryCreate = props => {

    const { classes } = props;

    const required = (message = 'Required') =>
        value => value ? undefined : message;

    return (
        <Create actions={<CreateActions />} {...props} >
            <SimpleForm toolbar={<CreateToolbar {...props} />} redirect="show">
                <TextInput label="Category Name" source="ca_Name" validate={required()} className={classes.textFld} />                
            </SimpleForm>
        </Create>
    );
};

export default withStyles(styles)(CategoryCreate);